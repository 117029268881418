import React from 'react'
import Wave from 'react-wavify'
// import { Howl } from 'howler'

// import Backdrop from '@mui/material/Backdrop';
import Fab from '@mui/material/Fab';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import NavigationIcon from '@mui/icons-material/Navigation';
import WaveResultCard from './WaveResultCard';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography'
import { CircularProgress } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { MyContext } from './MyContext';
import { Grid, Card, CardContent, CardActions, IconButton } from '@mui/material';
import { CheckCircle, Delete } from '@mui/icons-material';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';


class GetImages extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);

        this.storage_key = props.storage_key;

        console.log('Storage key:', this.storage_key);

        if(!this.storage_key) {
            console.error('Storage key not set');
            return;
        }

        let images = [];     
        images = localStorage.getItem(this.storage_key);
        if(images) images = JSON.parse(images);
        else images = [];

        console.log('Images in browser storage:', images.length);

        this.props.onImagesSet(images);


        this.state = {
            images: images
        }

        // fetch images from browser storage

    }

    componentDidMount() {
        this.startTs = Date.now();

    }

    finished() {
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);
    }


    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        clearTimeout(this.timeout);

        console.log("Ended");

        this.setState({ askFeedback: true, ended: true });
        //this.setState({ showResult: true, sessionData: sessionData, ended: true });

    }

    async save() {
        this.setState({ saving: true });

        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            events: this.events,
            feedback: this.state.textAreaValue,
        };

        let sessionId = await this.props.store_cb(sessionData, this.startTs);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }

    onEda(gsr, acc) {

    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    handleCopyImage = async () => {
        // Handle copying image from clipboard and making it visible
        try {

            const result = await navigator.permissions.query({ name: "clipboard-read" });
            console.log('Permission Status:', result.state);

            let text = await navigator.clipboard.readText();
            console.log('TEXT Clipboard item:', text);

            if(text.startsWith("data:image/") || text.startsWith("http")) {
                console.log('TEXT Clipboard item:', text);

                // check if image is already in the list
                if(this.state.images.indexOf(text) === -1) {

                    this.setState({images: [...this.state.images, text]});

                    this.state.images.push(text);

                    // store images to browser storage
                    localStorage.setItem(this.storage_key, JSON.stringify(this.state.images));

                    console.log('Images in browser storage:', this.state.images.length);

                    this.props.onImagesSet(this.state.images);
                } else {
                   enqueueSnackbar('Image already added', { variant: 'error' });
                }
            } else {
                enqueueSnackbar('No image found. Use "copy image address"', { variant: 'error' });
            }   

            // let clipboardItems = await navigator.clipboard.read();

            // console.log('Clipboard items:', clipboardItems);

            // for (const clipboardItem of clipboardItems) {
                
            //     for (const type of clipboardItem.types) {
            //         if (type.startsWith('image/')) {
            //             const blob = await clipboardItem.getType(type);
            //             const imageUrl = URL.createObjectURL(blob);
            //             // Handle displaying the image
            //             // For example, you can set the imageUrl to a state variable and render it in your component
            //             this.setState({ imageUrl });
            //             console.log('Image URL:', imageUrl);
            //             break;
            //         } if(type.startsWith('text/')) {
            //             const text = await clipboardItem.getType(type);
            //             console.log('Text:', text);
            //         }                    
            //     }
            // }
        } catch (error) {
            console.error('Failed to read clipboard:', error);
        }
    };


    handleRemoveImage = (image) => {
        // Handle removing the image
        console.log('Removing image:', image);
        let images = this.state.images.filter((i) => i !== image);

        localStorage.setItem(this.storage_key, JSON.stringify(images));
        this.props.onImagesSet(images);

        this.setState({ images });


    };


    render() {

        const style = {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        };

        if(this.storage_key === undefined) {
            return (
                <div>
                    <Typography variant="body1" component="h2">
                        This component is not correctly configured. Please set the storage key.
                    </Typography>
                </div>
            );
        }

        return (
            <div>
                <Grid container spacing={2}>
                    {this.state.images.map((image, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card>
                                <CardContent>
                                    
                                    <img src={image} alt="Image" style={{ width: '100%', height: 'auto' }} />

                                    {/* <img src={image} alt="Image" /> */}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        startIcon={<Delete />}
                                        onClick={() => this.handleRemoveImage(image)}
                                    >
                                        Remove image
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}

                    <Grid item xs={12} sm={6} md={4} key={-1}>
                        <Card>
                            <CardContent>
                                <Typography variant="body1" component="h2">
                                First find an image on the Internet you like and copy it to the clipboard 
                                by right clicking and selecting "Copy image address". 
                                Then click the button below to add it to the list.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckCircle />}
                                    onClick={() => this.handleCopyImage()}
                                >
                                    Add image
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>


                </Grid>
            </div>
        );
    }
}

export default GetImages;

