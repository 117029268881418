import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Link } from 'react-router-dom';
import CourseCardCalendar from './CourseCardCalendar';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'; // add this import
import AdaptiveDifficultyChart from '../AdaptiveDifficultyChart';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // added import for downward icon
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { calcDifficultyFromParams } from '../scripts/calc_difficulty';

import campaign_data from '../scripts/campaign_data.js';
import ScoreBox from '../ScoreBox';
import { MyContext } from '../MyContext';

class Dashboard extends Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);
        this.campdata = campaign_data();
        this.state = {
            selectedCampaign: '',
            anchorEl: null, // For the course menu
            popupOpen: false // added for popup modal
        };

        this.nextExerciseClicked = this.nextExerciseClicked.bind(this);
    }

    // New: Load last selected campaign from local storage using a user-specific key.
    componentDidMount() {
        const userKey = `lastSelectedCampaign_${this.context.user}`;
        const storedCampaign = localStorage.getItem(userKey);
        const availableCourses = this.props.campaigns || [];
        if (storedCampaign) {
            if (availableCourses.includes(storedCampaign)) {
                this.setState({ selectedCampaign: storedCampaign });
            } else {
                localStorage.removeItem(userKey);
            }
        }
    }

    nextExerciseClicked(next) {
        console.log("NEXT exercise clicked", next);
        let camp = next[0];
        let ex = next[1];
        this.context.setExercise(camp, ex);
    }

    // Open the menu anchored to the icon button.
    handleMenuOpen = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    }

    // Close the menu.
    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    }

    // Save the selected campaign to local storage under a user-specific key.
    handleCourseSelect = (camp) => {
        const userKey = `lastSelectedCampaign_${this.context.user}`;
        localStorage.setItem(userKey, camp);
        this.setState({ selectedCampaign: camp, anchorEl: null });
    }

    handlePopupOpen = () => {
        this.setState({ popupOpen: true });
    }

    handlePopupClose = () => {
        this.setState({ popupOpen: false });
    }

    // New: Handle calendar date click to filter sessions by campaign and day.
    handleCalendarDateClick = (date) => {
        const currentCampaign = this.state.selectedCampaign ||
            (this.props.campaigns && this.props.campaigns.length > 0 ? this.props.campaigns[0] : '');
        // Convert date to ISO string (yyyy-MM-dd)
        const filterDate = date instanceof Date ? date.toISOString().slice(0, 10) : date;
        // Assume context has setters for campaign and filter date.
        console.log("Filtering by date", filterDate);
        let pageParam = { filterDate: filterDate };
        this.context.setPage("sessions", pageParam);
    }

    renderCourseCard = (camp, showSelector) => {
        // Ensure campaign exists and has stats.
        if (!this.campdata[camp] || !(camp in this.props.weeklyStats)) return null;

        // Calculate exercise dates from sessions.
        const sessions = this.context.sessions || [];
        const exerciseDates = Array.from(
            new Set(
                sessions
                    .filter(
                        (s) =>
                            s.campaign === camp &&
                            s.exercise &&
                            !isNaN(new Date(s.ts).getTime())
                    )
                    .map((s) => new Date(s.ts).toISOString().slice(0, 10))
            )
        ).sort();

        // New: Compute yesterday's maximum difficulty and compare with current difficulty,
        // with added guard for valid s.ts values.
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        const yesterdayISO = yesterday.toISOString().slice(0, 10);
        let yesterdayMax = null;
        sessions.forEach(s => {
            if (s.data && s.data.difficulty_params && s.ts) {
                const dt = new Date(s.ts);
                if (!isNaN(dt.getTime())) { // Only use valid date values
                    const sessionDate = dt.toISOString().slice(0, 10);
                    if (sessionDate === yesterdayISO) {
                        const [, diff] = calcDifficultyFromParams(s.data.difficulty_params);
                        if (yesterdayMax === null || diff > yesterdayMax) {
                            yesterdayMax = diff;
                        }
                    }
                }
            }
        });
        const currentDifficulty = Math.floor(this.context.difficulty);
        const difficultyIncrease = (yesterdayMax !== null && currentDifficulty > yesterdayMax)
            ? currentDifficulty - yesterdayMax
            : null;

        // Modified: Compute 3-day difficulty trend using only days with sessions having difficulty parameters
        const validDays = Array.from(
            new Set(
                sessions
                    .filter(s => s.data && s.data.difficulty_params && s.ts && !isNaN(new Date(s.ts).getTime()))
                    .map(s => new Date(s.ts).toISOString().slice(0, 10))
            )
        ).sort();
        const recentDays = validDays.slice(-3);
        const trendValues = recentDays.map(dayISO => {
            let dayMax = null;
            sessions.forEach(s => {
                if (s.data && s.data.difficulty_params && s.ts) {
                    const dt = new Date(s.ts);
                    if (!isNaN(dt.getTime()) && dt.toISOString().slice(0, 10) === dayISO) {
                        const [, diff] = calcDifficultyFromParams(s.data.difficulty_params);
                        if (dayMax === null || diff > dayMax) {
                            dayMax = diff;
                        }
                    }
                }
            });
            return dayMax;
        });
        const baseDayDifficulty = trendValues.length > 0 ? trendValues[0] : currentDifficulty;
        const trendDelta = currentDifficulty - baseDayDifficulty;

        // Retrieve next exercise and adjust if usage is limited.
        let next_exercise = false
        let next_exercise_text = false;

        next_exercise = this.level[camp].next_exercise;
        if (this.context.usageLimited) {
            next_exercise = false;
        }

        next_exercise_text = next_exercise
            ? this.campdata[camp].exercises[next_exercise[1]].label
            : null;

        let level = this.level[camp].level;

        const levelInfo = this.level[camp].levelInfo;
        const extraMsg = this.level[camp].extraMsg;


        // Other campaign details.
        const title = this.campdata[camp].label;

        const noncourse = camp === "experimental" || camp === "raweda";
        const allstat = this.props.weeklyStats[camp].allstat;
        allstat.difficulty = Math.floor(this.context.difficulty);

        console.log("Dashboard render", camp, title, level, levelInfo, extraMsg, allstat, next_exercise, next_exercise_text, exerciseDates);

        return (
            <Box>
                {/* Top Row: Title with conditional Selector */}
                <Card sx={{ mb: 2 }}>
                    <CardContent sx={{ position: "relative", textAlign: "center", width: "100%" }}>
                        <Box sx={{ display: "inline-block", position: "relative" }}>
                            <Typography variant="h5" color="text.secondary" component="span">
                                {title}
                            </Typography>
                            {showSelector && ( // Only show the dropdown if there is more than one course
                                <IconButton
                                    size="small"
                                    onClick={this.handleMenuOpen}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        right: -30,
                                        transform: "translateY(-50%)",
                                    }}
                                >
                                    <ArrowDropDownIcon />
                                </IconButton>
                            )}
                        </Box>
                        {/* Optionally show course progress for normal courses */}
                        {!noncourse && (
                            <Typography variant="body2" color="text.secondary">
                                Course progress {level} / {
                                    Object.values(this.campdata[camp].exercises)
                                        .filter(e => !e.section && typeof e.level === 'number')
                                        .reduce((max, e) => Math.max(max, e.level), 0)
                                }
                            </Typography>
                        )}
                    </CardContent>
                </Card>

                {/* Bottom Row: Grid with individual cards */}
                <Grid container spacing={2} alignItems="stretch">
                    {/* ScoreBox Card (only if not noncourse) */}
                    {!noncourse && (
                        <Grid item xs={12} sm={4}>
                            <Card sx={{ height: "100%" }}>
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        minHeight: 200, // or any value that suits your design
                                        position: 'relative'
                                    }}
                                >
                                    <ScoreBox data={allstat} view="detailed" />
                                    {/* Replace tooltip with a popup trigger */}
                                    <IconButton sx={{ position: 'absolute', top: 4, right: 4 }} onClick={this.handlePopupOpen}>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}


                    {/* Next Exercise / Library Card */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                                {next_exercise ? (
                                    <>
                                        <Box>
                                            <Typography variant="body2">
                                                {levelInfo}
                                            </Typography>
                                            <br></br>
                                            {extraMsg && (
                                                <Typography variant="body2">
                                                    {extraMsg}
                                                </Typography>
                                            )}
                                            <br></br>
                                            <Typography variant="body2">
                                                Recommended exercise:
                                                <br></br>
                                                {next_exercise_text}
                                            </Typography>
                                            <Button
                                                sx={{ mt: 2 }}
                                                size="medium"
                                                variant="contained"
                                                onClick={() => this.nextExerciseClicked(next_exercise)}
                                            >
                                                GO!
                                            </Button>
                                        </Box>
                                        <Box sx={{ mt: 'auto', mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography variant="caption" sx={{ mr: 0 }}>
                                                Redo earlier exercises:
                                            </Typography>
                                            <IconButton size="small" onClick={() => {
                                                this.context.setCampaign(camp);
                                                this.context.setPage("campaigns");
                                            }}>
                                                <KeyboardArrowRightIcon />
                                            </IconButton>
                                        </Box>
                                    </>
                                ) : (
                                    <Button
                                        sx={{ mt: 2 }}
                                        size="medium"
                                        variant="contained"
                                        onClick={() => this.context.setPage("campaigns")}
                                    >
                                        Select from library
                                    </Button>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Calendar Card */}
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ height: "100%", minWidth: 250 }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', height: "100%" }}>
                                <Box sx={{ mt: 'auto' }}>
                                    <CourseCardCalendar
                                        exerciseDates={exerciseDates}
                                        indicatorType="tick"
                                        containerWidth={250}
                                        onDateClick={this.handleCalendarDateClick}
                                    />
                                </Box>
                                <Box sx={{ mt: 'auto', mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant="caption" sx={{ mr: 0 }}>
                                        Full history
                                    </Typography>
                                    <IconButton size="small" onClick={() => this.context.setPage("sessions")}>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

                    {/* Adaptive Difficulty Chart (only if course is not noncourse) */}
                    {!noncourse && (
                        <Grid item xs={12} sm={4}>
                            <Card sx={{ margin: 0 }}>
                                <CardContent>
                                    <AdaptiveDifficultyChart sessions={this.context.sessions || []} />
                                </CardContent>
                            </Card>
                        </Grid>
                    )}

                    {/* New Card: 3-Day Trend (only if course is not noncourse) */}
                    {!noncourse && (
                        <Grid item xs={12} sm={4}>
                            <Card sx={{ margin: 0 }}>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    {trendDelta > 0 ? (
                                        <TrendingUpIcon color="success" fontSize="large" />
                                    ) : trendDelta < 0 ? (
                                        <TrendingDownIcon color="error" fontSize="large" />
                                    ) : (
                                        <Typography variant="h6">No Change</Typography>
                                    )}
                                    <Typography variant="h6">3-Day Trend</Typography>
                                    {trendDelta > 0 && (
                                        <Typography variant="body2" color="text.secondary">
                                            Great job! Increased by {Math.floor(trendDelta)}.
                                        </Typography>
                                    )}
                                    {trendDelta < 0 && (
                                        <Typography variant="body2" color="text.secondary">
                                            Oops! Decreased by {Math.floor(Math.abs(trendDelta))}.
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>

                {/* Course Selection Menu */}
                {showSelector && (
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenuClose}
                    >
                        {(this.props.campaigns || []).map((camp) => (
                            <MenuItem key={camp} onClick={() => this.handleCourseSelect(camp)}>
                                {this.campdata[camp].label}
                            </MenuItem>
                        ))}
                    </Menu>
                )}
            </Box>
        );
    };

    render() {
        // Parse level info from the JSON string passed in props.
        this.level = JSON.parse(this.props.level);

        // Filter campaigns to only those that exist in our data, are not group campaigns, and have stats.
        const validCampaigns = (this.props.campaigns || []).filter(camp =>
            this.campdata[camp] && !this.campdata[camp].group && (camp in this.props.weeklyStats)
        );

        const showSelector = validCampaigns.length > 1; // New flag

        // Use the selected campaign or default to the first valid one.
        const currentCampaign = this.state.selectedCampaign || (validCampaigns.length > 0 ? validCampaigns[0] : '');

        console.log("Dashboard render currentCampaign", validCampaigns, currentCampaign, this.state.selectedCampaign);

        // Get the user's nickname.
        let nickname = this.context.user;
        if (this.context.userRecord && this.context.userRecord.nickname) {
            nickname = this.context.userRecord.nickname;
        }



        return (
            <div
                style={{
                    background: 'linear-gradient(to bottom, #031b58, #66f5f6)',
                    minHeight: 'calc(100vh - 64px)',
                    padding: 0,
                    margin: 0,
                }}
            >
                <Box sx={{ maxWidth: '100%', margin: 0 }}>
                    <Grid container spacing={0}>
                        {/* Header with video background and greeting */}
                        <Grid item xs={12} md={12}>
                            <div style={{ position: 'relative', minHeight: 150 }}>
                                <video
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        zIndex: 0,
                                    }}
                                    className="videoTag"
                                    autoPlay
                                    loop
                                    muted
                                >
                                    <source src="/media/dustwaves.mp4" type="video/mp4" />
                                </video>
                                <Card
                                    sx={{ minWidth: 275, minHeight: 300 }}
                                    elevation={0}
                                    style={{
                                        backgroundColor: 'rgba(255, 255, 255, 0)',
                                        zIndex: 1,
                                        position: 'relative'
                                    }}
                                >
                                    <CardContent>
                                        <Typography variant="h3" color="white" gutterBottom>
                                            Hello {nickname}!
                                        </Typography>
                                        {/* {this.context.mentorView ? (
                                            <Typography variant="h5" color="white" gutterBottom>
                                                You are in mentor view
                                                <br /><br />
                                                <Button
                                                    size="medium"
                                                    variant="contained"
                                                    onClick={() => { this.context.setPage("inspectgroupsessions") }}
                                                >
                                                    Group Sessions History
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button
                                                    size="medium"
                                                    variant="contained"
                                                    onClick={() => { this.context.setPage("inspectpupil") }}
                                                >
                                                    Mentee History
                                                </Button>
                                            </Typography>
                                        ) : (
                                            <Typography variant="body1" color="white" gutterBottom>
                                                Your adaptive difficulty score is {Math.floor(this.context.difficulty)}
                                                <br /><br />
                                                <Button
                                                    size="medium"
                                                    variant="contained"
                                                    onClick={() => { this.context.setPage("sessions") }}
                                                >
                                                    History
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button
                                                    size="medium"
                                                    variant="contained"
                                                    onClick={() => { this.context.setPage("campaigns") }}
                                                >
                                                    Library
                                                </Button>
                                            </Typography>
                                        )} */}

                                        {this.context.usageLimited && (
                                            <Paper
                                                elevation={3}
                                                sx={{
                                                    backgroundColor: 'rgba(100, 100, 100, 0.8)',
                                                    padding: 2,
                                                    margin: 5,
                                                    borderRadius: 1,
                                                }}
                                            >
                                                <Typography variant="h6" color="white" gutterBottom>
                                                    Daily exercise limit reached. Relax and come back tomorrow.
                                                </Typography>
                                                <Typography variant="body1" color="white" gutterBottom>
                                                    Your mind needs time to relax and learn. You can still watch video tutorials in the Library or inspect your History.
                                                </Typography>
                                            </Paper>
                                        )}
                                    </CardContent>
                                </Card>
                            </div>
                        </Grid>

                        {/* NEW: Firmware Upgrade Card */}
                        {this.context.firmwareUpgrade && (
                            <Grid item xs={12} style={{ padding: '10px', marginBottom: '20px' }}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">
                                            Device Update Available
                                        </Typography>
                                        <Typography variant="body2" sx={{ mb: 2 }}>
                                            A new device firmware ({this.context.latestFirmware}) is available.
                                        </Typography>
                                        <Button variant="contained" color="primary" onClick={() => this.context.setPage("ota")}>
                                            Upgrade Now
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}

                        {/* Course Card with inline dropdown icon */}
                        <Grid
                            item
                            xs={12}
                            style={{
                                width: '80%', // changed to 80% width of the screen
                                margin: '0 auto', // center the container
                                padding: '20px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            {currentCampaign ? this.renderCourseCard(currentCampaign, showSelector) : (
                                <Typography variant="body1" color="text.secondary">
                                    No valid courses available.
                                </Typography>
                            )}
                        </Grid>


                        {/* Footer */}
                        <Grid item xs={12}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                align="center"
                                style={{ marginTop: '20px' }}
                            >
                                <a href="https://obimon.com">obimon.com</a>
                                <br /><br />
                                General questions: <a href="mailto:info@obimon.com">info@obimon.com</a>
                                <br />
                                Technical support: <a href="mailto:support@obimon.com">support@obimon.com</a>
                                <br /><br />
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Menu for selecting a course */}
                {showSelector && (
                    <Menu
                        anchorEl={this.state.anchorEl}
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.handleMenuClose}
                    >
                        {validCampaigns.map(camp => (
                            <MenuItem
                                key={camp}
                                onClick={() => this.handleCourseSelect(camp)}
                            >
                                {this.campdata[camp].label}
                            </MenuItem>
                        ))}
                    </Menu>
                )}

                {/* Popup Modal for points explanation */}
                <Dialog open={this.state.popupOpen} onClose={this.handlePopupClose}>
                    <DialogTitle>Points Explanation</DialogTitle>
                    <DialogContent>
                        <Typography align="left">
                            <strong>Difficulty</strong> is your adaptive difficulty level.
                            <br />
                            <strong>Relax</strong> points are earned whenever you reduce your stress level
                            <br />
                            <strong>Control</strong> points are earned by the speed and efficiency of how
                            fast you can reduce your arousal by a significant amount.
                            <br />
                            <strong>Balance</strong> points are earned by being able to maintain a reduced stress setState.
                            <br />
                            <strong>Gardener</strong> points show how many trees and flowers you have grown.

                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePopupClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default Dashboard;
