import React, { Component } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// import { withSnackbar } from 'notistack';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

// import { DataGrid } from '@mui/x-data-grid';
import { TextField } from '@mui/material';

import campaign_data from '../scripts/campaign_data';
// import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';


import { MyContext } from '../MyContext';


class ManageUser extends Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);


    this.state = {
      user: "",
      userSelected: false,
      labelValue: "",
      showEda: false,
    }

    this.pupils = [];

    this.campaign_data = campaign_data();

    this.selected = [];


    console.log("ManageUser contructor");
  }

  async getUser(user) {
    let resp = await fetch('/api/getuser', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: this.state.user,
      })
    });

    if (!resp.ok) {
      enqueueSnackbar('User not found', { variant: 'error' });
      return;
    }

    let userData = await resp.json();
    console.log("User Data:", userData);

    let showEda = false;
    if (userData.showEda) showEda = true;

    let limitDailyTime = 0;
    if (userData.limitDailyTime) limitDailyTime = userData.limitDailyTime;

    let p = ""
    if (userData.pupils) {
      for (let i in userData.pupils) {
        p += userData.pupils[i];
        if (i < userData.pupils.length - 1) p += ", ";
      }
    }


    this.setState({ showEda: showEda, pupilValue: p, limitDailyTime: limitDailyTime });

    return userData;
  }


  async setUser(user) {
    console.log("setUser showEda:", this.state.showEda);

    let resp = await fetch('/api/setuserdata', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: this.state.user,
        showEda: this.state.showEda,
        pupils: this.pupils,
        limitDailyTime: this.state.limitDailyTime
      })
    });

    let userData = await resp.json();
    console.log("Set User Data response:", userData);

    return userData;
  }

  async onSubmit(event) {

    console.log("onSubmit:" + this.state.user);

    event.preventDefault();

    this.userData = await this.getUser();

    let resp = await fetch('/api/getcampaigns', {
      method: 'POST',
      body: JSON.stringify({ user: this.state.user }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })

    console.log("resp:", resp);

    if (resp.status != 200) {
      enqueueSnackbar('Error fetching campaigns.', { variant: 'error' });
      return;
    }


    let pp = await resp.json();

    // let c = "";
    // if("courses" in pp) c = pp.courses.toString();
    // this.setState({courseValue: c});


    let p = [];
    if (pp.campaigns) p = pp.campaigns;

    console.log("Get campaigns loaded:" + JSON.stringify(p));

    this.selected = [];
    for (let camp of Object.keys(this.campaign_data)) {
      // let camp = this.campaign_data[i];

      console.log("CAMP", camp);

      if ((p.indexOf(camp) > -1)) this.selected.push(true);
      else this.selected.push(false);

    }

    console.log("selected", this.selected);

    let label = "";
    if (pp.label) label = pp.label;

    this.setState({ userSelected: true, labelValue: label });
  }


  async save() {
    console.log("Save----------");

    this.pupils = [];
    this.pupils = this.state.pupilValue.split(/[\s,]+/);

    if (this.pupils.length === 1 && this.pupils[0] === '') this.pupils = [];

    console.log("PUPILS:", this.pupils, this.pupils.length);

    await this.setUser();

    console.log('XXX');

    let c = [];
    let i = 0;
    for (let camp of Object.keys(this.campaign_data)) {
      if (this.selected[i]) c.push(camp);
      i += 1;
    }

    // let courses = this.state.courseValue.split(/[\s,]+/);
    // console.log("XXX save courses", courses);


    let data = {
      user: this.state.user,
      campaigns: c,
      // courses: courses
    }

    if (this.state.labelValue && this.state.labelValue !== "") data.label = this.state.labelValue;

    console.log("save:", data);


    fetch('/api/setcampaigns', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => {
        if (res.status === 200) {
          enqueueSnackbar('Campaigns saved.', { variant: 'success' });

          //this.props.onRegisterFinished();

        } else {
          enqueueSnackbar('Error saving campaigns.', { variant: 'error' });
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar('Error2 saving campaigns.', { variant: 'error' });
      });


  }

  handleChange(id, event, checked) {
    console.log("event:", id, checked);

    this.selected[id] = checked;

    console.log("SELECTED", this.selected);
  }

  handleShowEDA(event, checked) {
    console.log("handleShowEDA:", checked);

    this.state.showEda = checked;

  }



  handleLabelChange(event) {
    this.setState({ labelValue: event.target.value })
  }



  handlePupilChange(event) {
    this.setState({ pupilValue: event.target.value })
  }

  // handleCourseChange(event) {
  //   this.setState({ courseValue: event.target.value })
  // }

  handleLimitDailyTimeChange(event) {
    if (event.target.value < 0) event.target.value = 0;
    this.setState({ limitDailyTime: event.target.value })
  }

  render() {

    if (this.state.userSelected) {

      let c = [];

      let i = 0;
      for (let [campname, camp] of Object.entries(this.campaign_data)) {
        // let camp = this.campaign_data[i];

        if (this.selected[i])
          c.push(
            <FormControlLabel control={<Switch defaultChecked onChange={this.handleChange.bind(this, i)} />} label={camp.label} />
          )
        else
          c.push(
            <FormControlLabel control={<Switch onChange={this.handleChange.bind(this, i)} />} label={camp.label} />
          )

        i += 1;
      }

      let s = <FormControlLabel control={<Switch onChange={this.handleShowEDA.bind(this)} />} label="show EDA" />;
      if (this.state.showEda) {
        s = <FormControlLabel control={<Switch defaultChecked onChange={this.handleShowEDA.bind(this)} />} label="show EDA" />;
      }

      return (
        <div>
          <p>User: {this.state.user}</p>
          <Box maxWidth='90%' margin={3} alignItems="center" justifyContent="center" display="flex">
            <FormGroup>
              {c}
            </FormGroup>
          </Box >

          Label:
          <br></br>
          <TextField
            helperText="label (will be added to all exercises of this user)"
            value={this.state.labelValue}
            onChange={this.handleLabelChange.bind(this)}
          />
          <br></br>
          <br></br>

          {s}
          <br></br>
          <br></br>

          Pupils:
          <br></br>
          <TextField
            helperText="List pupils, separated by comma, e.g.: user1, user2, user3"
            value={this.state.pupilValue}
            onChange={this.handlePupilChange.bind(this)}
          />
          <br></br>
          <br></br>

          {/* Courses:
          <br></br>
          <TextField
            helperText="List courses, separated by comma, e.g.: cbt1, cbt2, cbt3"
            value={this.state.courseValue}
            onChange={this.handleCourseChange.bind(this)}
          />
          <br></br>
          <br></br> */}
          Limit daily usage in  minutes:
          <br></br>

          <TextField
            type="number"
            inputProps={{ min: 0, max: 100 }}
            helperText="Limit total time spent with exercises per day in minutes"
            value={this.state.limitDailyTime}
            onChange={this.handleLimitDailyTimeChange.bind(this)}
          />

          <br></br>
          <br></br>

          <Button variant="contained" onClick={this.save.bind(this)}>Submit</Button>
        </div>
      );
    }


    //       return(
    //         <div>
    //           <p>{this.state.user}</p>
    //           <p>{JSON.stringify(this.state.savedProgress)}</p>
    //           <form onSubmit={this.onSubmitProgress}>

    //               <label>
    //                 Course:
    //                 <input
    //                   name="course"
    //                   value={this.state.course}
    //                   onChange={event => this.setState({
    //                     course: event.target.value,
    //                   })}
    //                   required
    //                 />
    //               </label>

    //               <label>
    //                 Progress:
    //                 <input
    //                   name="progress"
    //                   value={this.state.progress}
    //                   onChange={event => this.setState({
    //                     progress: event.target.value,
    //                   })}     
    //                   required
    //                 />
    //               </label>

    //               <input type="submit" value="Submit"/>

    //           </form>
    //       </div>
    //       );
    //     }
    //  */

    return (
      <Box maxWidth='90%' margin={3} alignItems="center" justifyContent="center" display="flex">
        <form onSubmit={this.onSubmit.bind(this)}>
          <p>Select user to manage:</p>
          <input
            type="text"
            name="user"
            value={this.state.user}
            placeholder=""
            onChange={event => this.setState({
              user: event.target.value,
            })}
            required
          />
          <input type="submit" value="Submit" />
        </form>
      </Box>

    );
  }
}

// export default withSnackbar(ManageUser);
export default ManageUser;