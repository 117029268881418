import React, { Component } from 'react';
import Wave from 'react-wavify';
import { InputLabel, FormControl, OutlinedInput, Button, TextField, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      confirmPassword: '', // added for confirming password
      showRegistration: false,
      email: '',
      registrationCode: '',
      showPassword: false
    };
  }

  handleInputChange(type, event) {
    this.setState({
      [type]: event.target.value
    });
  }

  onSubmit = async (event) => {
    event.preventDefault();
    try {
      let pwd = this.state.password;

      if(this.state.password === '') {
        pwd = 'passwordless';
      }

      const response = await fetch('/authenticate', {
        method: 'POST',
        body: JSON.stringify({ user: this.state.user, password: pwd }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log("LOGIN response:", data);

        this.props.onLogin(data);
      } else {
        console.log("LOGIN response:", response);
        alert('Login failed: ' + response);
      }
    } catch (err) {
      console.error(err);
      alert('Error logging in. Please try again.');
    }
  };

  onRegister = async (event) => {
    event.preventDefault();
    // Check if passwords match before proceeding
    if (this.state.password !== this.state.confirmPassword) {
      alert("Passwords do not match.");
      return;
    }
    try {
      console.log("email:", this.state.email, "user:", this.state.user, "password:", this.state.password, "code:", this.state.registrationCode);
      const response = await fetch('/api/register_with_code', {
        method: 'POST',
        body: JSON.stringify({
          nickname: this.state.nickname,
          user: this.state.email,
          password: this.state.password,
          code: this.state.registrationCode
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const data = await response.text();
      if (response.ok) {
        alert(data);
        this.setState({ showRegistration: false });
      } else {
        alert(`Registration failed: ${data}`);
      }
    } catch (err) {
      console.error(err);
      alert('Error registering user. Please try again.');
    }
  };

  toggleRegistration = () => {
    this.setState((prevState) => ({ showRegistration: !prevState.showRegistration }));
  };

  render() {
    const { showRegistration } = this.state;

    return (
      <div>
        <br /><br /><br />
        {!showRegistration ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px', mx: 'auto' }}>
            <TextField
              label="User"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.user}
              onChange={(e) => this.handleInputChange('user', e)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.password}
              onChange={(e) => this.handleInputChange('password', e)}
            />
            <Button variant="contained" fullWidth sx={{ mt: 2 }} onClick={this.onSubmit}>
              LOGIN
            </Button>
            <Button variant="text" fullWidth sx={{ mt: 1 }} onClick={this.toggleRegistration}>
              REGISTER
            </Button>
            <Box sx={{ mt: 1 }}>
              <a href="/forgot-password">Forgot Password?</a>
            </Box>
          </Box>
        ) : (
          <Box
            component="form"
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '300px', mx: 'auto', mt: 2 }}
            onSubmit={this.onRegister}
          >
            <Typography variant="h5" gutterBottom>
              Register
            </Typography>
            <TextField
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.email}
              onChange={(e) => this.handleInputChange('email', e)}
            />
            <TextField
              label="Registration Code"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.registrationCode}
              onChange={(e) => this.handleInputChange('registrationCode', e)}
            />
            <TextField
              label="Display Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.nickname}
              onChange={(e) => this.handleInputChange('nickname', e)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.password}
              onChange={(e) => this.handleInputChange('password', e)}
            />
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              margin="normal"
              value={this.state.confirmPassword}
              onChange={(e) => this.handleInputChange('confirmPassword', e)}
            />
            <Button variant="contained" fullWidth sx={{ mt: 2 }} type="submit">
              REGISTER
            </Button>
            <Button variant="text" fullWidth sx={{ mt: 1 }} onClick={this.toggleRegistration}>
              BACK TO LOGIN
            </Button>
          </Box>
        )}

        <div
          className="intro-wave"
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: -1
          }}
        >
          <Wave
            fill='#B289EF'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 7
            }}
            style={{ width: '100%', height: 'auto' }}
          />
          {/* <Wave
            fill='rgba(150, 97, 255, .8)'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 5
            }}
            style={{ width: '100%', height: 'auto' }}
          /> */}
        </div>

        <div
          className="intro-wave"
          style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            zIndex: -1
          }}
        >
          {/* <Wave
            fill='#B289EF'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 7
            }}
            style={{ width: '100%', height: 'auto' }}
          /> */}
          <Wave
            fill='rgba(150, 97, 255, .8)'
            paused={false}
            options={{
              height: 20,
              amplitude: 30,
              speed: 0.15,
              points: 5
            }}
            style={{ width: '100%', height: 'auto' }}
          />
        </div>

        <br></br><br></br>

        <Typography variant="body2" color="text.secondary">
          For more info, visit our homepage: <a href="https://obimon.com" onclick="window.onbeforeunload = null;">obimon.com</a>
        </Typography>

        <br></br>

        <Typography variant="body2" color="text.secondary">
          Contact us: <a href="mailto:info@obimon.com">info@obimon.com</a>
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Technical support: <a href="mailto:support@obimon.com">support@obimon.com</a>
        </Typography>

      </div>


    );
  }
}