import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const VideoTutorialCard = ({ title, date, time }) => {
  return (
    <Card elevation={5} sx={{ height: '100%' }}>
      <CardContent
        sx={{ display: 'flex', flexDirection: 'column', height: '100%', p: 2 }}
      >
        {/* Top section with centered date and time */}
        <Typography variant="h6" color="text.primary" sx={{ alignSelf: 'center' }}>
          {date} {time}
        </Typography>
        {/* Center section with icon and title */}
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <VideoLibraryIcon sx={{ fontSize: 40, mb: 1 }} />
          <Typography variant="h6">{title}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VideoTutorialCard;
