import { isUndefined } from 'lodash';
import React, { Component } from 'react';

import WaveResultCard from '../WaveResultCard.js';
import GroupResultCard from '../GroupResultCard.js';
import VideoTutorialCard from '../VideoTutorialCard';

//import Backdrop from '@mui/material/Backdrop';

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Stack } from '@mui/material';
//import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';

import campaign_data from '../scripts/campaign_data';
import { findCampHack } from '../scripts/campaign_data';
import adjust_timesteps from '../scripts/adjust_timesteps';
import isValidEdaSession from '../scripts/check_eda_session.js';

export default class MySessions extends Component {

  constructor(props) {
    super(props);

    // this.exercise_data = exercise_data();
    this.campdata = campaign_data();

    this.state = {
      selectWeek: 0,
      loaded: false

    }

    // this.groups = [];

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.filterDate) {
      const filterDateObj = new Date(this.props.filterDate);
      if (!isNaN(filterDateObj.getTime())) {
        const filterWeek = filterDateObj.getFullYear() * 52 + this.getWeek(filterDateObj);
        const now = new Date();
        const thisWeek = now.getFullYear() * 52 + this.getWeek(now);
        const diffWeek = thisWeek - filterWeek;
        if (diffWeek !== this.state.selectWeek) {
          this.setState({ selectWeek: diffWeek });
        }
      }
    }
  }

  // Add this method to auto-update the week when filterDate changes.
  componentDidUpdate(prevProps) {
    if (this.props.filterDate && this.props.filterDate !== prevProps.filterDate) {
      const filterDateObj = new Date(this.props.filterDate);
      if (!isNaN(filterDateObj.getTime())) {
        const filterWeek = filterDateObj.getFullYear() * 52 + this.getWeek(filterDateObj);
        const now = new Date();
        const thisWeek = now.getFullYear() * 52 + this.getWeek(now);
        const diffWeek = thisWeek - filterWeek;
        if (diffWeek !== this.state.selectWeek) {
          this.setState({ selectWeek: diffWeek });
        }
      }
    }
  }

  // Returns the ISO week of the date.
  getWeek = function (date) {
    //var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
      - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  handlePageChange(e, value) {
    //console.log("handlePageChange", value);

    let w = value - 1;

    this.setState({ selectWeek: w });
  }

  // checkGroups(s) {
  //   console.log("checkGroups", s);

  //   s.data.eda = adjust_timesteps(s.data.eda);

  //   let found = false;
  //   for(let g of this.groups) {
  //     console.log("group", g);

  //     if(g.campaign != s.campaign) continue;
  //     if(g.exercise != s.exercise) continue;

  //     if(Math.abs(g.ts - s.ts) > 4*60e3) continue;

  //     g.sessions.push(s);      
  //     if(s.ts > g.ts) g.ts = s.ts;

  //     console.log("added session to existing group", this.groups);
  //     return;    
  //   }

  //   //  not found, create new group
  //   this.groups.push({
  //     ts: s.ts,
  //     campaign: s.campaign,
  //     exercise: s.exercise,
  //     sessions: [s]
  //   });

  //   console.log("created new group", this.groups);

  // }

  render() {

    if (!this.props.sessions) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      );
    }

    // Filter sessions by campaign and date if specified in context.
    let sessions = this.props.sessions;
    const campaignFilter = this.context.campaign;
    const filterDate = this.props.filterDate; // expected format "yyyy-MM-dd"

    if (campaignFilter) {
      console.log("Filtering by campaign", campaignFilter);
      sessions = sessions.filter(s => s.campaign === campaignFilter);
    }
    if (filterDate) {
      console.log("Filtering by date", filterDate);
      sessions = sessions.filter(s => {
        const d = new Date(s.ts);
        if (isNaN(d.getTime())) return false; // invalid timestamp, skip
        return d.toISOString().slice(0, 10) === filterDate;
      });
    }

    //console.log("MySessions: ", this.state.sessions);

    if (this.props.sessions.length === 0) {
      return (
        <Card>No sessions yet!</Card>
      );
    }

    sessions.sort((a, b) => { return (b.ts - a.ts) });

    let now = new Date();
    let thisYear = now.getFullYear();
    let thisWeek = this.getWeek(now);

    //console.log("thisWeek", thisWeek);

    thisWeek = thisYear * 52 + thisWeek;
    //console.log("thisWeek", thisWeek);

    let nWeek = 1;

    let prevt = 0;

    this.groups = [];

    let s = {}
    for (let i in sessions) {

      let ses = sessions[i];

      let campname = false;

      if (!ses.campaign) continue;

      campname = ses.campaign;

      let ex = this.campdata[campname].exercises[ses.exercise];

      if (this.campdata[campname].exercises[ses.exercise] === undefined) {
        console.log("WARNING exercise not found:", ses.exercise);
        continue;
      }



      let t = ses.ts;

      // if(now - t <100) {
      //   console.log(ses);
      // }

      if (t === prevt) {
        // console.log("Duplicate record", t, ses);
        continue;
      }
      prevt = t;

      let d = new Date(t);
      let week = this.getWeek(d);
      let year = d.getFullYear();

      week = year * 52 + week;

      let diffWeek = thisWeek - week;
      if (diffWeek > nWeek) nWeek = diffWeek;
      if (diffWeek != this.state.selectWeek) continue;

      //console.log(JSON.stringify(ses));


      // console.log(ses);

      //console.log("MySessions sessionStats:", sessionStats)

      //console.log("Weekly session", ses);

      var date = new Date(t).toLocaleDateString("en-US");
      var time = new Date(t).toLocaleTimeString("en-US");

      // let scores = sessions[i].data.scores;

      // console.log("t", t);
      // console.log("s", ses);

      // if(this.campdata[campname].group) this.checkGroups(ses);

      // s.push(
      //   <Grid item key={t+"_g"} xs={12} md={6} lg={4} xl={3}>
      //     <WaveResultCard key={t+"w"} session={ses}></WaveResultCard>
      //   </Grid>
      // )

      // NEW: Check session type to decide which card to render
      if (ses.type === "VideoTutorial") {
        const title = ex.label;
        // Retrieve date and time strings for the session
        var date = new Date(t).toLocaleDateString("en-US");
        var time = new Date(t).toLocaleTimeString("en-US");
        s[t] = (
          <Grid item key={t + "_g"} xs={12} md={6} lg={4} xl={3}>
            <VideoTutorialCard title={title} date={date} time={time} />
          </Grid>
        );
      } else {
        if(!isValidEdaSession(ses)) continue;

        s[t] = (
          <Grid item key={t + "_g"} xs={12} md={6} lg={4} xl={3}>
            <WaveResultCard key={t + "w"} session={ses} />
          </Grid>
        );
      }

    }

    // // now add groups
    // for(let g of this.groups) {
    //   s[g.ts+1] = <Grid item key={g.ts+"_group"} xs={12} md={6} lg={4} xl={3}>
    //     <GroupResultCard key={g.ts+"_wg"} group={g}></GroupResultCard>
    //   </Grid>

    // }

    // console.log("s", Object.values(s));

    if (s.length === 0) {
      s[0] =
        <Paper style={{
          padding: 50,
          margin: 100
        }}>
          <Typography variant="body">No data on selected week</Typography>
        </Paper>

    }

    let keys = Object.keys(s)
    keys.sort().reverse();

    let sorted_s = []
    for (let i of keys) {
      sorted_s.push(s[i]);
    }

    //console.log("SCORES", scores);

    return (
      <Box maxWidth='90%' margin={3}>
        <Grid container spacing={2} justifyContent="center" alignItems="stretch">
          {!this.props.filterDate && (
            <Grid item xs={12} container spacing={3} alignItems="center">
              <Grid item xs={4} textAlign="right">
                <Typography variant="body">
                  Select week:
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Pagination count={52} color="primary" onChange={this.handlePageChange.bind(this)} />
              </Grid>
            </Grid>
          )}
          {sorted_s}
        </Grid>
      </Box>
    );


    return (
      <div className="mysessions">
        <Box margin={1}>
          <Stack spacing={1}>
            {s}
          </Stack>
        </Box>
      </div>
    );

  }



}