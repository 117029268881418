import React, { Component } from 'react';

// import BoltIcon from '@mui/icons-material/Bolt';
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
// import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';

// import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
// import BalanceIcon from '@mui/icons-material/Balance';

// import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
// import StarBorderIcon from '@mui/icons-material/StarBorder';
// import StarRateIcon from '@mui/icons-material/StarRate';
// import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
// import FilterVintageIcon from '@mui/icons-material/FilterVintage';

class WaveResultPlot extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scores: this.props.scores
    }

    this.waveRef = React.createRef();
  }

  draw() {
    this.context = this.waveRef.current.getContext('2d');

    this.context.fillStyle = '#eee';
    this.context.fillRect(0, 0, this.context.canvas.width, this.context.canvas.height);
    //this.context.fillRect(0, 0, 10,10);

    let scores = this.props.scores;

    if(scores.length<10) return;

    let t0 = scores[0][0];
    let dt = scores[scores.length-1][0]-t0;
    var lastx = 0;

    let mindt = 180*1000; // 3 minutes
    if(dt < mindt) dt = mindt;

    for (var i = 0; i < scores.length-4; i++) {
      var sl = scores[i][1];
      var sr = scores[i][2];

      var sl_next = scores[i+4][1];
      var sr_next = scores[i+4][2];


      var t = scores[i][0]-t0;

      var x = (t/dt) * this.context.canvas.width;

      var c = '#000';

      var sl_dec = 0;
      if((sl >= sl_next || sl === 0) && sl<150) sl_dec = 1;

      var sr_dec = 0;
      if((sr >= sr_next || sr === 0) && sr<150) sr_dec = 1;

      if(sl_dec && sr_dec) c = '#0f0';
      else if(sl_dec==0 && sr_dec==0) c = '#f00';
      else c = '#ff0';

      this.context.fillStyle = c;
      this.context.fillRect(x, 0, (x-lastx+2), this.context.canvas.height);

      lastx = x;
  
    }
  }

  componentDidUpdate() {
    this.draw();
  }

  componentDidMount() {
    this.draw();
  }


  render() {
  
    return (
      <canvas ref={this.waveRef} height="50" />
    )
  }

}

export default WaveResultPlot;