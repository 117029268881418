// AdaptiveDifficultyChart.jsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { calcDifficultyFromParams } from './scripts/calc_difficulty';

const AdaptiveDifficultyChart = ({ sessions }) => {
  // Compute the boundary date for 3 months ago
  const threeMonthsAgo = new Date();
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

  // Compute difficulty for a given session using its difficulty_params.
  const getSessionDifficulty = (session) => {
    if (!session.data || !session.data.difficulty_params) return null;
    const [nWave, diff] = calcDifficultyFromParams(session.data.difficulty_params);
    return diff;
  };

  // Filter sessions that have valid difficulty data and are within the last 3 months.
  const validSessions = sessions.filter((s) => {
    if (!s.data || !s.data.difficulty_params) return false;
    const sessionDate = new Date(s.ts);
    return sessionDate >= threeMonthsAgo;
  });

  // Group sessions by date and keep only the max difficulty per day.
  const difficultiesByDay = {};
  validSessions.forEach((session) => {
    // Changed: Show only month/day.
    const dateStr = new Date(session.ts).toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' });
    const diff = getSessionDifficulty(session);
    if (diff !== null) {
      if (!difficultiesByDay[dateStr] || diff > difficultiesByDay[dateStr]) {
        difficultiesByDay[dateStr] = diff;
      }
    }
  });

  // Sort dates and prepare labels and data values.
  const sortedDates = Object.keys(difficultiesByDay).sort((a, b) => new Date(a) - new Date(b));
  const labels = sortedDates;
  const dataValues = sortedDates.map(date => difficultiesByDay[date]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Adaptive Difficulty',
        data: dataValues,
        backgroundColor: 'rgba(104, 49, 218, 0.6)', // example color with transparency
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          clip: false, // prevent label clipping
          maxRotation: 45,
          minRotation: 0,
          padding: 20, // adjust as needed
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `Difficulty: ${Math.floor(context.parsed.y)}`,
        },
      },
    },
  };

  return (
    <Box sx={{ width: '100%', height: 250, mb: 2 }}> {/* added bottom margin */}
      <Typography variant="h6" gutterBottom>
        Adaptive Difficulty Trends
      </Typography>
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Bar data={data} options={options} />
      </Box>
    </Box>
  );
};

export default AdaptiveDifficultyChart;
