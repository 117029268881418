
export class CalcScore {

    constructor(p) {


        // current scores for l and r
        this.s_l = 100;
        this.s_r = 100;

        // avgerage values for l and r
        this.avg_l = 0;
        this.avg_r = 0;

        this.r_hist = [];
        this.r_hist_len = 0;

        this.l_hist = [];
        this.l_hist_len = 0;

        // max values
        this.max_l = 150;
        this.max_r = 150;

        // alphas 
        this.alpha_l = 1.0 / 4.0 / 15.0;
        this.alpha_r = 1.0 / 4.0 / 1.0;

        this.t0 = 0;
        this.last_avg_l = 0;
        this.last_avg_r = 0;

        this.init = 0;

        this.set_params(p);

        //console.log("PARAMS:", p)

    }

    set_params(p) {
        this.l_incr = p.l_incr;
        this.l_decr = p.l_decr;

        this.r_incr = p.r_incr;
        this.r_decr = p.r_decr;
    }


    calc_final_score(scores) {

        let min = 0;
        let t0 = 0;
        let zero_t = 0;

        let after_a = 0;
        let after_n = 0;

        for (var i = 0; i < scores.length; i++) {

            let [t, sl, sr] = scores[i]

            if (t0 === 0) t0 = t;

            let s = Math.max(sl, sr);

            //console.log("XXXX=", scores[i], "-->", s);

            if (min === 0 || s < min) {
                min = s;

                if (s === 0 && zero_t === 0) zero_t = t;
            }

            if (min === 0 && t > zero_t) {
                after_a += s;
                after_n++;
            }

        }

        let p0 = 0, p1 = 0, p2 = 0;

        let final_score = {}
        final_score.min = min;

        if (zero_t > 0) final_score.zero_t = zero_t - t0;

        if (after_n > 10) {
            after_a /= after_n;

            final_score.after_a = after_a;
            final_score.after_n = after_n;
        }

        //final_score.zero_t = 3000;
        //final_score.after_a = 20;

        //console.log("FINAL_SCORE:", final_score);
        return final_score;

    }

    calc_one(t, gsr) {

        if (this.init === 0) {
            this.avg_l = gsr;
            this.avg_r = gsr;

            this.init = 1;

        }

        if(gsr>100) this.avg_l = (this.alpha_l) * gsr + (1 - this.alpha_l) * this.avg_l;
        this.l_hist.push(this.avg_l);
        this.l_hist_len += 1;


        if (this.l_hist_len > 8) {
            let last_l = this.l_hist[this.l_hist_len - 8];

            //console.log(last_r, this.avg_r);

            if (this.avg_l > last_l + 1) {
                this.s_l += this.l_incr;
            } else {
                if (this.s_l > 0) this.s_l -= this.l_decr;
            }
        }


        // if (this.avg_l > this.last_avg_l) {
        //     this.s_l += this.l_incr;
        // } else {
        //     if (this.s_l > 0) this.s_l -= this.l_decr;
        // }

        if(gsr>100) this.avg_r = (this.alpha_r) * gsr + (1 - this.alpha_r) * this.avg_r;
        this.r_hist.push(this.avg_r);
        this.r_hist_len += 1;


        if (this.r_hist_len > 8) {
            let last_r = this.r_hist[this.r_hist_len - 8];

            //console.log(last_r, this.avg_r);

            if (this.avg_r > last_r + 1) {
                this.s_r += this.r_incr;
            } else {
                if (this.s_r > 0) this.s_r -= this.r_decr;
            }
        }

        // if (gsr > this.avg_r) {
        //     this.s_r += this.r_incr;
        // } else {
        //     if (this.s_r > 0) this.s_r-= this.r_decr;
        // }

        if (this.s_l > this.max_l) this.s_l = this.max_l;
        if (this.s_r > this.max_r) this.s_r = this.max_r;

        //console.log("ALT SCORE: ", this.s_l, this.s_r);

        //scores_r.push({ x: x, y: e[2] })

        this.last_avg_l = this.avg_l;
        this.last_avg_r = this.avg_r;

        if(this.s_l < 0) this.s_l = 0;
        if(this.s_r < 0) this.s_r = 0;

        return [this.s_l, this.s_r];

    }

    calc_all(eda) {

        console.log("DIFF calc_all");

        // here we store all the scores with time as list
        let scores_l = [];
        let scores_r = [];
        let t0 = eda[0][0];


        eda.forEach(e => {
            let sl, sr;
            [sl, sr] = this.calc_one(e[0], e[1]);

            const x = (e[0] - t0) / 1000.0;

            scores_l.push({ x: x, y: sl })
            scores_r.push({ x: x, y: sr })

        });

        return [scores_l, scores_r];
    }

    calc_all_db(eda) {
        let scores=[];

        for(let [ts,gsr] of eda) {
            let sl, sr;
            [sl, sr] = this.calc_one(ts, gsr);
            scores.push([ts,sl,sr]);
        }

        return scores;
    }

}

export default CalcScore;