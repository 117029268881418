import React, { useState } from 'react';
import { TextField, Button, Typography } from '@mui/material';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const text = await response.text();
      setMessage(text);
    } catch (error) {
      setMessage('Error sending request.');
    }
  };

  return (
    <div style={{ padding: '2rem' }}>
      <Typography variant="h5">Forgot Password</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Send Reset Link
        </Button>
      </form>
      {message && <Typography style={{ marginTop: '1rem' }}>{message}</Typography>}
    </div>
  );
};

export default ForgotPassword;
