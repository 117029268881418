import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

const MentorDashboard = () => {
  const [mentees, setMentees] = useState([]);

  useEffect(() => {
    // Fetch the mentee data from the backend
    fetch('/api/get-mentees', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to fetch mentees");
        }
        return res.json();
      })
      .then((data) => setMentees(data))
      .catch((err) => console.error("Error fetching mentees:", err));
  }, []);

  return (
    <div style={{ padding: 20 }}>
      <Typography variant="h4" gutterBottom>
        My Mentees
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>User Name</strong></TableCell>
              <TableCell><strong>Nickname</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mentees.map((mentee, index) => (
              <TableRow key={index}>
                <TableCell>{mentee.user}</TableCell>
                <TableCell>{mentee.nickname}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MentorDashboard;
