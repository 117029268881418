import React from 'react';
// import ReactHowler from 'react-howler'
import { Howl, Howler } from 'howler';

import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeDown from '@mui/icons-material/VolumeDown';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// import { text_audiomodule_data } from './text_audiomodule_data.js';


// files from https://www.zapsplat.com/ or https://freesound.org/

class AudioModule extends React.Component {
  constructor(props) {
    super(props);

    console.log("Audio moduleconstructor ");

    this.timeout = 0;
    this.loaded = false;

    this.players = [];
    this.seek = [];
    this.finished = [];

    this.state = {
      audioEnded: false,
      volume: 100,
      msg: ""
    }

    let files = props.params.audioFiles;
    let audioDir = props.params.audioDir;

    for (let i in files) {
      let audio = files[i][0];
      let src = audioDir + audio;

      this.seek.push(0);
      this.finished.push(false);


      let rate = 1;
      //if(process.env.NODE_ENV === 'development') rate = 50;

      let h = new Howl({
        src: src,
        html5: true,
        // onplayerror: function(e) {
        //   console.log("onplayerror", e);
        //   h.once('unlock', function() {
        //     h.play();
        //   });
        // },
        onunlock: this.audioUnlock.bind(this, i),
        onload: this.audioLoad(this,i),
        preload: 'metadata',
        autoplay: false,
        rate: rate,
        onend: this.audioFinished.bind(this, i)
      }
      );

      //h.mobileAutoEnable = true;
      //h.autoUnlock = true;

      this.players.push(h);
    }

    // this.text = text_audiomodule_data()[this.props.exercise];

    console.log("GGGG", this.text);

    this.timer = setInterval(this.update.bind(this), 1000);

    this.filterSessions();
  }

  audioLoad(i) {
    console.log("LOAD", i);

    //this.setState({msg: this.state.msg+ "LOAD"+i});
  }

  audioUnlock(i) {
    console.log("UNLOCK", i);

    //this.setState({msg: this.state.msg + "UNLOCK"+i});

  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;
    console.log('AM update');

    this.filterSessions();
  }

  filterSessions() {
    //console.log("Filter", this.props.sessions.length);

    let sessions = this.props.sessions;
    for (let ses of sessions) {
      if (ses.type !== "AudioModule") continue;
      let audio = ses.data.audioFinished;
      if (!audio) continue;

      console.log(ses.data);

      let files = this.props.params.audioFiles;
      for (let j in files) {
        if (files[j][0] === audio) {
          this.finished[j] = true;
          break;
        }
      }
    }

    console.log("F", this.finished);

  }

  update() {
    for (let i in this.players) {
      let s = this.players[i].seek();

      this.seek[i] = s;

      //console.log("seek", s);
    }

    this.setState({ update: true });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.timer) clearTimeout(this.timer);

    for (let i in this.players) {
      this.players[i].stop();
    }
  }

  playPressed(i) {
    console.log("play", i);

    if (!this.players[i].playing()) {
      console.log("start playing", i);
      this.players[i].play();
    }

    for (let j in this.players) {
      if (i === j) continue;
      this.players[j].stop();
    }

  }

  pausePressed(i) {
    this.players[i].pause();
  }

  stopPressed(i) {
    this.players[i].stop();
  }


  audioFinished(i) {
    let files = this.props.params.audioFiles;

    this.props.store_cb({ audioFinished: files[i][0] });
    this.finished[i] = true;

    let all = true;
    for (let j in this.finished) {
      if (this.finished[j] === false) all = false;
    }

    if (all) this.props.store_cb({ allFinished: true });

  }

  finish() {
    this.props.finished_cb();
  }

  volumeChange(e, newValue) {
    for(let p of this.players) p.volume(newValue/100);
    this.setState({volume: newValue});
  }

  render() {

    // if (this.state.audioEnded) {
    //   return (
    //     <div>
    //       <br></br>
    //     <Box>
    //       <Button sx={{m:5}} variant='contained' color='primary' onClick={this.storeAndFinish.bind(this)}>Save</Button>
    //       <Button sx={{m:5}} variant='contained' color='secondary' onClick={this.finish.bind(this)}>Do not save</Button>
    //     </Box>
    //     <br></br>

    //     <CircularProgress variant="determinate" value={this.state.pressTimer} />

    //     </div>
    //   );
    // }

    //let src = this.props.params.params.audio;
    let label = this.props.params.label;


    // let text = this.props.params.text;
    let text = this.props.instructions;

    console.log("INST: ",text);


    let files = this.props.params.audioFiles;
    let cards = [];

    for (let i in files) {

      let playColor = "disabled";
      let pauseColor = "disabled";
      let stopColor = "disabled";

      if (this.players[i].playing()) {
        pauseColor = "inherit";
      } else playColor = "inherit";

      if (this.players[i].seek() > 0) stopColor = "inherit";


      let dur = this.players[i].duration();
      let min = Math.floor(dur / 60);
      let sec = Math.floor(dur - min * 60);
      if (sec < 10) sec = "0" + sec;

      //console.log("DUR", dur, min, sec);

      let remdur = this.players[i].seek();
      let remmin = Math.floor(remdur / 60);
      let remsec = Math.floor(remdur - remmin * 60);
      if (remsec < 10) remsec = "0" + remsec;

      let fname = files[i][1];
      let audioLabel = fname;
      let timeLabel = "(" + remmin + ":" + remsec + " / " + min + ":" + sec + ")";

      let progress = 100 * this.seek[i] / dur;


      cards.push(
        <Card key={fname} sx={{ width: 350, m: 2 }} elevation={2}>
          <Box sx={{ display: 'flex', width: '=100%', flexDirection: 'column', alignItems: 'center', flexGrow: 1 }}>

            {/* <CardContent  > */}

            <Box sx={{ display: 'flex', flexDirection: 'row', flexGrow: 1, alignItems: 'center', }}>
              <Typography variant="h6" sx={{ p: 0, flexGrow: 1 }}>
                {audioLabel}
              </Typography>
              {this.finished[i] && <CheckCircleIcon color='success' />}
            </Box>
            {/* </CardContent> */}

            <Typography variant="body2" sx={{ p: 1, flexGrow: 1 }}>
                {timeLabel}
              </Typography>

            <Box sx={{ display: 'flex' }}>
            </Box>

            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
              {/* <IconButton aria-label="previous">
                <SkipNextIcon />
              </IconButton> */}
              <IconButton onClick={this.playPressed.bind(this, i)}>
                <PlayArrowIcon color={playColor} sx={{ height: 38, width: 38 }} />
              </IconButton>
              <IconButton aria-label="play/pause" onClick={this.pausePressed.bind(this, i)}>
                <PauseIcon color={pauseColor} sx={{ height: 38, width: 38 }} />
              </IconButton>
              <IconButton aria-label="play/pause" onClick={this.stopPressed.bind(this, i)}>
                <StopIcon color={stopColor} sx={{ height: 38, width: 38 }} />
              </IconButton>


              {/* <IconButton aria-label="next">
                <SkipPreviousIcon />
              </IconButton> */}
            </Box>

          </Box>
          {/* <CardMedia
            component="img"
            sx={{ width: 151 }}
            image="/static/images/cards/live-from-space.jpg"
          /> */}

        </Card>
      );

      // players.push(
      //   <ReactHowler
      //     src={src}
      //     playing={false}
      //     preload={false}
      //     // key={this.props.params.audio}
      //     ref={(ref) => (this.players[i]=ref)}
      //     //onLoad={this.onLoad.bind(this)}
      //     onend={this.finished_cb}
      //   />
      // );
    }

    return (
      <Box sx={{ m: 'auto', p:1, maxWidth: "800px" }}>
        <br></br>

        <Typography variant="h4" color="text.primary">
          {label}
        </Typography>

        {this.state.msg}


        {text}


        <Box sx={{ display: 'flex', flexDirection: 'column', p: 'auto', alignItems: 'center' }}>
          {cards}

          <Stack spacing={2} direction="row" sx={{ mb: 1, width: 200 }} alignItems="center">
            <VolumeDown />
            <Slider aria-label="Volume" size="small" value={this.state.volume} onChange={this.volumeChange.bind(this)} />
            <VolumeUp />
          </Stack>

        </Box>



        <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finish.bind(this)}>Back to exercises</Button>

      </Box>
    );

  };
}

export default AudioModule;