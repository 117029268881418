import React, { useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { Link as RouterLink, MemoryRouter } from 'react-router-dom';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { MyContext } from './MyContext';
import { HorizontalRule, HorizontalSplit, PropaneSharp } from '@mui/icons-material';
import { Divider } from '@mui/material';
import BatteryGauge from 'react-battery-gauge';

// Helper function to calculate battery percentage from voltage.
function calculateBatteryPercentage(voltage) {
    const minV = 3.3; // voltage when battery is empty
    const maxV = 4.1; // voltage when battery is full
    let percentage = ((voltage - minV) / (maxV - minV)) * 100;
    if (percentage > 100) percentage = 100;
    if (percentage < 0) percentage = 0;
    return Math.round(percentage);
}

export default function NavigateBar({ user, isRoot, hasPupils, isLoggedIn, onLogoutClicked, onLoginClicked, onDisconnectClicked, onConnectClicked }) {
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();


    let myContext = useContext(MyContext);
    if (!myContext) {
        myContext = { mentorView: false };
    }

    let manageCourses = false;
    if (myContext.userRecord) manageCourses = myContext.userRecord.manageCourses;

    // let mentorViewStr = "Mentor view";
    let mentorViewStr = myContext.mentorView ? "User view" : "Mentor view";


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        console.log(e);
        setAnchorEl(null);
    };

    const handleRegister = () => {
        setAnchorEl(null);
        myContext.setPage("register");

        // navigate("/register");

    }

    const handleManage = () => {
        setAnchorEl(null);
        myContext.setPage("manage");
        // navigate("/manage");

    }

    const handleForceReload = () => {
        setAnchorEl(null);
        myContext.setPage("force_reload");
        // navigate("/force_reload");

    }

    // const handleManageCourses = () => {
    //     setAnchorEl(null);
    //     navigate("/manage_courses");
    // }

    const handleMentorView = () => {
        setAnchorEl(null);
        myContext.setMentorView(!myContext.mentorView);
        myContext.setPage("home");
        // navigate("/");
    }


    const handleHome = () => {
        console.log("handleHome");
        myContext.setPage("home");
        myContext.setExercise(null, null);
        setAnchorEl(null);
        // navigate('/');

    }

    const handlePrivacy = () => {
        setAnchorEl(null);
        // navigate('/privacy');
        myContext.setPage("privacy");


    }

    const handleResults = () => {
        setAnchorEl(null);
        // navigate('/inspectuser');
        myContext.setPage("inspectuser");

    }

    // const handlePupils = () => {
    //     setAnchorEl(null);
    //     // navigate('/inspectpupil');
    //     myContext.setPage("inspectpupil");

    // }

    const handleGroupExercise = () => {
        setAnchorEl(null);
        myContext.setPage("groupexercise");
        // navigate('/');
    }


    // const handleInspectGroupSessions = () => {
    //     setAnchorEl(null);
    //     // navigate('/inspectgroupsessions');
    //     myContext.setPage("inspectgroupsessions");

    // }

    // const handleFractal = () => {
    //     setAnchorEl(null);
    //     navigate('/fractal');
    // }

    // const handleScenery = () => {
    //     setAnchorEl(null);
    //     navigate('/scenery');
    // }

    const handleOta = () => {
        setAnchorEl(null);
        // navigate('/ota');
        myContext.setPage("ota");

    }

    const handleCreateRegistrationCode = () => {
        setAnchorEl(null);
        myContext.setPage("create-registration-code"); // Assuming your context supports this page
        // Alternatively, use navigate for a direct route:
        // navigate("/create-registration-code");
    };

    // New: handleConnect 
    const handleConnect = () => {
        setAnchorEl(null);
        if (onConnectClicked) {
            onConnectClicked();
        }
    };

    const handleDisconnect = () => {
        setAnchorEl(null);
        onDisconnectClicked();
        // navigate('/');
    }

    // const onHomeClicked = () => {
    //     setAnchorEl(null);
    //     navigate('/');
    // }

    const onLoginClicked2 = () => {
        setAnchorEl(null);
        // navigate('/');
        onLoginClicked();
    }


    var loginButton = null;
    if (isLoggedIn) {
        loginButton = (
            <Button color="inherit" key="logout" onClick={onLogoutClicked}>
                Logout
            </Button>);
    } else {
        loginButton = (
            <Button color="inherit" key="login" onClick={onLoginClicked2}>
                Login
            </Button>);
    }

    let nickname = false;
    let avatar = false;
    if (user) avatar = user.substring(0, 2).toUpperCase();
    if (myContext.userRecord && myContext.userRecord.nickname) avatar = myContext.userRecord.nickname.substring(0, 2).toUpperCase();

    let tooltip = "";
    let deviceName = false;

    if (myContext) {
        deviceName = myContext.deviceName;
        tooltip = "Battery: " + myContext.bat + "V" + " Firmware: " + myContext.firmware;
    }

    let batteryPercentage = myContext && myContext.bat ? calculateBatteryPercentage(myContext.bat) : null;

    return (
        <Box sx={{ flexGrow: 1 }} >

            <AppBar position="static">
                <Toolbar sx={{ position: 'relative' }}>
                    {/* Left group */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {isLoggedIn && [
                            <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 0 }}
                                onClick={handleMenu}>
                                <MenuIcon />
                            </IconButton>,


                            <Button color="inherit" onClick={handleHome}>
                                HOME
                            </Button>
                        ]}
                    </Box>

                    {/* Center "Obimon" text */}
                    <Typography variant="h6" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                        {/* <Typography variant="h6" sx={{alignSelf: "center"}}> */}
                        {/* Add link to Obimon.com */}
                        <Link href="https://obimon.com" underline="none" color="inherit">
                            Obimon
                        </Link>
                    </Typography>

                    <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>

                        {isLoggedIn && deviceName && (
                            <Tooltip
                                title={`Device: ${deviceName} | Battery: ${batteryPercentage}% (${myContext.bat}V) | Firmware: ${myContext.firmware}`}
                                arrow
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center', mr: 0 }}>
                                    <Typography variant="body2">{deviceName}</Typography>
                                    {myContext.bat && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                            <BatteryGauge value={batteryPercentage} size={40} />
                                        </Box>
                                    )}
                                </Box>
                            </Tooltip>

                        )}

                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                        >
                            {isLoggedIn && <Avatar>{avatar}</Avatar>}

                        </IconButton>
                    </Box>

                    {/* {isLoggedIn &&
                        loginButton
                    } */}

                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {/* <MenuItem onClick={handleHome}>Home</MenuItem> */}
                        <MenuItem onClick={handleHome}>Home</MenuItem>
                        {isLoggedIn &&
                            <MenuItem onClick={onLogoutClicked}>Logout</MenuItem>
                        }
                        <MenuItem onClick={handlePrivacy}>Privacy policy</MenuItem>
                        {deviceName && <MenuItem onClick={handleDisconnect}>Disconnect Obi</MenuItem>}
                        {!deviceName && <MenuItem onClick={handleConnect}>Connect Obi</MenuItem>}

                        {/* {(!myContext.mentorView) && (
                            <>
                                <Divider />
                                <MenuItem onClick={handleGroupExercise}>Join group exercise</MenuItem>
                            </>
                        )} */}

                        {(!myContext.mentorView) && [
                            <Divider key="divider-group-exercise" />,
                            <MenuItem key="group-exercise" onClick={handleGroupExercise}>
                                Join group exercise
                            </MenuItem>,
                        ]}

                        {(isRoot || myContext.mentorView) && [
                            <MenuItem key="create-mentee" onClick={() => {
                                setAnchorEl(null);
                                myContext.setPage("create-mentee");
                            }}>
                                Create New Mentee
                            </MenuItem>,
                            <MenuItem onClick={() => {
                                setAnchorEl(null);
                                myContext.setPage("mentor-dashboard");
                                // navigate("/mentor-dashboard"); 
                            }}>
                                My Mentees
                            </MenuItem>
                        ]}



                        {(isRoot || manageCourses) && [
                            <Divider key="divider-mentor" />,
                            <MenuItem key="mentor-view" onClick={handleMentorView}>
                                {mentorViewStr}
                            </MenuItem>,
                        ]}

                        {/* {hasPupils && <MenuItem onClick={handlePupils}>Inspect pupil records</MenuItem>} */}
                        {/* {manageCourses && <MenuItem onClick={handleInspectGroupSessions}>Inspect group records</MenuItem>} */}
                        {/* {isRoot && <MenuItem onClick={handleFractal}>Fractal</MenuItem>} */}
                        {/* {isRoot && <MenuItem onClick={handleScenery}>Scenery</MenuItem>} */}


                        {isRoot && [
                            <Divider key="divider-root" />,
                            <MenuItem key="register" onClick={handleRegister}>Register new user</MenuItem>,
                            <MenuItem key="manage" onClick={handleManage}>Manage user</MenuItem>,
                            <MenuItem key="results" onClick={handleResults}>Inspect user records</MenuItem>,
                            <MenuItem key="ota" onClick={handleOta}>Device upgrade</MenuItem>,
                            <MenuItem key="force-reload" onClick={handleForceReload}>Force reload all clients</MenuItem>,
                            <MenuItem key="create-registration-code" onClick={handleCreateRegistrationCode}>Create Registration Code</MenuItem>,
                        ]}

                    </Menu>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
