// import { AddReactionRounded } from "@mui/icons-material";

// import exercise_data from './exercise_data.js';
import campaign_data from './campaign_data';

export class CalcSessionStats {

    constructor() {
        this.t0 = 0;
        this.decr = false;
        this.sustain = false;
        this.t_decr = false;
        this.relaxpts = 0;

        this.last_sl = 0;
        this.last_s = 0;

        this.avgsust = 0;
        this.avgsust_n = 0;

        // this.exercise_data = exercise_data();
        this.campdata = campaign_data();
    }

    calc_one(ts, sl, sr) {

        if (this.t0 === 0) this.t0 = ts;

        let s = Math.max(sl, sr);

        //if(sl<this.last_sl || sl===0) this.relaxpts += 0.2;
        //this.last_sl = sl;

        if(s<=this.last_s || s===0) this.relaxpts += 0.1;
        this.last_s = s;

        //console.log(this.relaxpts);

        //console.log(s);

        if (s <= 30 && (!this.decr)) {

            let phase1 = (ts - this.t0) / 1000.0;  // in seconds
            let rate = Math.floor(2500 / phase1);

            this.t_decr = ts - this.t0;

            this.decr = rate;
        }

        if (this.decr) {
            //score = 70;

            let y = 100 - s;
            if (y < 0) y = 0;

            this.avgsust += y;
            this.avgsust_n += 1;
            //let ss = Math.floor(this.avgsust / this.avgsust_n);
            let ss = Math.floor(this.avgsust/1000);


            //console.log("SUSTAIN", ss, y);


            this.sustain = ss;


        }
    }

    calc_stats(ses) {

        if(!ses.data.scores) return {};

        let scores = ses.data.scores;

        for (var i = 0; i < scores.length; i++) {
            let [ts, sl, sr] = scores[i];
            this.calc_one(ts, sl, sr);

        }

        // console.log("EXERCISE", ses.exercise);

        if(! ses.campaign ) return {};
        
        let data = this.campdata[ses.campaign].exercises[ses.exercise];

        let multiplier = 0;

        if(data === undefined) {
            console.error("WARNING exercise not found:", ses.exercise, "user:", ses.user);
            return {};
        }

        // console.log("EXERCISE DATA", data);

        if(data["params"] === undefined) {
            console.error("ERROR EXERCISE PARAMS", data);
        }
        // console.log("EXERCISE PARAMS", data.params);

        multiplier = data.params.point_mult;

        this.relaxpts = Math.floor(this.relaxpts);

        if(ses.type === "WaveBasic") return {
            relaxpts: Math.floor(this.relaxpts), 
        };

        if(ses.data.diffculty_params && ses.data.diffculty_params.nWave == 1) return {
            relaxpts: Math.floor(this.relaxpts), 
        };

        //multiplier = 1;

        let ret = {
            decr: Math.floor(this.decr*multiplier), 
            sustain: Math.floor(this.sustain*multiplier), 
            relaxpts: Math.floor(this.relaxpts*multiplier)
        };
        //console.log("CalcSessionStats", ret, "mult:", multiplier, this.decr);

        return(ret);

    }


}