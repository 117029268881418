import React, { Component } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

import ShutterSpeedIcon from '@mui/icons-material/ShutterSpeed';
import ParkIcon from '@mui/icons-material/Park';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import BalanceIcon from '@mui/icons-material/Balance';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import { Typography } from '@mui/material';

class ScoreBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let d = this.props.data;
    if (this.props.view === "detailed") {
      return (
        // Detailed view: icons one below the other, label above each icon with number beside it.
        <Stack spacing={2} direction="column">
          {d.difficulty > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" style={{ width: 80 }}>Difficulty</Typography>
              <SelfImprovementIcon />
              <Typography style={{ marginLeft: 8 }}>{d.difficulty}</Typography>
            </div>
          ) : null}

          {d.relaxpts ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" style={{ width: 80 }}>Relax</Typography>
              <LocalFloristIcon />
              <Typography style={{ marginLeft: 8 }}>{d.relaxpts}</Typography>
            </div>
          ) : null}
          {d.decr ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" style={{ width: 80 }}>Control</Typography>
              <ShutterSpeedIcon />
              <Typography style={{ marginLeft: 8 }}>{d.decr}</Typography>
            </div>
          ) : null}
          {d.sustain ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" style={{ width: 80 }}>Balance</Typography>
              <BalanceIcon />
              <Typography style={{ marginLeft: 8 }}>{d.sustain}</Typography>
            </div>
          ) : null}
          {d.tree > 0 ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption" style={{ width: 80 }}>Forester</Typography>
              <ParkIcon />
              <Typography style={{ marginLeft: 8 }}>{d.tree}</Typography>
            </div>
          ) : null}
        </Stack>
      );
    }

    return (
      <div>
        <Stack justifyContent="center" spacing={2} direction="row">
          {d.difficulty > 0 && (
            <div>
              <Tooltip title="Adaptive difficulty">
                <SelfImprovementIcon />
              </Tooltip>
              <Typography>{d.difficulty}</Typography>
            </div>
          )}
          <div>
            <Tooltip title="Relax points earned">
              <LocalFloristIcon />
            </Tooltip>
            <Typography>{d.relaxpts}</Typography>
          </div>
          <div>
            <Tooltip title="Control points earned">
              <ShutterSpeedIcon />
            </Tooltip>
            <Typography>{d.decr}</Typography>
          </div>
          <div>
            <Tooltip title="Balance points earned">
              <BalanceIcon />
            </Tooltip>
            <Typography>{d.sustain}</Typography>
          </div>
          {d.tree > 0 && (
            <div>
              <Tooltip title="Gardener points earned">
                <ParkIcon />
              </Tooltip>
              <Typography>{d.tree}</Typography>
            </div>
          )}
        </Stack>
      </div>
    );
  }
}

export default ScoreBox;
