// CreateMentee.jsx
import React, { useState, useContext, useEffect } from 'react';
import { MyContext } from './MyContext';
import { Button, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
// Import the campaign data (assuming it exports a function returning an object)
import campaign_data from './scripts/campaign_data';

const CreateMentee = () => {
  const { setPage } = useContext(MyContext);
  const [nickname, setNickname] = useState('');
  const [campaign, setCampaign] = useState('');
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    // Assuming campaign_data is a function that returns an object of campaigns
    const campData = campaign_data();
    // Transform the object into an array with id and label (using label property if available)
    const campArray = Object.keys(campData).map(key => ({
      id: key,
      label: campData[key].label || key
    }));
    setCampaigns(campArray);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('/api/create-mentee', {
      method: 'POST',
      body: JSON.stringify({ nickname, campaign }),
      headers: { 'Content-Type': 'application/json' }
    });
    if (response.ok) {
      const data = await response.json();
      alert(`Mentee created successfully!\nLogin: ${data.login}\nDisplay Name: ${data.nickname}`);
      setPage("home"); // Return to the home page or another appropriate page
    } else {
      alert("Error creating mentee");
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ margin: '20px' }}>
      <TextField
        label="Display Name"
        value={nickname}
        onChange={(e) => setNickname(e.target.value)}
        required
        fullWidth
        margin="normal"
      />
      <FormControl fullWidth margin="normal" required>
        <InputLabel>Campaign</InputLabel>
        <Select
          value={campaign}
          onChange={(e) => setCampaign(e.target.value)}
        >
          {campaigns.map(c => (
            <MenuItem key={c.id} value={c.id}>{c.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button variant="contained" type="submit" color="primary">
        Create Mentee
      </Button>
    </form>
  );
};

export default CreateMentee;
