import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
import features from '../scripts/features';
import campaign_data from './campaign_data';
import { calcDifficultyFromParams } from './calc_difficulty'; // <-- new import

function isValidEdaSession(s) {
    if (!s.data) return false;
    if (!s.data.eda) return false;
    if (s.data.eda.length < 10) return false;

    if (!s.data.scores) return false;
    if (s.data.scores.length < 10) return false;

    // let dur = s.data.eda[s.data.eda.length-1][0] - s.data.eda[0][0];
    //if(dur < 60*1000) return false;

    return true;
}

function getDuration(s) {
    if (!isValidEdaSession(s)) return 0;

    let dur = s.data.eda[s.data.eda.length - 1][0] - s.data.eda[0][0];
    return dur;
}

function isValidPractice(s, ex) {
    console.log("QQQ isValidPractice", s, ex);

    if (s.data.success) return true;

    let dur = getDuration(s);

    if (ex.params) {
        if (ex.params.duration)
            if (dur > ex.params.duration - 5000) {
                console.log("QQQ isValidPractice reached the end TRUE", dur, ex.params.duration);
                return true;
            }
            else if (dur > 60 * 1000) {
                console.log("QQQ isValidPractice long enouogh TRUE", dur, ex.params.duration);
                return true;
            }
    }

    return false;
}

function getStat(ses, levelType) {
    console.log("QQQ getStat levelType", levelType);

    let sessions = 0;
    let relax = 0;
    let decr = 0;
    let sustain = 0;
    let trees = 0;
    let flowers = 0;
    let practices = 0;

    let levelsessions = [];
    let levelSucc = [];

    let campdata = campaign_data();

    for (let s of ses) {

        if (!s.campaign) continue;
        let camp = campdata[s.campaign];
        if (camp.levelType != levelType) continue;

        let ex = camp.exercises[s.exercise];

        if (ex === undefined) {
            console.log("QQQ EX UNDEFINED", s.exercise);
            continue;
        }

        console.log("QQQ", ex.level, camp.label, ex);

        let slevel = ex.level;

        if (!levelsessions[slevel]) {

            levelsessions[slevel] = 0;
        }
        levelsessions[slevel] += 1;

        if (!levelSucc[slevel]) levelSucc[slevel] = 0;

        if (ex.type === "VideoTutorial") {
            levelSucc[slevel] += 1;
        }

        if (!isValidEdaSession(s)) continue;

        if (s.data.success) levelSucc[slevel] += 1;

        //console.log(s);

        let calcSessionStats = new CalcSessionStats();
        let stats = calcSessionStats.calc_stats(s);

        //console.log(stats);

        if (stats.relaxpts) relax += stats.relaxpts;
        if (stats.decr) decr += stats.decr;
        if (stats.sustain) sustain += stats.sustain;

        if (isValidPractice(s, ex)) {
            practices += 1;
        }

        sessions += 1;

        if (s.data.creature) {
            if (s.data.creature.type === "tree") {
                if (s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

            if (s.data.creature.type === "flower") {
                if (s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

        }
    }

    console.log("QQQ", sessions, relax, decr, sustain, trees, levelsessions, levelSucc);

    return {
        sessions: sessions,
        relax: relax,
        decr: decr,
        sustain: sustain,
        trees: trees,
        levelsessions: levelsessions,
        levelSucc: levelSucc,
        practices: practices
    }
}

export function calcLevelGeneric(sessions, campName, difficulty) {

    console.log("QQQ camp", campName, difficulty);

    let campdata = campaign_data();
    let camp = campdata[campName];
    let exercises = camp.exercises;

    console.log("QQQ exercises", exercises);


    let levelType = camp.levelType;

    // console.log("CALC_LEVEL_GENERIC camp", camp);
    // console.log("CALC_LEVEL_GENERIC ex", exercises);

    let level = 1;
    let res, info;

    let s = getStat(sessions, levelType);
    // console.log("CALC_LEVEL_GENERIC levelstat", s);

    let text = false;

    let delta = {}

    let currentLevel = 0;

    let ptext = [];

    let current_levelSessions = 0;
    let current_levelSucc = 0;


    for (let exname in exercises) {
        let ex = exercises[exname];

        console.log("QQQ ======================= ", currentLevel, ex.level, ex);

        // check if ex contains 'level'
        if (!ex.level) continue;
        if (!ex.criteria) {
            currentLevel = ex.level;
            console.log("QQQ", currentLevel, "no criteria");
            continue;
        }

        let criteria = ex.criteria;

        console.log("QQQ LEVEL CRITERIA", ex.level, criteria);

        let l = criteria;

        console.log("QQQ stats", s);

        delta = {};

        text = false;
        if (l.sessions && l.sessions > s.sessions) {
            delta.sessions = l.sessions - s.sessions;
            text = `to do ${l.sessions - s.sessions} more exerise(s)`;
        }

        if (!s.levelsessions[currentLevel]) s.levelsessions[currentLevel] = 0;
        if (!s.levelSucc[currentLevel]) s.levelSucc[currentLevel] = 0;

        let current_levelSessions = s.levelsessions[currentLevel];
        let current_levelSucc = s.levelSucc[currentLevel];

        console.log("QQQ ZZZ", currentLevel, current_levelSessions, current_levelSucc);

        if (l.n_maxlevel && l.n_maxlevel > current_levelSessions) {
            delta.n_maxlevel = l.n_maxlevel - current_levelSessions;
            text = `to do ${delta.n_maxlevel} more exercise(s) on this level`;
        }

        if (l.levelSucc && l.levelSucc > current_levelSucc) {
            delta.levelSucc = l.levelSucc - current_levelSucc;

            if (delta.levelSucc == 1) text = `to complete this step`;
            else text = `to do ${delta.levelSucc} more successful exercises on this level`;
        }

        if (l.relax && l.relax > s.relax) {
            delta.relax = l.relax - s.relax;
            ptext.push(`${delta.relax} more relax points`);
        }


        if (l.decr && l.decr > s.decr) {
            delta.decr = l.decr - s.decr;
            ptext.push(`${delta.decr} more control points`);
        }

        if (l.sustain && l.sustain > s.sustain) {
            delta.sustain = l.sustain - s.sustain;
            ptext.push(`${delta.sustain} more balance points`);
        }

        if (l.trees && l.trees > s.trees) {
            delta.trees = l.trees - s.trees;
            ptext.push(`${delta.trees} more flower(s) / tree(s)`);
        }

        if (l.sum_practice && l.sum_practice > s.practices) {
            delta.practices = l.sum_practice - s.practices;
            ptext.push(`${delta.practices} more practice(s)`);
        }

        if (text || ptext.length > 0) break;

        console.log("QQQ Criteria met set level to ", ex.level);
        currentLevel = ex.level;

    }


    console.log("QQQ", text, ptext);

    if (text) {
        info = "To progress further you need " + text;
    }

    if (ptext.length > 0) {
        console.log("QQQ PTEXT", currentLevel, "ptext", ptext);
        if (text) info += ", and also "
        else info = "To progress further you need ";

        info += " " + ptext.join(", ");
    }

    if (!info) {
        info = "You have reached the final step!";
        //currentLevel+=1;
    }
    console.log("QQQ l", currentLevel, info, delta, s);

    // New logic: compare current 'difficulty' parameter with last session's calculated difficulty
    console.log("QQQ: calcLevelGeneric - scanning sessions for last difficulty value");
    let lastDiff = null;
    let lastSessionLevel = null;
    for (let i = sessions.length - 1; i >= 0; i--) {
        let sess = sessions[i];
        // require valid difficulty parameters and matching campaign exercise
        if (sess.data && sess.data.difficulty_params) {
            const [nWave, diffValue] = calcDifficultyFromParams(sess.data.difficulty_params);
            console.log("QQQ: Found session with difficulty:", diffValue);
            lastDiff = diffValue;
            let sessEx = camp.exercises[sess.exercise];
            if (sessEx && sessEx.level) {
                lastSessionLevel = sessEx.level;
                console.log("QQQ: Last session level detected:", lastSessionLevel, sessEx);
            }
            break;
        }
    }

    let extraMsg = "";
    let easyLevel = false;

    console.log("QQQ: calcLevelGeneric - lastDiff:", lastDiff, "current difficulty:", difficulty);
    if (lastDiff !== null) {
        const diffDifference = difficulty - lastDiff;
        console.log("QQQ: calcLevelGeneric - diffDifference:", diffDifference);
        if (Math.abs(diffDifference) >= 1) {  // significant difference threshold
            // Existing case for decreased difficulty:
            if (diffDifference < 0) {
                if (lastSessionLevel === currentLevel) {
                    extraMsg = " Note: the decrease in difficulty from your last session on this level is normal since it reflects the adjustment to a new exercise.";
                    console.log("QQQ: Normal decrease on same level.");
                } else if (lastSessionLevel !== null && lastSessionLevel < currentLevel && currentLevel > 16) {
                    extraMsg = " Note: consider trying a relaxation exercise from earlier in the course.";
                    console.log("QQQ: Decrease from an earlier level detected; potential stress indicator.");
                    // New code: select an early next_exercise randomly

                    easyLevel = true;
                    console.log("QQQ: Selecting early relaxation exercise from level:", Math.floor(currentLevel / 2));
                }
            }
            // New conditions for improved performance
            else if (diffDifference > 1) {
                if (lastSessionLevel === currentLevel) {
                    extraMsg = "Note: You crushed it even on the most advanced level!";
                    console.log("QQQ: Excellent improvement on current level.");
                } else if (lastSessionLevel !== null && lastSessionLevel < currentLevel) {
                    extraMsg = "Note: your last practice paid off!";
                    console.log("QQQ: Excellent improvement from an earlier level.");
                }
            }
            if (extraMsg) {
                console.log("QQQ: Extra message:", extraMsg);
            }
        }
    }

    let next_exercise = false;

    if (easyLevel) {
        let earlyLevel = Math.max(0, Math.floor(currentLevel / 2));
        next_exercise = getEasyRandomExercise(campName, earlyLevel);
    } else {
        // case when there are no sessions on the level
        if (s.levelsessions[currentLevel] === 0) {
            console.log("QQQ", currentLevel, "no sessions on level, offer the last one", s.levelsessions[currentLevel], current_levelSessions);
            extraMsg += " This is your first session on this level.";
            next_exercise = getExerciseFromLevel(campName, currentLevel);
        } else if (s.levelSucc[currentLevel] === 0) {
            console.log("QQQ", currentLevel, "no successful sessions on level, offer a random", currentLevel, delta);
            next_exercise = getRandomExercise(campName, currentLevel, delta);
        } else {
            console.log("QQQ", currentLevel, "offer random sessions from earlier");
            next_exercise = getRandomExercise(campName, currentLevel, delta);
        }
    }

    // features
    let earnedfeatures = [];
    let feat = features();
    // console.log(s);
    for (let f of feat.fg) {
        //console.log(f);

        let pass = true;
        if (f.sessions && s.sessions < f.sessions) pass = false;
        if (f.decr && s.decr < f.decr) pass = false;
        if (f.relax && s.relax < f.relax) pass = false;
        if (f.sustain && s.sustain < f.sustain) pass = false;
        if (f.trees && s.trees < f.trees) pass = false;

        if (pass) {
            earnedfeatures.push(f.id);
        }



    }
    console.log("QQQ NEXT_EXERCISE", currentLevel, info, extraMsg, earnedfeatures, next_exercise);


    // OVERRRIDE!!!
    // currentLevel = 100;
    // info = "";

    return [currentLevel, info, extraMsg, earnedfeatures, next_exercise];

}

function getExerciseFromLevel(campName, level) {
    let campdata = campaign_data();
    let camp = campdata[campName];
    let exercises = camp.exercises;

    for (let e in exercises) {
        if (exercises[e].level == level) {
            console.log("QQQ LEVEL", level, "camp", campName, "exercise", e);
            return [campName, e];
        }
    }

    console.log("QQQ LEVEL", level, "exercise not found");
    return false;
}

function getEasyRandomExercise(campName, level) {
    let campdata = campaign_data();
    let camp = campdata[campName];
    let exercises = camp.exercises;

    let ex = [];
    for (let e in exercises) {
        if (exercises[e].level <= level && exercises[e].type !== "VideoTutorial") {
            ex.push([campName, e]);
        }
    }

    let selected = ex[ex.length - 1];

    if (Math.random() > 0.5 && ex.length > 1) {
        let id = Math.floor(Math.random() * (ex.length - 1))
        selected = ex[id];
    }

    return selected;
}

function getRandomExercise(campName, level, delta) {
    let campdata = campaign_data();
    let camp = campdata[campName];
    let exercises = camp.exercises;

    console.log("QQQ getRandomExercise", level, "delta", delta, "camp", campName);

    let ex = [];
    for (let e in exercises) {
        if (exercises[e].level <= level && exercises[e].type !== "VideoTutorial") {
            ex.push([campName, e]);
        }
    }

    console.log("QQQ getRandomExercise", ex, "ex.length", ex.length);

    let selected = ex[ex.length - 1];

    if (Math.random() > 0.5 && ex.length > 1) {
        console.log("QQQ getRandomExercise", level, "random selection");
        let id = Math.floor(Math.random() * (ex.length - 1))

        console.log("QQQ getRandomExercise", level, "id", id);

        selected = ex[id];
    }

    console.log("QQQ getRandomExercise", level, "selected", selected);

    return selected;
}