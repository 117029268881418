
import { CalcSessionStats } from './calc_sessionstats';
import { getWeek } from './getWeek';
import features from '../scripts/features';
import campaign_data from './campaign_data';

function isValidEdaSession(s) {
    if (!s.data) return false;
    if (!s.data.eda) return false;
    if (s.data.eda.length < 10) return false;

    if (!s.data.scores) return false;
    if (s.data.scores.length<10) return false;

    let dur = s.data.eda[s.data.eda.length-1][0] - s.data.eda[0][0];
    //if(dur < 60*1000) return false;

    return true;
}
// Criteria: what to do to reach the next level (finish the current one)
const levels = {

    1: {
        levelsucc: 2,
        relax: 50
    },

    2: {
        levelsucc: 2,
        relax: 80,
    },

    3: {
        levelsucc: 1,
        relax: 120,
    },

    4: {
        levelsucc: 2,
        relax: 150,
    },

    // 5: {
    //     levelsucc: 2,
    //     relax: 180,

    // },

    // 6: {
    //     levelsucc: 2,
    //     relax: 180,
    // },

    // 7: {
    //     levelsucc: 2,
    //     relax: 210,

    // },

    // 8: {
    //     levelsucc: 6,
    //     // relax: 210,
    // },

    // 9: {
    //     decr: 200,
    //     tree: 3,
    //     // relax: 210,
    // },

    // 10: {
    //     tree: 6,
    //     relax: 1000,
    //     decr: 500,
    //     sustain: 500,

    // },

    // 11: {
    //     tree: 6,
    //     // relax: 210,
    // },

    // 80: {
    //     relax: 80,
    //     decr: 150,
    //     sustain: 150,
    //     trees: 3
    // }

};


function getStat(ses) {
    let sessions = 0;
    let relax = 0;
    let decr = 0;
    let sustain = 0;
    let trees = 0;
    let flowers = 0;

    let levelsessions = [];
    let levelsucc = [];

    let campdata = campaign_data();

    for (let s of ses) {

        if(! s.campaign ) continue;
        let camp = campdata[s.campaign];
        if(camp.levelType != 'CBTSTRESS') continue;
            
        let ex = camp.exercises[s.exercise];

        if(ex === undefined) {
            console.log("EX UNDEFINED", s.exercise);
            continue;
        }

        // if (s.type !== 'Waves2Phase' && s.type !== 'WaveBasic' && s.type !== 'Tetris') continue;

        if (!isValidEdaSession(s)) continue;

        
        let slevel = ex.level;

        if(!levelsessions[slevel]) {
            
            levelsessions[slevel] = 0;
            if(slevel==4) console.log("CBT LEVEL", slevel, ex, s);
        }
        levelsessions[slevel] += 1;

        if(!levelsucc[slevel]) levelsucc[slevel] = 0;
        if(s.data.success) levelsucc[slevel] += 1;


        //console.log(s);

        let calcSessionStats = new CalcSessionStats();
        let stats = calcSessionStats.calc_stats(s);

        //console.log(stats);

        if(stats.relaxpts) relax += stats.relaxpts;
        if(stats.decr) decr += stats.decr;
        if(stats.sustain) sustain += stats.sustain;

        sessions += 1;

        if(s.data.creature) {
            if(s.data.creature.type==="tree") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

            if(s.data.creature.type==="flower") {
                if(s.data.creature.data.size >= 50) {
                    trees += 1;
                }
            }

        }
    }

    console.log("CBTSTATS", sessions, relax, decr, sustain, trees, levelsessions, levelsucc);

    return {
        sessions: sessions,
        relax: relax,
        decr: decr,
        sustain: sustain,
        trees: trees,
        levelsessions: levelsessions,
        levelsucc: levelsucc,
    }
}

export function calcLevelShooting(sessions, courseLevel) {
    // console.log("calcLevelZen");

    // console.log("ZZZ", courseLevel);


    let level = 1;
    let res, info;

    let s = getStat(sessions);
    //console.log("levelstat", s);

    let text = false;

    let delta = {}

    let currentLevel = 1;

    for(currentLevel in levels) {
        let l = levels[currentLevel];

        console.log("CBT STRESS LEVEL CRITERIA", currentLevel, l);

        delta={};

        text = false;
        if(l.sessions && l.sessions > s.sessions) {
            delta.sessions = l.sessions - s.sessions;
            text = `${l.sessions - s.sessions} more sessions` ;
        }

        if(!s.levelsessions[currentLevel]) s.levelsessions[currentLevel] = 0;
        if(l.n_maxlevel && l.n_maxlevel > s.levelsessions[currentLevel]) {
            delta.n_maxlevel = l.n_maxlevel - s.levelsessions[currentLevel];
            text = `${delta.n_maxlevel} more sessions on this level` ;
        }

        if(!s.levelsucc[currentLevel]) s.levelsucc[currentLevel] = 0;
        if(l.levelsucc && l.levelsucc > s.levelsucc[currentLevel]) {
            delta.levelsucc = l.levelsucc - s.levelsucc[currentLevel];
            text = `${delta.levelsucc} more successful sessions on this level` ;
        }

        if(l.relax && l.relax > s.relax) {
            delta.relax = l.relax - s.relax;
            if(text) text+=", "; else text="";
            text += `${delta.relax} more relax points` ;
        }

        if(l.decr && l.decr > s.decr) {
            delta.decr = l.decr - s.decr;
            if(text) text+=", "; else text="";
            text += `${delta.decr} more control points` ;
        }

        if(l.sustain && l.sustain > s.sustain) {
            delta.sustain = l.sustain - s.sustain;
            if(text) text+=", "; else text="";
            text += `${delta.sustain} more balance points` ;
        }

        if(l.trees && l.trees > s.trees) {
            delta.trees = l.trees - s.trees;
            if(text) text+=", "; else text="";
            text += `${delta.trees} more flowers / trees` ;
        }

        //console.log("1", text);

        if(text) break;

    }

    if(text) {
        info = "To progress further, you need to get "+text;
    } else {
        info = "You have reached the final level!";
        //currentLevel+=1;
    }

    if(currentLevel>courseLevel) {
        currentLevel = courseLevel;
        info = "You have reached the maximum level set by your trainer."
    }
    // console.log("CBT l", currentLevel, info, delta, s);

    let next_exercise = false;

    // case when there are no sessions on the level
    if(s.levelsessions[currentLevel] === 0) {
        // console.log("CBT STRESS LEVEL", currentLevel, "no sessions on level, offer the last one");
        next_exercise = getExerciseFromLevel(currentLevel);
    } else if(s.levelsucc[currentLevel] === 0) {
        // console.log("CBT STRESS LEVEL", currentLevel, "no successful sessions on level, offer a random", currentLevel, delta);
        next_exercise = getRandomExercise(currentLevel, delta);
    } else {
        // console.log("CBT STRESS LEVEL", currentLevel, "offer random sessions from earlier");
        next_exercise = getRandomExercise(currentLevel, delta);
    }


    // features
    let earnedfeatures = [];
    let feat = features();
    // console.log(s);
    for(let f of feat.fg) {
        //console.log(f);

        let pass = true;
        if(f.sessions && s.sessions < f.sessions) pass = false;
        if(f.decr && s.decr < f.decr) pass = false;
        if(f.relax && s.relax < f.relax) pass = false;
        if(f.sustain && s.sustain < f.sustain) pass = false;
        if(f.trees && s.trees < f.trees) pass = false;

        if(pass) {
            earnedfeatures.push(f.id);
        }



    }
    // console.log("ZEN earnedfeatures", earnedfeatures);


    // OVERRRIDE!!!
    currentLevel = 100;
    info = "";

    return [currentLevel, info, earnedfeatures, next_exercise];

}

function getExerciseFromLevel(level) {
    let campdata = campaign_data();

    let camp = 'cbt_stress'

    let ex = campdata[camp].exercises;
    for(let e in ex) {
        if(ex[e].level == level) {
            console.log("CBT STRESS LEVEL", level, "camp", camp, "exercise", e);
            return [camp, e];
        }
    }

    console.log("CBT STRESS LEVEL", level, "exercise not found");
    return false;
}

function getRandomExercise(level, delta) {
    let campdata = campaign_data();

    let camp = 'cbt_stress'

    console.log("CBT STRESS LEVEL", level, "delta", delta, "camp", camp, campdata[camp].exercises);

    let ex = [];
    for(let e in campdata[camp].exercises) {
        if(campdata.cbt_stress.exercises[e].level <= level) {
            ex.push([camp, e]);
        }
    }

    console.log("CBT STRESS LEVEL", level, "random exercise", ex);
    return ex[Math.floor(Math.random() * ex.length)];
}