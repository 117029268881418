import React from 'react';

function CourseCardCalendar({
  exerciseDates = [],           // array of date strings e.g. ["2025-03-05", "2025-03-12"]
  containerWidth = 140,         // overall SVG width before scaling
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1, // 1-indexed month (e.g., 1 for January)
  onDateClick, // new prop for handling date click events
  onMonthChange, // new prop for changing the month
  startWeek = "M" // new prop: "M" for Monday (default) or "S" for Sunday
}) {
  // New: Local state for year and month when onMonthChange is not provided
  const [localYear, setLocalYear] = React.useState(year);
  const [localMonth, setLocalMonth] = React.useState(month);
  
  const effectiveYear = onMonthChange ? year : localYear;
  const effectiveMonth = onMonthChange ? month : localMonth;
  
  // New: Handle local month change when not controlled
  const handleLocalMonthChange = (direction) => {
    let newYear = effectiveYear;
    let newMonth = effectiveMonth;
    if (direction === 'prev') {
      if (newMonth === 1) {
        newMonth = 12;
        newYear -= 1;
      } else {
        newMonth -= 1;
      }
    } else if (direction === 'next') {
      if (newMonth === 12) {
        newMonth = 1;
        newYear += 1;
      } else {
        newMonth += 1;
      }
    }
    setLocalYear(newYear);
    setLocalMonth(newMonth);
  };

  // Utility: format a date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // months are 0-indexed
    let dd = date.getDate();
    mm = mm < 10 ? '0' + mm : mm;
    dd = dd < 10 ? '0' + dd : dd;
    return `${yyyy}-${mm}-${dd}`;
  };

  // Compute previous and next month details.
  const prevMonthDate = new Date(effectiveYear, effectiveMonth - 2, 1); // previous month (month is 1-indexed)
  const nextMonthDate = new Date(effectiveYear, effectiveMonth, 1);       // next month

  const daysInCurrentMonth = new Date(effectiveYear, effectiveMonth, 0).getDate();
  const firstDayOfMonth = new Date(effectiveYear, effectiveMonth - 1, 1);
  // Compute adjusted start day based on the startWeek option:
  const adjustedStartDay = startWeek === "M"
    ? (firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1)
    : firstDayOfMonth.getDay();
  const daysInPrevMonth = new Date(
    prevMonthDate.getFullYear(),
    prevMonthDate.getMonth() + 1,
    0
  ).getDate();

  // Build the grid: always 42 cells (7 columns × 6 rows).
  const totalCells = 42;
  const cells = [];
  for (let i = 0; i < totalCells; i++) {
    let cellData = null;
    if (i < adjustedStartDay) {
      // Previous month's dates.
      const day = daysInPrevMonth - adjustedStartDay + i + 1;
      const dateObj = new Date(
        prevMonthDate.getFullYear(),
        prevMonthDate.getMonth(),
        day
      );
      const dateStr = formatDate(dateObj);
      cellData = { day, dateStr, inCurrentMonth: false, isExercise: exerciseDates.includes(dateStr) };
    } else if (i < adjustedStartDay + daysInCurrentMonth) {
      // Current month's dates.
      const day = i - adjustedStartDay + 1;
      const dateObj = new Date(effectiveYear, effectiveMonth - 1, day);
      const dateStr = formatDate(dateObj);
      cellData = { day, dateStr, inCurrentMonth: true, isExercise: exerciseDates.includes(dateStr) };
    } else {
      // Next month's dates.
      const day = i - (adjustedStartDay + daysInCurrentMonth) + 1;
      const dateObj = new Date(
        nextMonthDate.getFullYear(),
        nextMonthDate.getMonth(),
        day
      );
      const dateStr = formatDate(dateObj);
      cellData = { day, dateStr, inCurrentMonth: false, isExercise: exerciseDates.includes(dateStr) };
    }
    cells.push(cellData);
  }

  // Scale factor: make the whole table 25% smaller.
  const scaleFactor = 0.75;
  const effectiveWidth = containerWidth * scaleFactor;
  const cellSize = effectiveWidth / 7;
  
  // Header heights for the month label and day initials.
  const headerMonthHeight = cellSize * 0.7;
  const headerDayInitialHeight = cellSize * 0.5;
  const headerHeight = headerMonthHeight + headerDayInitialHeight;
  
  // Grid height (6 rows).
  const gridHeight = cellSize * 6;
  
  // Total SVG height.
  const totalHeight = headerHeight + gridHeight;
  
  // Month name and day initials.
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthLabel = `${monthNames[effectiveMonth - 1]} ${effectiveYear}`;
  // Set day initials header based on startWeek option.
  const dayInitials = startWeek === "M"
    ? ['M', 'T', 'W', 'T', 'F', 'S', 'S']
    : ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

  const disablePrev = exerciseDates.length > 0 ? (() => {
    const minDate = new Date(Math.min(...exerciseDates.map(dateStr => new Date(dateStr))));
    const minYear = minDate.getFullYear();
    const minMonth = minDate.getMonth() + 1;
    return (effectiveYear < minYear) || (effectiveYear === minYear && effectiveMonth <= minMonth);
  })() : false;

  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth() + 1;
  const disableNext = (effectiveYear > currentYear) || (effectiveYear === currentYear && effectiveMonth >= currentMonth);
  
  return (
    <svg width={effectiveWidth} height={totalHeight}>
      {/* Month Name Header with Arrows */}
      <text
        x={20}
        y={headerMonthHeight / 2 + 5}
        textAnchor="middle"
        fontSize={headerMonthHeight * 0.7} // increased arrow size
        fontWeight="bolder" // made arrows bolder
        style={disablePrev ? { cursor: 'default', opacity: 0.5 } : { cursor: 'pointer' }}
        onClick={!disablePrev ? (onMonthChange ? () => onMonthChange('prev') : () => handleLocalMonthChange('prev')) : undefined}
      >
        ‹
      </text>
      <text
        x={effectiveWidth / 2}
        y={headerMonthHeight / 2 + 5}
        textAnchor="middle"
        fontSize={headerMonthHeight * 0.8} // increased month year font size
        fontWeight="bold"
      >
        {monthLabel}
      </text>
      <text
        x={effectiveWidth - 20}
        y={headerMonthHeight / 2 + 5}
        textAnchor="middle"
        fontSize={headerMonthHeight * 0.7} // increased arrow size
        fontWeight="bolder" // made arrows bolder
        style={disableNext ? { cursor: 'default', opacity: 0.5 } : { cursor: 'pointer' }}
        onClick={!disableNext ? (onMonthChange ? () => onMonthChange('next') : () => handleLocalMonthChange('next')) : undefined}
      >
        ›
      </text>
      
      {/* Day Initials Header */}
      {dayInitials.map((day, index) => {
        const x = index * cellSize + cellSize / 2;
        const y = headerMonthHeight + headerDayInitialHeight / 2 + 5;
        return (
          <text
            key={index}
            x={x}
            y={y}
            textAnchor="middle"
            fontSize={headerDayInitialHeight * 0.8} // increased day initials font size
            fill="black"
          >
            {day}
          </text>
        );
      })}
      
      {/* Calendar Grid Cells */}
      {cells.map((cell, index) => {
        const col = index % 7;
        const row = Math.floor(index / 7);
        const x = col * cellSize;
        const y = headerHeight + row * cellSize;
        const cx = x + cellSize / 2;
        const cy = y + cellSize / 2;
        const fontSize = cellSize * 0.4; // smaller date numbers
        
        return (
          <g
            key={index}
            style={cell.inCurrentMonth && cell.isExercise ? { cursor: 'pointer' } : {}}
            onClick={cell.inCurrentMonth && cell.isExercise && onDateClick ? () => onDateClick(cell.dateStr) : undefined}
          >
            {/* Only show border for current month cells */}
            {cell.inCurrentMonth && (
              <rect
                x={x}
                y={y}
                width={cellSize}
                height={cellSize}
                fill="none"
                stroke="#ccc"
              />
            )}
            {cell.inCurrentMonth && cell.isExercise && (
              <circle
                cx={cx}
                cy={cy}
                r={cellSize * 0.35}
                fill="rgba(255,0,0,0.5)"
              />
            )}
            {cell.inCurrentMonth && (
              <text
                x={cx}
                y={cy + fontSize * 0.35}
                textAnchor="middle"
                fontSize={fontSize}
                fill={cell.isExercise ? "white" : "black"}
                fontWeight={cell.isExercise ? "bold" : "normal"}
              >
                {cell.day}
              </text>
            )}
          </g>
        );
      })}
    </svg>
  );
}

export default CourseCardCalendar;
