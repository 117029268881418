import React, { Component } from 'react';
import campaign_data from './scripts/campaign_data.js';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
  Paper,
  Box,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

class AddRegistrationCode extends Component {
  constructor(props) {
    super(props);

    this.campdata = campaign_data();

    this.state = {
      code: '',
      duration: 2, // Default duration in months
      selectedCampaigns: [],
      message: '',
      campaigns: Object.keys(this.campdata),
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleCourseChange = (event) => {
    const { value, checked } = event.target;
    this.setState((prevState) => {
      const selectedCampaigns = new Set(prevState.selectedCampaigns);
      if (checked) {
        selectedCampaigns.add(value);
      } else {
        selectedCampaigns.delete(value);
      }
      return { selectedCampaigns: Array.from(selectedCampaigns) };
    });
  };

  handleDurationChange = (event) => {
    const duration = event.target.value;
    this.setState({ duration });
  };

  calculateExpiryDate = () => {
    const { duration } = this.state;
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + parseInt(duration, 10));
    return currentDate.toISOString().substring(0, 10); // Format as YYYY-MM-DD
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { code, selectedCampaigns } = this.state;
    const expiresAt = this.calculateExpiryDate();
    const payload = { code, expiresAt, campaigns: selectedCampaigns };

    try {
      const response = await fetch('/api/create-registration-code', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        this.setState({
          message: 'Registration code added successfully!',
          code: '',
          selectedCampaigns: [],
        });
      } else {
        const error = await response.text();
        this.setState({ message: `Error: ${error}` });
      }
    } catch (error) {
      console.error('Error adding registration code:', error);
      this.setState({ message: 'An error occurred while adding the code.' });
    }
  };

  render() {
    const { code, duration, selectedCampaigns, message, campaigns } = this.state;
    const expiryDate = this.calculateExpiryDate();

    return (
      <Paper elevation={3} sx={{ padding: 4, maxWidth: 600, margin: '20px auto' }}>
        <Typography variant="h4" gutterBottom>
          Add New Registration Code
        </Typography>
        <form onSubmit={this.handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Registration Code"
              variant="outlined"
              fullWidth
              name="code"
              value={code}
              onChange={this.handleChange}
              required
            />
          </Box>
          <Box mb={2}>
            <FormControl fullWidth>
              <InputLabel id="duration-label">Expiry Duration</InputLabel>
              <Select
                labelId="duration-label"
                value={duration}
                label="Expiry Duration"
                onChange={this.handleDurationChange}
              >
                <MenuItem value={1}>1 Month</MenuItem>
                <MenuItem value={2}>2 Months</MenuItem>
                <MenuItem value={3}>3 Months</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </FormControl>
          </Box>
          <Box mb={2}>
            <TextField
              label="Expiry Date"
              variant="outlined"
              fullWidth
              value={expiryDate}
              InputProps={{
                readOnly: true,
              }}
            />
          </Box>
          <Box mb={2}>
            <Typography variant="h6">Select Courses</Typography>
            <Grid container spacing={1} alignItems="flex-start">
              {campaigns.map((course) => (
                <Grid item xs={12} key={course}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={course}
                        checked={selectedCampaigns.includes(course)}
                        onChange={this.handleCourseChange}
                      />
                    }
                    label={this.campdata[course].label}
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      margin: 0,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box mb={2}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Add Code
            </Button>
          </Box>
        </form>
        {message && (
          <Box mt={2}>
            <Alert severity={message.startsWith('Error') ? 'error' : 'success'}>{message}</Alert>
          </Box>
        )}
      </Paper>
    );
  }
}

export default AddRegistrationCode;
