import React, { Component } from 'react';
import { MyContext } from './MyContext';
import MySessions from './pages/MySessions';
import ScoreBox from './ScoreBox';
import campaign_data from './scripts/campaign_data';
import SessionsChart from './SessionsChart'; // new import
import CalcScore from './scripts/calc_score'; // new import

// Material UI components
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  Tabs,
  Tab,
  CircularProgress,
  Autocomplete,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';

const filterOptions = createFilterOptions({
  matchFrom: 'any', // enables matching within the username
  stringify: (option) => option,
});

export default class ResultSelector extends Component {
  static contextType = MyContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedUser: null, // the actual selected user (only valid options)
      inputValue: "", // the text in the input field
      userSelected: false,
      loaded: false,
      activeTab: 0,
      editingUser: false,
      users: [], // list of usernames for autofill
    };

    this.campdata = campaign_data();
  }

  componentDidMount() {
    // Fetch the list of usernames from the backend
    fetch('/api/usernames', { method: 'POST' })
      .then((res) => res.json())
      .then((data) => this.setState({ users: data }))
      .catch((err) => console.error("Error fetching usernames:", err));
  }

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  async userSelected() {
    if (!this.state.selectedUser) {
      console.error("No valid user selected");
      return;
    }
    console.log("ResultSelector userSelected", this.state.selectedUser);
    this.setState({ userSelected: true, loaded: false });

    // Fetch records and calculate stats using the selected user
    this.sessions = await this.context.fetchRecords(this.state.selectedUser);

    // let last_params = false;
    // // TEST RECALULATE SCORES
    // for (let s of this.sessions) {
    //   // if (s.data.scores) continue; // ANDRAS

    //   if (!s.data.eda) continue;
    //   if (s.data.eda.length < 10) continue;

    //   let params = null;
    //   if (s.params.l_incr) params = s.params;
    //   if (s.data.difficulty_params) params = s.data.difficulty_params;
    //   else if (last_params) params = last_params;
    //   else {
    //     params = {
    //       l_incr: 1,
    //       l_decr: 1,
    //       r_incr: 1,
    //       r_decr: 1
    //     }
    //   }

    //   last_params = params;

    //   console.log("TEST RECALULATE SCORES params", params, s);

    //   let calc_score = new CalcScore(params);
    //   let scores = calc_score.calc_all_db(s.data.eda);

    //   s.data.scores = scores; // REPLACE!
    // }


    this.weeklyStats = this.context.calcStats(this.sessions);
    this.campaigns = await this.context.loadCampaigns(this.state.selectedUser);

    this.difficulty = this.context.calcDifficulty(this.sessions);
    const [level, earnedFeatures] = this.context.calcLevels(this.sessions, this.difficulty);
    this.levels = level;

    console.log("weeklyStats", this.weeklyStats);
    console.log("campaigns", this.campaigns);
    console.log("difficulty", this.difficulty);
    console.log("levels", this.levels);

    this.setState({ loaded: true, editingUser: false });
  }

  render() {
    return (
      <Box
        sx={{
          minHeight: '100vh',
          padding: 2,
        }}
      >
        <Grid container spacing={2}>
          {/* Header */}
          <Grid item xs={12}>
            <Card
              sx={{
                backgroundColor: 'rgba(255,255,255,0.8)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 1,
              }}
            >
              <CardContent sx={{ flex: 1 }}>
                <Typography variant="h4">
                  Inspect user:{" "}
                  {this.state.selectedUser ? this.state.selectedUser : "No user selected"}
                </Typography>
                {this.state.userSelected && this.state.loaded && (
                  <Typography variant="body1">
                    Adaptive Difficulty: {Math.floor(this.difficulty)}
                  </Typography>
                )}
              </CardContent>
              <Box sx={{ mr: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => this.setState({ editingUser: true })}
                >
                  Change User
                </Button>
              </Box>
            </Card>
          </Grid>

          {/* User selection form */}
          {this.state.editingUser && (
            <Grid item xs={12}>
              <Card sx={{ padding: 2 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Select User
                  </Typography>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      this.userSelected();
                    }}
                  >
                    <Autocomplete
                      options={this.state.users}
                      filterOptions={filterOptions}
                      value={this.state.selectedUser}
                      inputValue={this.state.inputValue}
                      onChange={(event, newValue) =>
                        this.setState({ selectedUser: newValue })
                      }
                      onInputChange={(event, newInputValue) =>
                        this.setState({ inputValue: newInputValue })
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="User"
                          required
                          margin="normal"
                        />
                      )}
                    />
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          )}

          {/* Tabs and Tab content remain unchanged */}
          <Grid item xs={12}>
            <Tabs
              value={this.state.activeTab}
              onChange={this.handleTabChange}
              textColor="inherit"
              indicatorColor="secondary"
            >
              <Tab label="Stats" />
              <Tab label="Sessions" />
              <Tab label="Chart" />
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            {(!this.state.loaded && this.state.userSelected) ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: 200,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                {this.state.activeTab === 0 && (
                  <Box>
                    {(!this.state.userSelected || !this.state.loaded) ? (
                      <Typography>
                        No data available. Please select a user.
                      </Typography>
                    ) : (
                      <Grid container spacing={2}>
                        {this.campaigns &&
                          this.campaigns.map((camp) => {
                            if (!this.weeklyStats || !this.weeklyStats[camp])
                              return null;

                            const title =
                              this.campdata[camp]
                                ? this.campdata[camp].label
                                : camp;
                            const wstat = this.weeklyStats[camp].wstat;
                            const allstat = this.weeklyStats[camp].allstat;

                            const level = this.levels[camp];

                            const step = level.level;
                            const levelInfo = level.levelInfo;

                            let current_exercise = "-";
                            for (let exname in this.campdata[camp].exercises) {

                              const ex = this.campdata[camp].exercises[exname];

                              console.log("EX", ex);
                              if (step == ex.level) {
                                current_exercise = ex.label
                                break
                              }
                            }

                            const next_exercise = this.campdata[level.next_exercise[0]].exercises[level.next_exercise[1]].label;

                            console.log("CARD step", step);
                            console.log("CARD levelInfo", levelInfo);
                            console.log("CARD next_exercise", next_exercise);

                            return (
                              <Grid item xs={12} sm={6} md={4} key={camp}>
                                <Card
                                  sx={{
                                    background:
                                      'linear-gradient(to right, #d6daffab, #24fff4ab)',
                                    minHeight: 200,
                                    padding: '1px',
                                  }}
                                  elevation={5}
                                >
                                  <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                      {title}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                      Weekly Stats
                                    </Typography>
                                    <ScoreBox data={wstat} />
                                    <Typography
                                      variant="body2"
                                      gutterBottom
                                      sx={{ mt: 2 }}
                                    >
                                      All-Time Stats
                                    </Typography>

                                    <ScoreBox data={allstat} />

                                    <br></br>

                                    <Typography variant="body2" gutterBottom>
                                      <b>Step:</b> {step} ({current_exercise})
                                      <br></br>
                                      <b>Info:</b> {levelInfo}
                                      <br></br>
                                      <b>Next exercise:</b> {next_exercise}
                                    </Typography>


                                  </CardContent>
                                </Card>
                              </Grid>
                            );
                          })}
                      </Grid>
                    )}
                  </Box>
                )}
                {this.state.activeTab === 1 && (
                  <Box>
                    {(!this.state.userSelected || !this.state.loaded) ? (
                      <Typography>
                        No data available. Please select a user.
                      </Typography>
                    ) : (
                      <MySessions user={this.state.selectedUser} sessions={this.sessions} />
                    )}
                  </Box>
                )}
                {this.state.activeTab === 2 && (
                  <Box>
                    {(!this.state.userSelected || !this.state.loaded) ? (
                      <Typography>
                        No data available. Please select a user.
                      </Typography>
                    ) : (
                      <SessionsChart sessions={this.sessions} />
                    )}
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
}
