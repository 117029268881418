

// import { calcLevelSelfAwareness } from './calc_level_self_awareness';
import { calcLevelGeneric } from './calc_level_generic';
import Typography from '@mui/material/Typography';

export const self_awareness_data = {
    self_awareness: {
        label: "Self awareness",
        levelType: "SELFAWARENESS",
        levelFn: calcLevelGeneric,

        levelUpLimit: 5,

        exercises: (() => {
            const baseLevel = 1; // Starting level
            let currentLevel = baseLevel;

            const exercises = {

                s_intro: {
                    section: "Introduction",
                },

                welcome: {
                    label: "Welcome to the module",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven1_welcome/master.m3u8"
                    },
                    instruction: {
                        en: (
                            <div>
                                {/* <Typography variant="body1" align='justify' color="text.primary"> */}
                                <Typography variant="body1" align='left' color="text.primary">

                                    <br></br>
                                    This training module blends experiential learning with personalized biofeedback techniques
                                    to help participants enhance self-awareness and better manage their everyday tension levels
                                    and stress responses. A visual representation of changes in your tension levels is provided
                                    through a unique biofeedback technique. You will learn to <b>calm your waves</b> using conscious
                                    strategies and develop stress management skills through personalized tasks that utilize
                                    cognitive-behavioral methods.
                                </Typography>
                                <br></br>
                                <Typography variant="heading6" align='left' color="text.primary">
                                    Key elements of your training in this module:
                                </Typography>
                                <br></br>
                                <br></br>
                                <Typography variant="body1" align='left' color="text.primary">
                                    <ul>
                                        <li><b>Understanding Stress and Tension:</b>
                                            <br></br>
                                            Explore what stress is and its physical, emotional, and cognitive effects through video
                                            lectures and engaging exercises using the Obimon device.
                                        </li>
                                        <li><b>Learning Through Experience:</b>
                                            <br></br>
                                            Discover your unique path to resilience by experimenting with various cognitive-
                                            behavioral strategies for managing stress, supported by instant biofeedback.
                                        </li>
                                        <li><b>One Size Does Not Fit All:</b>
                                            <br></br>
                                            The difficulty of your practice tasks will be personalized in real-time to provide the
                                            optimal challenge for developing effective stress control skills.
                                        </li>
                                        <li><b>Enhancing Self-Awareness and Stress Control:</b>
                                            <br></br>
                                            Develop the ability to intentionally manage your stress responses by applying these
                                            techniques during challenging moments.
                                        </li>
                                    </ul>

                                    <br></br>
                                    By the end of the course, participants will have the tools to monitor and reduce their tension
                                    levels, gain greater control over their stress responses, and build a strong foundation for
                                    further growth.
                                    <br></br>

                                </Typography>

                            </div>
                        ),

                    },
                },

                howtodo: {
                    label: "How to do this training",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven2_how_to_do_this_training/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    Allocate time in your daily schedule to ensure appropriate time for excercising, as
                                    experiential learning via the Obimon device and the web-based biofeedback tasks are an
                                    essential part of development. Always use your own account to practice, as the program is
                                    personalized to your own level and needs.
                                </Typography>
                                <br></br>
                                <Typography variant="heading6" align='left' color="text.primary">
                                    Main tools of the course to assist your development:
                                </Typography>

                                <Typography variant="body1" align='left' color="text.primary">
                                    <ul>
                                        <li><b>Educational videos:</b> Introducing concepts and explaining how to complete the
                                            training.</li>
                                        <li>
                                            <b>Exercises: </b>Support your regular training (20-minute sessions, 3-4 times a week).
                                        </li>
                                        <li>
                                            <b>Group discussions &amp; webinars:</b> Share experiences and ask questions.
                                        </li>
                                    </ul>
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                howtoobi: {
                    label: "How to use the Obimon device",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven3_how_to_connect_obimon/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">

                                    Before you start training:
                                    <ul>
                                        <li>Turn off your mobile and find a place where you will not be disturbed.</li>
                                        <li>Make sure that Bluetooth is enabled on your computer or tablet.</li>
                                        <li>Attach electrodes to the first and second fingers of your non-dominant hand,
                                            ensuring they are at the same height. The patches should fit snugly around your fingers.</li>
                                        <li>Turn on the Obimon device and check for a blinking green light, which signals that it
                                            is sufficiently charged and ready to measure.</li>
                                    </ul>
                                    While training:
                                    <ul>
                                        <li>Find a comfortable position for your hand with the Obimon device (it can be on a table
                                            or in your lap, face up or down—it does not matter).</li>
                                        <li>Attach the Obimon device to the electrodes (the orientation does not matter).</li>
                                        <li>Open Google Chrome and log into your account at <a href="https://ctw.obimon.com/">ctw.obimon.com</a>.</li>
                                        <li>You may use the <b>GO!</b> button to continue with the suggested exercise, or you can
                                            select an exercise of your choice from previous sessions or re-watch any video.</li>
                                        <li>The program is set to impose a daily limit on exercise sessions, as they can be tiring.</li>
                                        <li>While exercising, avoid moving your hand with the Obimon device to prevent artifacts.
                                            Similarly, talking and laughing can affect your tension level.</li>
                                    </ul>
                                    After training:
                                    <ul>
                                        <li>Detach and turn off the Obimon device; peel off and dispose of the electrodes. You
                                            might want to use an alcohol-based hand sanitizer to clean your fingers.</li>
                                        <li>Charge your device occasionally (you can check its status by hovering your mouse
                                            over the Obimon ID on the screen - can be found on the top-right).</li>
                                    </ul>
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }

                },

                s_chill: {
                    section: "Chillaxing",
                },


                intro_chillaxing: {
                    label: "Introduction to Chillaxing",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v4en_introduction_and_overview/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <br></br>
                                    In the first part of this module, the main focus is on learning ways to reduce tension.
                                    Your personal path to relaxation involves trying out different exercises. An open mind is essential!
                                    You are welcome to repeat those routines that seem to work for you. Your adaptive difficulty score
                                    will change according to your current state to best suit your implicit learning capacity.
                                    <br></br>By performing a handful of Calm the Waves tasks daily, you may find that you naturally apply
                                    these techniques when you need to calm yourself during challenging situations. This is the
                                    path to discovering your inner peace.
                                    <br></br>
                                </Typography>

                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                chill: {
                    label: "The Chill Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                        backgroundImage: "/media/cbtbasic/01fantasy.jpg",
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven6_chill/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <br></br>
                                    Focus on your waves. Think of this process like riding a bicycle: there's no need to overthink it — just chill and go with the flow. You'll be notified when the exercise is complete, and it will take no more than three minutes.
                                    <br></br>
                                </Typography>

                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }

                },

                what_do_waves_mean: {
                    label: "What do Waves Mean?",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v57en_what_do_my_waves_mean/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <br></br>
                                    A timeline of biofeedback - capturing changes in your tension level - is provided after each exercise.
                                    At this stage of the training, our goal is to downregulate tension and promote relaxation. The more green
                                    you see in your feedback, the more successfully you've calmed yourself. However, pay attention to the red
                                    moments too, as they offer meaningful insights into your stressors. This is an outline of how to interpret
                                    feedback:
                                    <br></br>
                                    <div style={{ textAlign: "center" }}>
                                        <img width="50%" src="/media/instr_images/what_do_waves_mean.jpg" />
                                    </div>
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                        relax: 20,
                        practice: 2
                        // sessions: 2
                    }

                },

                // fight_or_flight: {
                //     label: "Fight or Flight",
                //     eda: false,
                //     type: "VideoTutorial",
                //     params: {
                //         url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                //     },
                //     criteria: {
                //         levelSucc: 1,
                //     }

                // },

                peek: {
                    label: "The Peek Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/kukucska.png",
                        backgroundImage: "/media/cbtbasic/02cat.jpg",
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven8_peek/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <br></br>
                                    <b>Calm your waves with your eyes closed. </b>
                                    From time-to-time open your eyes for a moment and peek for a few moments and check if you've managed to calm your waves.
                                    <br></br>
                                </Typography>

                            </div>
                        ),

                    },
                    criteria: {
                        levelSucc: 1,
                    }


                },

                flexibility_and_mindful_action: {
                    label: "Flexibility and Mindful Action",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v41en_flexibility_and_mindful_action/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    Mindful actions in Acceptance and Commitment Therapy (ACT) are deliberate, values-based
                                    behaviors performed with full awareness of the present moment. They involve engaging in
                                    activities intentionally rather than reacting automatically, even in the presence of challenging
                                    thoughts or emotions. Here are some key aspects:
                                    <ul>
                                        <li><b>Present-Moment Awareness:</b> Before acting, you pause and notice your thoughts,
                                            feelings, and bodily sensations without judgment.</li>
                                        <li><b>Acceptance:</b> You allow uncomfortable internal experiences to be present rather than
                                            trying to avoid or suppress them.</li>
                                        <li><b>Values-Based Behavior:</b> Actions are chosen based on what matters most to
                                            you—your core values—rather than being driven by fleeting emotions or automatic habits.</li>
                                        <li><b>Committed Action:</b> Even when it feels difficult, you commit to taking steps that align
                                            with your long-term goals and values, gradually building psychological flexibility.</li>
                                    </ul>
                                    If you feel anxious about attending a social event, for e.g., a mindful action might involve
                                    acknowledging your anxiety, accepting it as a natural feeling, and then deciding to attend the
                                    event because you value connection and community. This process supports a shift from
                                    avoidance toward engaging with life in a meaningful way.
                                </Typography>

                            </div>
                        ),

                    },
                    criteria: {
                        levelSucc: 1,
                        relax: 40,
                        practice: 3

                    }

                },

                observe: {
                    label: "Observe your Waves",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/kukucska.png",
                        backgroundImage: "/media/cbtbasic/woman_blonde.jpg",
                        point_mult: 1,
                    },
                    // video_instruction: {
                    //     url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                    // },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <br></br>
                                    Now, try the chill exercise again, but this time take a moment to really observe your waves. What physical sensations arise as your waves grow calmer and calmer? You may redo this task as many times as you want.
                                    <br></br>
                                </Typography>

                            </div>
                        ),

                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                what_is_cbt: {
                    label: "What is CBT?",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v5en_what_is_cbt/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <br></br>
                                    Cognitive Behavioral Therapy is a powerful tool to change your THOUGHTS, FEELINGS and BEHAVIOR.
                                    Negative thoughts can trap you in cycles of anxiety or sadness, but CBT teaches you how to break
                                    these cycles. Here is how it works:
                                    <ul>
                                        <li><b>Cognitive Restructuring:</b> recognizing negative or unhelpful thoughts and challenging them.
                                            By questioning these thoughts and replacing them with more balanced perspectives, you can
                                            change the way you feel.</li>
                                        <li><b>Behavioral Activation:</b> encourages you to engage in activities that boost your mood.
                                            Even when you feel down, doing things you enjoy or find meaningful can help lift your spirits.</li>
                                        <li><b>Exposure Therapy:</b> if fears hold you back, exposure therapy helps you face them gradually
                                            in a safe and controlled way. Over time, this reduces anxiety and builds confidence.</li>
                                    </ul>
                                    We can use CBT approaches to understand and change negative patterns that contribute to
                                    distress. Using biofeedback techniques, such as Calm The Waves, you can observe how your
                                    thoughts affect your body. This method helps you take a step-by-step approach to shifting
                                    from negative thinking to a healthier, more positive way of living.
                                    <br></br>
                                </Typography>

                            </div>
                        ),

                    },
                    criteria: {
                        levelSucc: 1,
                        relax: 100,
                        decr: 100,
                        practice: 5
                    }

                },

                // relax_points: {
                //     label: "Relax Points",
                //     eda: false,
                //     type: "VideoTutorial",
                //     params: {
                //     },
                //     video_instruction: {
                //         url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                //     },
                //     criteria: {
                //         levelSucc: 1,
                //     }
                // },

                // stressors: {
                //     label: "Stressors",
                //     eda: false,
                //     type: "VideoTutorial",
                //     params: {
                //     },
                //     video_instruction: {
                //         url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven7_stressors/master.m3u8",
                //     },
                //     criteria: {
                //         levelSucc: 1,
                //     }
                // },



                breathe: {
                    label: "The Breathe Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/kukucska.png",
                        backgroundImage: "/media/cbtbasic/04blowball.jpg",
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven10_breath/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>Pay attention to your breathing.</b> First, just pay attention to the rhythm, and later,
                                    observe how by only paying attention to your breathing, it naturally gets deeper and slower.
                                </Typography>

                            </div>
                        ),

                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                power_of_focusing: {
                    label: "The Power of Focusing",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven11_the_power_of_focusing/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align="justify" color="text.primary">
                                    Focusing your attention is key to noticing what truly matters. Watch these entertaining videos and guess how the trick works!
                                </Typography>
                                <div style={{ display: 'flex', gap: '16px', marginTop: '8px' }}>
                                    <iframe
                                        width="25%"
                                        src="https://www.youtube.com/embed/VKaMA-84QfI"
                                        title="Video 1"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    <iframe
                                        width="25%"
                                        src="https://www.youtube.com/embed/4O6stkAu2pY"
                                        title="Video 2"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                    <iframe
                                        width="25%"
                                        src="https://www.youtube.com/embed/-_wGhKl_u2E"
                                        title="Video 3"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </div>
                        ),

                    },
                    criteria: {
                        levelSucc: 1,
                        relax: 150,
                        practice: 5
                    }
                },

                focus: {
                    label: "The Focus Exercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/megkapaszkodos.png",
                        backgroundImage: "/media/cbtbasic/03mountains.jpg",
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven12_focus/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>Choose a spot on the screen</b> and pay attention to that detail only.
                                    If you find yourself distracted, drive your focus back to the chosen point.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                groupwork: {
                    label: "Groupwork",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven13_groupwork/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    Tune in for an opportunity to listen and learn. You can also gain experience simply by observing.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                        practice: 3 
                    }
                },

                why_practice: {
                    label: "Why Practice?",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven14_practice_makes_it_it_perfect/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        relax: 200,
                        decr: 200,
                        practice: 5
                    }
                },

                // why_do_reflections: {
                //     label: "Why do Reflections?",
                //     eda: false,
                //     type: "VideoTutorial",
                //     params: {
                //     },
                //     video_instruction: {
                //         url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven15_why_do_reflections/master.m3u8",
                //     },
                //     criteria: {
                //         levelSucc: 1,
                //     }
                // },

                // self_reflection_template: {
                //     label: "Self-Reflection Template",
                //     eda: false,
                //     type: "VideoTutorial",
                //     params: {
                //     },
                //     video_instruction: {
                //         url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                //     },
                //     criteria: {
                //         levelSucc: 1,
                //     }
                // },

                s_resilience: {
                    section: "Resilience",
                },

                intro_resilience: {
                    label: "Introduction to Resilience",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven16_introduction_and_overview/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    A tennis ball is a great example of resilience—the ability to adapt, cope, and recover.
                                    It is designed to bounce back from hard surfaces, symbolizing our capacity to overcome life's
                                    obstacles and challenges. However, even the toughest ball eventually wears down under constant
                                    stress, and even a tiny puncture can cause it to lose its bounce. This shows that resilience is
                                    not infinite and requires care. In this part of the training, we aim to enhance your ability to
                                    maintain focus and relax, even under pressure. Your balance points will reflect how well you
                                    nurture your emotional resilience.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                        relax: 250,
                        decr: 250,
                        practice: 5
                    }
                },

                creative_hopelessness: {
                    label: "Creative Hopelessness",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven17_creative_hopelessness/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        decr: 300,
                        practice: 5
                    }
                },

                flower: {
                    label: "The Flower Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        flower: true,
                        backgroundImage: "/media/tree.jpg",
                        duration: 300000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven18_flower/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    If you can keep your waves calm, you will see a flower grow on the screen.
                                    The flowe will keep growing as long as you can keep your waves calm.
                                    You have 5 minutes to grow the flower.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                why_keep_diary: {
                    label: "Why Keep a Diary?",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven19_why_keep_a_diary/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                effortful_control: {
                    label: "Effortful Control",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven20_effortful_control/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                control_balance_points: {
                    label: "Control and Balance Points - MISSING VIDEO",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                power_of_five_senses: {
                    label: "The Power of Five Senses",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven21_the_five_senses/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        decr: 350,
                        practice: 5
                    }
                },

                touch: {
                    label: "The Touch Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/ujjbungyurgetos.png",
                        backgroundImage: "/media/cbtbasic/06yoga.jpg",
                        // introLabel: "Érintsd össze a hüvelyk és mutató ujjadat és körözz lassan!",
                        // introTime: 20000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven22_touch/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>Touch two fingers</b> and try comforting yourself by moving them gently if you please.
                                    Pay attention to the physical and emotional sensations.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                self_compassion: {
                    label: "Self Compassion",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven24_self_compassion/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        decr: 400,
                        practice: 5
                    }
                },

                hug: {
                    label: "The Hug Exercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/dudolos.png",
                        backgroundImage: "/media/cbtbasic/07hands.jpg",
                        // introLabel: "Dúdold magadban a kedvenc dalodat!",
                        // introTime: 20000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven23_hug/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>Place your hand gently on your chest or arms </b> try comforting yourself by moving it smoothly.
                                    Pay attention to the physical and emotional sensations.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                // memories_game_changers: {
                //     label: "Memories as Game Changers",
                //     eda: false,
                //     type: "VideoTutorial",
                //     params: {
                //     },
                //     video_instruction: {
                //         url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven25_memory/master.m3u8",
                //     },
                //     criteria: {
                //         levelSucc: 1,
                //     }
                // },

                hum: {
                    label: "The Hum Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        // backgroundImage: "/media/hintazos.png",
                        backgroundImage: "/media/cbtbasic/09guitar.jpg",
                        // introLabel: "Keress két pontot a képen és lassan hintázz köztük a szemeddel!",
                        // introTime: 20000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven26_hum/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>Think about your favorite song</b> and try to relax while you hum it quietly, or just think about it.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                good_stress_bad_stress: {
                    label: "Good Stress, Bad Stress",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven27_good_stress_bad_stress/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        decr: 450,
                        practice: 5
                    }
                },

                shift: {
                    label: "The Shift Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 180000,
                        backgroundImage: "/media/hintazos.png",
                        // introLabel: "Keress két pontot a képen és lassan hintázz köztük a szemeddel!",
                        // introTime: 20000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven28_shift/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>Choose two spots</b> on the image and shift your attention between them continuously.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                building_emotional_muscle: {
                    label: "Building your Emotional Muscle",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven29_building_your_emotional_muscle/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        decr: 500,
                        practice: 5
                    }
                },

                how_to_enhance_resilience: {
                    label: "How to Enhance Resilience?",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven30_how_to_enhance_resilience/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                s_stressors: {
                    section: "Stressors",
                },

                intro_stressors: {
                    label: "Introduction to Stressors",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven31_introduction_and_overview/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        decr: 550,
                        practice: 5
                    }
                },

                optimal_arousal: {
                    label: "Optimum Arousal - MISSING VIDEO",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/XXX/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                time_as_stressor: {
                    label: "Time as a Stressor",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v42en_time_as_a_stressor/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },


                time_exercise: {
                    label: "The Time Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 40000,
                        // backgroundImage: "/media/00szinkombinacioJO_5.jpg",
                        backgroundImage: "/media/cbtbasic/10boat.jpg",
                        // introLabel: "You have 40 seconds to calm your waves.",
                        // introTime: 20000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v48en_the_tree_excercise/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>XXXXX Now you are ready to find your inner peace. </b>
                                    You've learned different relaxation techniques ready to put forward in a challenge.
                                    You'll have 40 seconds to calm your waves whatever way you see fit.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                why_stress_out: {
                    label: "Why do we Stress out?",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven32_why_we_stress_out/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                sounds: {
                    label: "The Sounds Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 193000,
                        // backgroundImage: "/media/lelegzos.png",
                        backgroundImage: "/media/cbtbasic/05bell.jpg",
                        backgroundSound: "/media/cbtbasic/sounds.mp4",
                        continueOnZero: true,
                        hideWaves: false,
                        // introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",                
                        // introTime: 20000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven33_sounds/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    <b>Pay attention to the sounds. </b> Observe your body and your reactions.
                                    Try not to engage with what you hear, just listen.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                intrusive_thoughts: {
                    label: "Intrusive Thoughts",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven34_intrusive_thoughts/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        decr: 700,
                        practice: 5
                    }
                },

                thoughts: {
                    label: "The Toughts Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        duration: 193000,
                        // backgroundImage: "/media/lelegzos.png",
                        backgroundImage: "/media/cbtbasic/05bell.jpg",
                        backgroundSound: "/media/cbtbasic/sounds.mp4",
                        continueOnZero: true,
                        hideWaves: false,
                        // introLabel: "Mondd lassan magadban és közben csináld is: Beszívom a levegőt és kifújom a levegőt!",                
                        // introTime: 20000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven35_thoughts/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    ???
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                updown: {
                    label: "Up-down",
                    eda: true,
                    type: "WaveUpdown",
                    params: {
                        backgroundImage: "/media/moon.jpg",
                        visual: {
                            incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                            decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                        },
                        duration: 180000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v44en_the_up_down_excercise/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    ???
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                stress_tolerance: {
                    label: "Stress Tolerance",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/ven36_stress_tolerance/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                test_your_stress_tolerance: {
                    label: "Test Your Stress Tolerance - MISSING VIDEO",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/venxxx_stress_tolerance/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                s_inner_peace: {
                    section: "Inner Peace",
                },

                intro_inner_peace: {
                    label: "Introduction to Inner Peace",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v46en_introduction_to_inner_peace/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                life_goals: {
                    label: "Life Goals and Life Values",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v47en_life_goals_and_life_values/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        practice: 5
                    }
                },

                tree: {
                    label: "The Tree Excercise",
                    eda: true,
                    type: "Waves",
                    params: {
                        tree: true,
                        backgroundImage: "/media/desert4.jpg",
                        duration: 300000,
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v48en_the_tree_excercise/master.m3u8",
                    },
                    instruction: {
                        en: (
                            <div>
                                <br></br>
                                <Typography variant="body1" align='justify' color="text.primary">
                                    If you can keep your waves calm, you will see a tree grow on the screen.
                                    The tree will keep growing as long as you can keep your waves calm.
                                    You have 5 minutes to grow the tree.
                                </Typography>
                            </div>
                        ),
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                committed_action: {
                    label: "Committed Action",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v49en_commited_action/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        practice: 5
                    }
                },

                tetris: {
                    label: "Tetris",
                    eda: true,
                    type: "Tetris",
                    params: {
                        point_mult: 1,
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v50_the_tetris_excercise/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                    }
                },

                power_of_habituation: {
                    label: "The Power of Habituation",
                    eda: false,
                    type: "VideoTutorial",
                    params: {
                    },
                    video_instruction: {
                        url: "https://ctw-videos.s3.eu-central-1.amazonaws.com/v51en_the_power_of_habituation/master.m3u8",
                    },
                    criteria: {
                        levelSucc: 1,
                        practice: 5
                    }
                },





                // mindfulness1: {
                //     label: "Mindfulness",
                //     eda: true,
                //     type: "Waves",
                //     params: {
                //         duration: 193000,
                //         backgroundImage: "/media/cbtbasic/11panda.jpg",
                //         backgroundSound: "/media/mindfulness.m4a",
                //         continueOnZero: true,
                //         hideWaves: false,
                //         point_mult: 1,
                //         // l_incr: 1,
                //         // l_decr: 1,
                //         // r_incr: 1,
                //         // r_decr: 1
                //     },

                //     instruction: {
                //         en: (
                //             <div>
                //                 <br></br>
                //                 <Typography variant="body1" align='justify' color="text.primary">
                //                     <b></b>
                //                     Mindfulness meditation is a mental training practice that teaches you to slow
                //                     down racing thoughts, let go of negativity, and calm both your mind and body.
                //                 </Typography>
                //             </div>
                //         ),
                //     }

                // },

                // mindfulness2: {
                //     label: "Body-scan technique",
                //     eda: true,
                //     type: "Waves",
                //     params: {
                //         duration: 560000,
                //         backgroundImage: "/media/cbtbasic/12beach.jpg",
                //         backgroundSound: "/media/bodyscan.m4a",
                //         continueOnZero: true,
                //         hideWaves: false,
                //         point_mult: 1,
                //         // l_incr: 1,
                //         // l_decr: 1,
                //         // r_incr: 1,
                //         // r_decr: 1
                //     },

                //     instruction: {
                //         en: (
                //             <div>
                //                 <br></br>
                //                 <Typography variant="body1" align='justify' color="text.primary">
                //                     <b></b>
                //                     Body scanning is a simple and effective way to relax your body and mind.
                //                 </Typography>
                //             </div>
                //         ),
                //     }

                // },

                // mindfulness3: {
                //     label: "Acceptance meditation",
                //     eda: true,
                //     type: "Waves",
                //     params: {
                //         duration: 327000,
                //         backgroundImage: "/media/cbtbasic/13bamboo.jpg",
                //         backgroundSound: "/media/acceptancemeditation.m4a",
                //         continueOnZero: true,
                //         hideWaves: false,
                //         point_mult: 1,
                //         // l_incr: 1,
                //         // l_decr: 1,
                //         // r_incr: 1,
                //         // r_decr: 1
                //     },

                //     instruction: {
                //         en: (
                //             <div>
                //                 <br></br>
                //                 <Typography variant="body1" align='justify' color="text.primary">
                //                     <b></b>
                //                     Acceptance meditation is a practice that helps you to accept the present moment.
                //                 </Typography>
                //             </div>
                //         ),
                //     }

                // },

                // updown: {
                //     label: "Up-down",
                //     eda: true,
                //     type: "WaveUpdown",
                //     params: {
                //         backgroundImage: "/media/moon.jpg",
                //         visual: {
                //             incr: ["/media/incr/fire1.jpg", "/media/incr/fire2.jpg", "/media/incr/fire3.jpg", "/media/incr/fire4.jpg", "/media/incr/fire5.jpg", "/media/incr/fire6.jpg",],
                //             decr: ["/media/decr/water1.jpg", "/media/decr/water2.jpg", "/media/decr/water3.jpg", "/media/decr/water4.jpg", "/media/decr/water5.jpg", "/media/decr/water6.jpg", "/media/decr/water7.jpg", "/media/decr/water8.jpg",],
                //         },
                //         duration: 180000,
                //         point_mult: 1,
                //         l_incr: 2,
                //         l_decr: 1,
                //         r_incr: 2,
                //         r_decr: 1,

                //     },

                // },


            };

            // Assign levels incrementally to all exercises
            for (const key in exercises) {
                if (!exercises[key].section) {
                    exercises[key].level = currentLevel++;
                }
            }

            // Calculate cumulative practice during init time
            let cumulativePractice = 0;
            for (const key in exercises) {
                if (exercises[key].criteria && exercises[key].criteria.practice) {
                    cumulativePractice += exercises[key].criteria.practice;
                    exercises[key].criteria.sum_practice = cumulativePractice;

                    console.log("YYY", key, cumulativePractice);

                    // Optionally remove the old property:
                    // delete exercises[key].criteria.num_practice;
                }
            }

            return exercises;
        })(),

    }

}
