import React from 'react';
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  InputLabel,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { MyContext } from '../MyContext';

// Styled Paper for consistent card-like appearance.
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
}));

// Service UUIDs for app and OTA
const edaAppService = '43974957-3487-9347-5977-654321987654';
const edaOtaService = '8a97f7c0-8506-11e3-baa7-0800200c9a66';

// Device modes
const DeviceMode = {
  UNKNOWN: "Unknown",
  OTA: "OTA",
  APP: "APP",
};

// OTA transfer parameters
const CHUNK_LENGTH = 13 * 16;
const NUM_OF_CHUNKS_TO_SEND = 8;
const SECTOR_SIZE = 8 * 1024;

let writeAddressCharacteristic;
let indicateCharacteristic;
let writeWithoutResponseCharacteristic;
let fileContent;
let fileLength;
let nbSector;
let readyToReceive = false;
let uploadClicked = false;
var myDevice;

function resetVariables() {
  writeAddressCharacteristic = null;
  indicateCharacteristic = null;
  writeWithoutResponseCharacteristic = null;
  fileContent = null;
  fileLength = null;
  nbSector = null;
  readyToReceive = null;
  uploadClicked = null;
  myDevice = null;
}

export default class Ota extends React.Component {
  state = {
    connected: false,
    devMode: DeviceMode.UNKNOWN,
    currentStep: "not_connected", // changed to a short identifier
    devServices: [],
    devCharacteristics: [],
    manualySettingNbSector: false,
    uploadButtonEnabled: false,
    uploadPercentage: 0,
    isOperating: false,
    firmwareSource: 'server',
    firmwareList: [],
    selectedFirmware: ''
  };

  constructor(props) {
    super(props);
    console.log("Ota constructor", props.size);
  }

  getSupportedProperties(characteristic) {
    let supportedProperties = [];
    for (const p in characteristic.properties) {
      if (characteristic.properties[p] === true) {
        supportedProperties.push(p.toUpperCase());
      }
    }
    return supportedProperties.join(', ');
  }

  onDisconnected() {
    if (this.state.devMode === DeviceMode.OTA) {
      console.log('Device disconnected in OTA mode');
      this.setState({
        connected: false,
        currentStep: "reconnect" // shortened
      });
    }
  }

  // Connect to the device (either in APP or OTA mode)
  connect = () => {

    let options = {
      filters: [

        // xxxxxxxxx This is the format of a 16bit uuid. The actual 16 bits are "7654"
        { services: ['00007654-0000-1000-8000-00805f9b34fb'] }, 

        { services: [edaOtaService] },
        { services: ['669a0c20-0008-a7ba-e311-0685c0f7978a'] }, // this is in reverse order due to bug in OTA firmware
        
        { namePrefix: '_' },
        { name: '_edaota' },
        { name: 'OTAServiceMgr' },

      ],
      // multiple: true
    }

    this.setState({ isOperating: true });
    console.log('Requesting Bluetooth Device...');
    navigator.bluetooth.requestDevice(options)
      .then(device => {
        myDevice = device;
        myDevice.addEventListener('gattserverdisconnected', () => this.onDisconnected());
        return device.gatt.connect();
      })
      .then(server => server.getPrimaryServices())
      .then(services => {
        let queue = Promise.resolve();
        services.forEach(service => {
          if (service.uuid === edaOtaService) {
            console.log('OTA service found');
            this.setState({ devMode: DeviceMode.OTA, currentStep: "ota_connected" }); // shortened
          } else if (service.uuid === edaAppService) {
            console.log('APP service found');
            this.setState({ devMode: DeviceMode.APP, currentStep: "app_connected" }); // shortened
          }
          this.setState(prevState => ({
            devServices: [...prevState.devServices, service]
          }));
          queue = queue.then(() =>
            service.getCharacteristics().then(characteristics => {
              characteristics.forEach(characteristic => {
                this.setState(prevState => ({
                  devCharacteristics: [...prevState.devCharacteristics, characteristic]
                }));
                if (this.state.devMode === DeviceMode.APP) {
                  if (characteristic.uuid === "43789734-9798-3479-8347-98347988787a") {
                    console.log('APP go to OTA characteristic found');
                    writeAddressCharacteristic = characteristic;
                  }
                } else if (this.state.devMode === DeviceMode.OTA) {
                  switch (characteristic.uuid) {
                    case "210f99f0-8508-11e3-baa7-0800200c9a66":
                      writeAddressCharacteristic = characteristic;
                      break;
                    case "2bdc5760-8508-11e3-baa7-0800200c9a66":
                      indicateCharacteristic = characteristic;
                      indicateCharacteristic.startNotifications();
                      indicateCharacteristic.oncharacteristicvaluechanged = this.notifHandler;
                      break;
                    case "2691aa80-8508-11e3-baa7-0800200c9a66":
                      writeWithoutResponseCharacteristic = characteristic;
                      break;
                    default:
                      break;
                  }
                }
                console.log('Characteristic: ' + characteristic.uuid + ' ' + this.getSupportedProperties(characteristic));
              });
            })
          );
        });
        return queue;
      })
      .then(() => {
        this.setState({ isOperating: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ currentStep: "Error: " + error, isOperating: false });
      });
  }

  // Reconnect specifically in OTA mode.
  connectOta = () => {
    this.setState({ isOperating: true });
    console.log('Reconnecting in OTA mode...');
    this.connect();
  }

  // OTA notification handler.
  notifHandler = (event) => {
    console.log("Notification received");
    const buf = new Uint8Array(event.target.value.buffer);
    let replyCounter = buf[1] << 8 | buf[0];
    let errCode = buf[3] << 8 | buf[2];
    console.log('replyCounter ', replyCounter, ' errCode', errCode);
    if (!uploadClicked) {
      console.log('Upload not started yet');
      return;
    }
    if (errCode === 0 || errCode === 912) {
      if (errCode === 912) {
        console.log('Resend requested');
      }
      readyToReceive = true;
      this.sliceAndSend(replyCounter);
    }
  }

  // Initiate OTA mode transition from APP mode.
  goToOtaButtonClick = async () => {
    if (!writeAddressCharacteristic) {
      console.error('Device upgrade command characteristic not available.');
      this.setState({ currentStep: "error_no_char" });
      return;
    }
    this.setState({ isOperating: true, currentStep: "switching_mode" });
    const myWord = new Uint8Array(2);
    myWord[0] = 1; // OTA command action

    const props = writeAddressCharacteristic.properties;
    if (!props.write && !props.writeWithoutResponse) {
      const errorMsg = "Device upgrade command characteristic does not support write operations.";
      console.error(errorMsg);
      this.setState({ currentStep: "Error: " + errorMsg, isOperating: false });
      return;
    }

    try {
      await writeAddressCharacteristic.writeValue(myWord);
      console.log("Sent device upgrade command", myWord);
    } catch (error) {
      if (error.name === 'NotSupportedError') {
        console.warn("NotSupportedError caught, assuming OTA mode switch succeeded.");
      } else {
        console.error('Error sending OTA command: ' + error);
        this.setState({ currentStep: "Error: " + error, isOperating: false });
        return;
      }
    }
    this.setState({ currentStep: "mode_switched_reconnect", isOperating: false });
  }

  // Start the OTA update process and disable the start button immediately.
  startUploadButtonClick = async () => {
    this.setState({ isOperating: true, uploadButtonEnabled: false });
    this.calculateNbSector();
    readyToReceive = true;
    uploadClicked = true;
    await this.writeAddress();
    await this.sliceAndSend(0);
    this.setState({ isOperating: false });
  }

  writeAddress = async () => {
    let sizeHex = fileLength.toString(16).padStart(8, '0');
    let sizeHexFistPart = parseInt(sizeHex.substring(0, 2), 16);
    let sizeHexSecondePart = parseInt(sizeHex.substring(2, 4), 16);
    let sizeHexThirdPart = parseInt(sizeHex.substring(4, 6), 16);
    let sizeHexForthPart = parseInt(sizeHex.substring(6, 8), 16);
    let address = "10051800";
    let hexStringFistPart = parseInt(address.substring(0, 2), 16);
    let hexStringSecondePart = parseInt(address.substring(2, 4), 16);
    let hexStringThirdPart = parseInt(address.substring(4, 6), 16);
    let hexStringForthPart = parseInt(address.substring(6, 8), 16);
    const myWord = new Uint8Array(9);
    myWord[0] = 1; // notification interval placeholder
    myWord.set([sizeHexForthPart, sizeHexThirdPart, sizeHexSecondePart, sizeHexFistPart], 1);
    myWord.set([hexStringForthPart, hexStringThirdPart, hexStringSecondePart, hexStringFistPart], 5);
    try {
      await writeAddressCharacteristic.writeValue(myWord);
      console.log("Writing address", myWord);
    } catch (error) {
      console.log('Error writing address: ' + error);
    }
  }

  calculateNbSector = () => {
    fileLength = fileContent.length;
    nbSector = Math.ceil(fileLength / SECTOR_SIZE);
    console.log("File length =", fileLength, "NbSector =", nbSector);
  }

  sliceAndSend = async (from_chunk) => {
    let totalBytes = 0;
    if (readyToReceive) {
      for (let act_chunk = from_chunk; act_chunk < NUM_OF_CHUNKS_TO_SEND + from_chunk; act_chunk++) {
        let start = act_chunk * CHUNK_LENGTH;
        let end = start + CHUNK_LENGTH;
        if (end > fileLength) {
          console.log('Upload finished');
          this.otaFinished();
          return;
        }
        let seqnumHex = act_chunk.toString(16).padStart(4, '0');
        let seqNumHigh = parseInt(seqnumHex.substring(0, 2), 16);
        let seqNumLow = parseInt(seqnumHex.substring(2, 4), 16);
        let needNotif = 1;
        let fileChunk = fileContent.slice(start, end);
        let toSend = new Uint8Array(CHUNK_LENGTH + 4);
        toSend.set(fileChunk, 1);
        toSend.set([needNotif], CHUNK_LENGTH + 1);
        toSend.set([seqNumLow], CHUNK_LENGTH + 2);
        toSend.set([seqNumHigh], CHUNK_LENGTH + 3);
        let chsum = 0;
        for (let i = 1; i < CHUNK_LENGTH + 4; i++) {
          chsum ^= toSend[i];
        }
        toSend[0] = chsum;
        try {
          await writeWithoutResponseCharacteristic.writeValue(toSend);
        } catch (error) {
          console.log("Error sending chunk:", error);
        }
        totalBytes += toSend.length;
        let percentage = (end * 100) / fileLength;
        this.setState({ uploadPercentage: percentage });
        console.log('Sent chunk', act_chunk, 'Total bytes sent:', totalBytes);
      }
    } else {
      console.log("Not ready to receive...");
    }
  }

  browseFileButtonClick = async (input) => {
    this.setState({ uploadButtonEnabled: false });
    const file = input.target.files[0];
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      let uint8View = new Uint8Array(reader.result);
      let fileLen16B = Math.ceil(uint8View.length / CHUNK_LENGTH) * CHUNK_LENGTH;
      let rightPadding = fileLen16B - uint8View.length;
      fileContent = new Uint8Array(uint8View.length + rightPadding);
      fileContent.set(uint8View, 0);
      fileContent.fill(0xff, uint8View.length);
      console.log("File prepared for OTA update");
      // Enable the upgrade button only after the file is fully processed.
      this.setState({ uploadButtonEnabled: true });
    };
    reader.onerror = () => {
      console.error("Error reading the file");
    };
  };

  otaFinished = () => {
    resetVariables();
    // Disconnect device if still connected
    if (myDevice && myDevice.gatt && myDevice.gatt.connected) {
      myDevice.gatt.disconnect();
      console.log("Device disconnected after OTA finish.");
    }
    this.setState({
      connected: false,
      devMode: DeviceMode.UNKNOWN,
      currentStep: "OTA Update Finished",
      devServices: [],
      devCharacteristics: [],
      manualySettingNbSector: false,
      uploadButtonEnabled: false,
      uploadPercentage: 0,
    });
  }

  // New: Fetch firmware list from server
  fetchFirmwareList = async () => {
    console.log("Fetching firmware list");
    try {
      const res = await fetch('/api/firmware-list', { method: 'POST' });
      const firmwareList = await res.json();
      this.setState({ firmwareList });
      console.log("Firmware list fetched", firmwareList);
    } catch (error) {
      console.log("Error fetching firmware list", error);
    }
  }

  // New: Load firmware from server for upgrade (changed to use POST)
  loadFirmwareFromServer = async () => {
    console.log("Loading firmware from server", this.state.selectedFirmware);
    if (!this.state.selectedFirmware) return;
    try {
      const res = await fetch('/api/firmware/' + this.state.selectedFirmware, { method: 'POST' });
      const arrayBuffer = await res.arrayBuffer();
      let uint8View = new Uint8Array(arrayBuffer);
      let fileLen16B = Math.ceil(uint8View.length / CHUNK_LENGTH) * CHUNK_LENGTH;
      let rightPadding = fileLen16B - uint8View.length;
      fileContent = new Uint8Array(uint8View.length + rightPadding);
      fileContent.set(uint8View, 0);
      fileContent.fill(0xff, uint8View.length);
      fileLength = fileContent.length;
      console.log("Firmware loaded from server");
      this.setState({ uploadButtonEnabled: true });
    } catch (error) {
      console.log("Error loading firmware", error);
    }
  }

  // New: Handle change in firmware source (local or server)
  handleFirmwareSourceChange = (e) => {
    console.log("Firmware source changed", e.target.value);
    this.setState({ firmwareSource: e.target.value });
    if (e.target.value === 'server') {
      this.fetchFirmwareList();
    }
  }

  // New: Handle firmware selection from dropdown
  handleFirmwareSelect = (e) => {
    const firmware = e.target.value;
    this.setState({ selectedFirmware: firmware, uploadButtonEnabled: false }, () => {
      if (firmware) {
        this.loadFirmwareFromServer();
      }
    });
  }

  // Fetch firmware list when component mounts
  componentDidMount() {
    // If a device is already connected as indicated by deviceName in the context, update currentStep accordingly
    // if (this.context && this.context.deviceName) {
    //   this.setState({
    //     connected: true,
    //     currentStep: "app_connected"
    //   });
    // }
    // ...existing code...
    this.fetchFirmwareList().then(() => {
      if (this.state.firmwareList.length > 0) {
        this.setState({
          firmwareSource: 'server',
          selectedFirmware: this.state.firmwareList[0],
        }, () => {
          this.loadFirmwareFromServer();
        });
      }
    });
  }

  // New: Map currentStep to an active step index
  getActiveStep() {
    const stepMapping = {
      "not_connected": 0,
      "app_connected": 0,
      "switching_mode": 1,
      "mode_switched_reconnect": 1,
      "ota_connected": 1,
      // Set "uploading" during upload; you might set this in startUploadButtonClick.
      "uploading": 2,
      "OTA Update Finished": 3,
      "error_no_char": 0
    };
    return stepMapping[this.state.currentStep] ?? 0;
  }

  render() {
    // ...existing code...
    return (
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        {/* NEW: Horizontal Stepper for OTA process */}
        <Stepper activeStep={this.getActiveStep()} alternativeLabel>
          {["Connect Device", "Switch Mode", "Upload Firmware", "Complete"].map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* ...existing Grid container and content... */}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Item>
              <Typography variant="h4" gutterBottom>
                Device Upgrade Process
              </Typography>
              <Typography variant="body1" color="error" gutterBottom>
                Warning: During and after Device Upgrade, the device may temporarily change its name to _edaota. This is normal—simply reconnect using that name.
              </Typography>
              <Typography variant="body1" color="error" gutterBottom>
                While the upgrade is in progress, DO NOT DISCONNECT, SWITCH OFF THE DEVICE, OR CLOSE THE BROWSER WINDOW!
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Status: {this.state.currentStep}
              </Typography>

              {this.state.currentStep === "not_connected" && (
                <Button variant="contained" disabled={this.state.isOperating} onClick={this.connect}>
                  Connect Device
                </Button>
              )}

              {this.state.currentStep === "app_connected" && (
                <Box mt={2}>
                  <Typography variant="body1" gutterBottom>
                    Your device is connected in APP mode. Ready to switch to Device upgrade mode.
                  </Typography>
                  <Button variant="contained" disabled={this.state.isOperating} onClick={this.goToOtaButtonClick}>
                    Switch to Device upgrade mode
                  </Button>
                </Box>
              )}

              {(this.state.currentStep === "reconnect" ||
                this.state.currentStep === "mode_switched_reconnect") && (
                  <Box mt={2}>
                    <Button variant="contained" disabled={this.state.isOperating} onClick={this.connectOta}>
                      Reconnect in Device upgrade mode
                    </Button>
                  </Box>
                )}

              {this.state.currentStep === "ota_connected" && (
                <Box mt={2}>
                  <Typography variant="body1" gutterBottom>
                    Device connected in Device upgrade mode. Select a firmware file and start the upgrade.
                  </Typography>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Firmware Source</FormLabel>
                    <RadioGroup row value={this.state.firmwareSource} onChange={this.handleFirmwareSourceChange}>
                      <FormControlLabel value="local" control={<Radio />} label="Local File System" />
                      <FormControlLabel value="server" control={<Radio />} label="Server Firmware" />
                    </RadioGroup>
                  </FormControl>
                  {this.state.firmwareSource === 'local' ? (
                    <Box mt={2}>
                      <input type="file" onChange={this.browseFileButtonClick} disabled={this.state.isOperating} />
                    </Box>
                  ) : (
                    <Box mt={2}>
                      <FormControl fullWidth>
                        <InputLabel id="firmware-select-label">Select Firmware</InputLabel>
                        <Select
                          labelId="firmware-select-label"
                          value={this.state.selectedFirmware}
                          label="Select Firmware"
                          onChange={this.handleFirmwareSelect}
                        >
                          {this.state.firmwareList.map((fw, idx) => (
                            <MenuItem key={idx} value={fw}>{fw}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {this.state.firmwareList.length > 0 && (
                        <Typography variant="caption" display="block" mt={1}>
                          Default Firmware: {this.state.firmwareList[0]}
                        </Typography>
                      )}
                    </Box>
                  )}
                  {(this.state.devMode === DeviceMode.OTA &&
                    this.state.uploadButtonEnabled &&
                    !this.state.isOperating) && (
                      <Box mt={2}>
                        <Button variant="contained" onClick={this.startUploadButtonClick}>
                          Start Device Upgrade
                        </Button>
                      </Box>
                    )}
                </Box>
              )}

              {(this.state.uploadPercentage > 0 && this.state.uploadPercentage < 100) && (
                <Box mt={2}>
                  <Typography variant="body2">
                    Upload Progress: {this.state.uploadPercentage.toFixed(0)}%
                  </Typography>
                </Box>
              )}

              {this.state.currentStep === "OTA Update Finished" && (
                <Box mt={2}>
                  <Typography variant="h6" color="success.main">
                    Device upgrade completed successfully.
                  </Typography>
                </Box>
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

// Add contextType to use App.js connection state
Ota.contextType = MyContext;
