import React from 'react'
import Wave from 'react-wavify'
import wt from 'discrete-wavelets';
import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

import Backdrop from '@mui/material/Backdrop';
// import BackdropUnstyled from '@mui/base/BackdropUnstyled';
import Fab from '@mui/material/Fab';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import WaveResultCard from '../WaveResultCard';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import ScoreBox from '../ScoreBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CardContent } from '@mui/material';

import Tree from '../pages/Tree';
import Flower from '../pages/Flower';

// import { session } from 'passport';
import { MyContext } from '../MyContext';

async function sleep(seconds) {
    return new Promise((resolve) => setTimeout(resolve, seconds * 1000));
}


async function aiCreateThread() {
    const resp = await fetch('/api/ai_create_thread', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        // body: JSON.stringify()
    })

    let d = await resp.json();

    console.log("OPENAI aiCreateThread: ", d.thread_id);

    return d.thread_id;

}

async function aiCreateMessage(thread_id, message) {
    console.log("OPENAI aiCreateMessage", message);
    const resp = await fetch('/api/ai_create_message', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            thread_id: thread_id,
            message: message
        })
    })

    let d = await resp.json();

    console.log("OPENAI aiCreateMessage response: ", d);

    return d.msg;

}

async function aiCreateRun(thread_id) {
    const resp = await fetch('/api/ai_create_run', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            thread_id: thread_id,
        })
    })

    let d = await resp.json();

    console.log("OPENAI aiCreateRun: ", d.run);

    return d.run;

}

async function aiRetrieveRun(thread_id, run_id) {
    const resp = await fetch('/api/ai_retrieve_run', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            thread_id: thread_id,
            run_id: run_id,
        })
    })

    let d = await resp.json();

    console.log("OPENAI aiRetrieveRun resp: ", d.run);

    return d.run;

}

async function aiSubmitToolOutputs(thread_id, run_id, tool_call_id, output) {
    const resp = await fetch('/api/ai_submit_tool_outputs', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            thread_id: thread_id,
            run_id: run_id,
            tool_call_id: tool_call_id,
            output: output
        })
    })

    let d = await resp.json();

    console.log("OPENAI aiSubmitToolOutputs resp: ", d.msg);

    return d.msg;

}

async function aiGetMessages(thread_id) {
    const resp = await fetch('/api/ai_get_messages', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            thread_id: thread_id,
        })
    })

    let d = await resp.json();

    // console.log("OPENAI aiCreateRun: ", d.run);

    return d.msg;

}

async function aiCreateImage(prompt) {
    const resp = await fetch('/api/ai_create_image', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            model: "dall-e-3",
            prompt: prompt,
            size: "1024x1024",
            quality: "standard",
            style: "vivid",

        })
    });

    let d = await resp.json();
    // console.log("OPENAI RESP", d);

    let img = d.resp.data[0].b64_json;
    img = `data:image/jpeg;base64,${img}`;

    //console.log("OPENAI IMG", img);

    return img;
}

async function aiCreateSpeech(input) {
    const resp = await fetch('/api/ai_create_speech', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            model: "tts-1",
            voice: "alloy",
            input: input,
        })
    });

    let d = await resp.json();

    //console.log("OPENAI voice resp", d);


    let mp3 = `data:audio/mp3;base64,${d.resp}`;

    return mp3;
}


class Story extends React.Component {
    static contextType = MyContext;

    constructor(props) {
        super(props);

        this.col1 = [178, 137, 239, 0.3];
        this.col2 = [150, 97, 255, 0.1];

        this.color1 = this.rgba(this.col1);
        this.color2 = this.rgba(this.col2);

        let params = props.params;



        // if(params) {
        //     console.log("DDDDDDDD Difficulty: ", params);
        // }

        // if (params.backgroundSound) {

        //     if (params.backgoundSoundNoloop) {
        //         this.bgPlayer = new Howl({
        //             src: [props.params.backgroundSound],
        //             html5: true,
        //             loop: false,
        //             onend: () => this.ended()
        //         })
        //     } else {
        //         this.bgPlayer = new Howl({
        //             src: [props.params.backgroundSound],
        //             html5: true,
        //             loop: true
        //         })
        //     }
        // }

        // this.players = []


        if (process.env.NODE_ENV === 'development') this.test = true;
        this.simscore = 100;

        this.calc_score = new CalcScore(params);
        this.calc_stats = new CalcSessionStats();

        this.eda = [];
        this.scores = [];

        this.scl = 0;
        this.scr = 0;
        this.start = Date.now();
        this.end = 0;
        this.section = 0;

        this.state = {
            sclScore: 100,
            scrScore: 100,
            showResult: false,
            askFeedback: false,
            textAreaValue: "",
            bImg: params.backgroundImage
        }

        this.aiPlayEnded = this.aiPlayEnded.bind(this);

        // if (params.duration) {
        //     const dur = params.duration;
        //     console.log("Waves2Phase duration: " + dur);

        //     this.timeout = setTimeout(() => {
        //         this.ended();
        //     }, dur);
        // }

    }

    // StrictMode causes double rendering during development!

    async getPrompts(m) {
        // m = "hello";
        let thread_id = this.thread_id;

        let msg = await aiCreateMessage(thread_id, m);
        console.log("OPENAI msg", msg);

        let run = await aiCreateRun(thread_id);
        console.log("OPENAI run.status", run.status);

        let respOK = false;
        let para = null;
        let imgp = null;

        while (!respOK) {

            while (run.status === "queued" || run.status === "in_progress") {
                await sleep(1);
                run = await aiRetrieveRun(thread_id, run.id);
                console.log("OPENAI run.status", run.status);
            }
            console.log("OPENAI run.status", run.status);

            if (run.status === "failed") {
                console.log("OPENAI RUN FAILED ----------------------------------------------------------- ", run);

                await sleep(1);

                console.log("OPENAI RECREATING RUN");
                let run = await aiCreateRun(thread_id);
                console.log("OPENAI run.status", run.status);
                continue;
        

            }

            if (run.status === "requires_action") {
                console.log("OPENAI requires_action", run);

                let args = JSON.parse(run.required_action.submit_tool_outputs.tool_calls[0].function.arguments);

                para = args['paragraph']
                imgp = args['imagePrompt']

                console.log("OPENAI para", para);
                console.log("OPENAI imgp", imgp);
        

                break;
            }

            console.log("OPENAI Should not be here!", run.status);

            this.ended();
            break;

            // should not be here, remove last messages
            if (run.status === 'completed') {
                msg = await aiGetMessages(thread_id);
                console.log("OPENAI msg: ", msg);

                console.log("OPENAI retry ========== ");

                msg = await aiCreateMessage(thread_id, "Recreate your last response.");
                console.log("OPENAI resp", msg);

                run = await aiCreateRun(thread_id);
                console.log("OPENAI run", run.status);

            }

        }

        // para = "Miles beneath the ocean's surface, Dr. Eleanor plunged into the abyss in her submersible, a vessel made for the unknown, for the deep sea that held secrets untold. Every descent was both a challenge and a marvel, her heartbeat syncopating with the beep of the sonar. The dark waters pressed in on the small vessel, a reminder of the crushing depths outside -- yet it was within this confined space that Eleanor sought the wonders that most would never see. Bound by her curiosity and her conviction to uncover the mysteries of the deep, each creak of the submersible's hull was a testament to human tenacity in the face of the vast, unwelcoming sea."
        // imgp = "The interior of a small, modern submersible with ambient, cool-toned lighting, highlighting the confined space within. A lone silhouette of a person can be seen seated, gazing out at the deep dark blue that surrounds the vessel. The atmosphere should convey a sense of solitude, isolation and slight unease, yet tinged with a spark of curiosity and the thrill of the unknown."


        let tool_call_id = run.required_action.submit_tool_outputs.tool_calls[0].id;
        let output = '{success: "true"}';

        let resp = await aiSubmitToolOutputs(thread_id, run.id, tool_call_id, output);
        console.log("OPENAI aiSubmitToolOutputs response", resp);

        run = await aiRetrieveRun(thread_id, run.id);
        while (run.status === "queued" || run.status === "in_progress") {
            await sleep(1);
            run = await aiRetrieveRun(thread_id, run.id);
            console.log("OPENAI run.status", run.status);
        }
        console.log("OPENAI final run.status", run.status);

        this.para = para;
        this.imgp = imgp;


        let messages = await aiGetMessages(thread_id);
        console.log("OPENAI MESSAGES", messages);
        for(let m of messages.data) {
            for(let c of m.content) {
                console.log("OPENAI MSG --> ", c.text.value);
            }
        }

        // let textResp = msg.body.data[msg.body.data.length - 1].content[0].text.value;
        // console.log("OPENAI textResp: ", textResp);
    }

    async getAi(m) {
        console.log("OPENAI getAi START", m);

        if(!this.thread_id) this.thread_id = await aiCreateThread();

        await this.getPrompts(m);

        //TEST
        // let t1 = aiCreateImage(this.imgp);
        // let t2 = aiCreateSpeech(this.para);
        // [this.aiImg, this.aiSpeech] = await Promise.all([t1, t2]);

        console.log("OPENAI getAi READY");

        this.aiReady = true;
    }

    async getSpeech(para) {
        //TEST IMAGE
        // let para = "Miles beneath the ocean's surface, Dr. Eleanor plunged into the abyss in her submersible, a vessel made for the unknown, for the deep sea that held secrets untold. Every descent was both a challenge and a marvel, her heartbeat syncopating with the beep of the sonar. The dark waters pressed in on the small vessel, a reminder of the crushing depths outside -- yet it was within this confined space that Eleanor sought the wonders that most would never see. Bound by her curiosity and her conviction to uncover the mysteries of the deep, each creak of the submersible's hull was a testament to human tenacity in the face of the vast, unwelcoming sea."
        // let imgp = "The interior of a small, modern submersible with ambient, cool-toned lighting, highlighting the confined space within. A lone silhouette of a person can be seen seated, gazing out at the deep dark blue that surrounds the vessel. The atmosphere should convey a sense of solitude, isolation and slight unease, yet tinged with a spark of curiosity and the thrill of the unknown."

        let resp = await aiCreateSpeech(para);
        // console.log("OPENAI voice resp", resp);

        // let b = Buffer.from(resp.data);

        this.mp3 = `data:audio/mp3;base64,${resp}`;
        // console.log("OPENAI img resp", img);
    }

    componentDidMount() {

        // console.log("mounted");
        // if (this.bgPlayer) this.bgPlayer.play();

        // this.ai();

    }

    mutePlayers() {
        if (this.player) this.player.stop();

    }

    componentWillUnmount() {
        console.log("Waves2Phase componentWillUnmount()");

        this.mutePlayers();

    }


    finished() {
        console.log("Waves2Phase finish()");
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);


    }

    async save() {
        this.setState({ saving: true });


        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            feedback: this.state.textAreaValue,

        };

        if (this.objData) {
            let creatureId = await this.props.storeCreature_cb(this.objData.type, this.objData.data, this.objData.img);
            console.log("**************************** Creature ID:", creatureId);

            sessionData["creature"] = {
                type: this.objData.type,
                data: this.objData.data,
                creatureId: creatureId
            }
        }

        if (this.disturbData) sessionData.disturbData = this.disturbData;

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }


    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        this.mutePlayers();
        // clearTimeout(this.timeout);

        console.log("ended");

        this.setState({ askFeedback: true, ended: true });
        console.log("Ended");


    }


    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }

        let ts = Date.now();

        if (!this.lastts) {
            this.lastts = ts;
            return;
        }

        this.eda.push([ts, gsr])

        let [sl, sr] = this.calc_score.calc_one(ts, gsr);

        if (this.test) {
            sl = this.simscore;
            sr = sl;

            // console.log("SIMSCORE", this.simscore);
        }

        this.calc_stats.calc_one(ts, sl, sr);
        this.scores.push([ts, sl, sr]);

        this.setState({ sclScore: sl, scrScore: sr });


        if (!this.aiPlaying) {

            if ((!this.aiRunning)) {

                console.log("OPENAI +++++++++++ new section ", this.aiRunning, this.aiPlaying, this.aiReady);

                // let topic = "film noir"
                // let topic = "star wars"
                let topic = "sci-fi";

                // let stressor = "heights"
                // let stressor = "claustrophobia"
                let stressor = "heavy traffic";

                let m = null;
                if(this.section === 0) {
                    m = `Your client has a stressor is "${stressor}". Tell the story from a first person perspective. 
                    The topic of the story: "${topic}". You have to call the function <story> with the paragraph and the image prompt. 
                    Start the story in a neutral stress level.`
                } else if(this.section===1) {
                    m = "Continue and turn the story to increase the stressor. You have to call the function <story> with the paragraph and the image prompt."
                } else if(this.section===2) {
                    m = "Continue and turn the story to increase the stressor.  You have to call the function <story> with the paragraph and the image prompt."
                } else if(this.section===3) {
                    m = "Continue and turn the story to increase the stressor. You have to call the function <story> with the paragraph and the image prompt."
                } else if(this.section===4) {
                    m = "Conclude the story by completely eliminating the stressor. You have to call the function <story> with the paragraph and the image prompt."
                } else {
                    this.ended();
                }

                if(m) {
                    this.aiReady = false;
                    this.getAi(m);
                    this.aiRunning = true;
                }
            } else if (this.aiReady) {
                console.log("OPENAI +++++++++++ start playing ", this.aiRunning, this.aiPlaying, this.aiReady);


                this.aiRunning = false;
                this.aiPlaying = true;

                this.setState({ bImg: this.aiImg });

                this.player = new Howl({
                    src: [this.aiSpeech],
                    html5: true,
                    onend: () => this.aiPlayEnded()
                })

                this.player.play();

            }
        }



        this.lastts = ts;

    }

    aiPlayEnded() {
        console.log("OPENAI aiPlayEnded");

        this.section +=1;

        this.aiStoryEndedTs = Date.now();
        this.aiPlaying = false;
        this.aiRunning = false;


    }

    lerp = function (a, b, u) {
        return (1 - u) * a + u * b;
    };

    fade = function (start, end, u) {

        var r = Math.round(this.lerp(start[0], end[0], u));
        var g = Math.round(this.lerp(start[1], end[1], u));
        var b = Math.round(this.lerp(start[2], end[2], u));
        var a = this.lerp(start[3], end[3], u);
        return `rgba(${r}, ${g}, ${b}, ${a})`
    };

    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simscore += 5;
    }

    simdec() {
        this.simscore -= 5;
        if (this.simscore < 0) this.simscore = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });

    }

    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    render() {

        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);

            let ses = {
                exercise: this.props.exercise,
                campaign: this.props.campaign,
                data: this.state.sessionData,
                ts: Date.now(),
                device: this.context.deviceName,
                user: this.context.user
            }


            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <WaveResultCard session={ses}></WaveResultCard>

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                </Box>
            );
        }


        let a1 = this.state.sclScore;
        let a2 = this.state.scrScore;

        if (a1 > 100) a1 = 100;
        if (a2 > 100) a2 = 100;

        const style = {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        };

        let showWaves = true;
        if (this.props.params.hideWaves === true) {
            showWaves = false
        }

        return (


            <div>

                {this.state.bImg &&
                    <div style={{ position: 'absolute', left: '0px', top: 0, zIndex: '-10', height: "100%", width: "100%" }}>
                        <img
                            src={this.state.bImg}
                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                        />
                    </div>

                }

                {this.state.backdropLabel &&
                    <Backdrop
                        sx={{
                            p: 1, color: 'black', zIndex: 0, background: '#fff0',
                            textShadow: 'white 0.1em 0.1em 0.2em, white -0.1em -0.1em 0.2em'
                        }}
                        // open={this.state.backdropLabel}
                        open={true}
                        transitionDuration="5000"
                    >
                        {this.state.backdropLabel}
                    </Backdrop>}

                {showWaves &&
                    <Wave fill={this.color1}
                        paused={false}
                        options={{
                            height: 50,
                            amplitude: a1,
                            speed: 0.15,
                            points: 2
                        }}
                        style={{ position: 'absolute', left: '0px', top: '70%', zIndex: '-4', height: "30%" }}
                    ></Wave>
                }
                {showWaves &&
                    <Wave fill={this.color2}
                        paused={false}
                        options={{
                            height: 50,
                            amplitude: a2,
                            speed: 0.15,
                            points: 10
                        }}
                        style={{ position: 'absolute', left: '0px', top: '70%', zIndex: '-3', height: "30%" }}
                    ></Wave>

                }

                <Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab>

                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.siminc.bind(this)} />
                    </Fab>}
                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.simdec.bind(this)} />
                    </Fab>}


            </div>


        );

    };
}

export default Story;