import React from 'react'
import Wave from 'react-wavify'
// import wt from 'discrete-wavelets';
import { Howl } from 'howler'
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';

import Backdrop from '@mui/material/Backdrop';
// import BackdropUnstyled from '@mui/base/BackdropUnstyled';
import Fab from '@mui/material/Fab';
// import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CircularProgress, Typography } from '@mui/material';

import NavigationIcon from '@mui/icons-material/Navigation';
import WaveResultCard from '../WaveResultCard';
import TextareaAutosize from '@mui/material/TextareaAutosize';

// import ScoreBox from '../ScoreBox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { CardContent } from '@mui/material';

// import { session } from 'passport';
import { MyContext } from '../MyContext';
import GetImages from '../GetImages';

class ShrinkImage extends React.Component {
    static contextType = MyContext;

    constructor(props, context) {
        super(props);

        console.log("ShrinkImage constructor", props);

        this.canvas = React.createRef()

        this.col1 = [178, 137, 239, 0.4];
        this.col2 = [150, 97, 255, 0.2];

        this.color1 = this.rgba(this.col1);
        this.color2 = this.rgba(this.col2);

        this.shrinkSize = 100;

        this.disturbData = [];

        let params = props.params;

        if (params.backgroundSound) {
            this.bgPlayer = new Howl({
                src: [props.params.backgroundSound],
                html5: true,
                loop: true
            })
        }

        this.decoded_images = [];

        this.players = []
        this.fired = []

        this.imgId = this.bgImgId = -1;


        if (process.env.NODE_ENV === 'development') this.test = true;
        this.simscore = 100;

        this.decr_ended_ts = false;


        this.calc_score = new CalcScore(params);
        this.calc_stats = new CalcSessionStats();


        if ("disturb" in params) {
            for (let i = 0; i < params.disturb.length; i++) {

                let loop = false;
                if ("loop" in params.disturb[i]) {
                    loop = params.disturb[i].loop;
                }

                let volume = 1;
                if ("volume" in params.disturb[i]) {
                    volume = params.disturb[i].volume;
                }

                console.log("loop = ", loop);

                let player = new Howl({
                    src: [params.disturb[i].sound],
                    html5: true,
                    loop: loop,
                    volume: volume
                })

                this.players.push(player);
                this.fired.push(false);
            }
        }



        this.eda = [];

        this.scores = [];

        this.sclResult = 0;
        this.scrResult = 0;

        this.n = 0;

        this.image_id = 0;


        this.t = 10;
        this.scl = 0;
        this.scr = 0;
        this.end = 0;

        this.events = [];

        if (!params.storage_key) {
            console.error('Storage key not set');
            return;
        }

        this.storage_key = params.storage_key + '_' + context.user;
        let images = localStorage.getItem(this.storage_key);
        if (images) images = JSON.parse(images);
        else images = [];

        console.log('Images in browser storage:', images.length);



        this.state = {
            sclScore: 100,
            scrScore: 100,
            showResult: false,
            askFeedback: false,
            textAreaValue: "",
            bImg: params.backgroundImage,
            animalType: "",
            animalTypeSelected: false,

            images: images,
            imagesConfirmed: false
        }

        if (params.duration) {
            const dur = params.duration;
            console.log("Waves2Phase duration: " + dur);

            this.timeout = setTimeout(() => {
                this.ended();
            }, dur);
        }

    }

    async componentDidMount() {

        console.log("mounted");
        if (this.bgPlayer) this.bgPlayer.play();





    }

    mutePlayers() {
        if (this.bgPlayer) this.bgPlayer.stop();

        for (let i = 0; i < this.players.length; i++) {
            this.players[i].stop();
        }

    }

    componentWillUnmount() {
        console.log("ImageHabituation componentWillUnmount()");

        this.mutePlayers();

    }


    finished() {
        console.log("ImageHabituation finish()");
        // on finish, we do not send data, because we already called store_cb in "ended"
        this.props.finished_cb({}, false);


    }

    async save() {
        this.setState({ saving: true });

        let sessionData = {
            eda: this.eda,
            scores: this.scores,
            events: this.events,
            feedback: this.state.textAreaValue,
        };

        if (this.disturbData) sessionData.disturbData = this.disturbData;

        let sessionId = await this.props.store_cb(sessionData);

        console.log("**************************** Session ID:", sessionId);

        //Now read back
        // await fetch

        this.setState({ showResult: true, askFeedback: false, sessionData: sessionData, saving: false });

    }


    async ended() {
        if (this.endedCalled) return;
        this.endedCalled = true;

        this.mutePlayers();
        clearTimeout(this.timeout);

        this.setState({ askFeedback: true, ended: true });
        console.log("Ended");


    }

    drawSubl() {
        console.log("drawSubl");

        let img = this.decoded_images[this.image_id]        

        let canvas = this.canvas.current;

        let scale1 = window.innerHeight / 480;
        let scale2 = window.innerWidth / 640;

        let scale = scale2;
        if (scale2 > scale1) scale = scale1;

        // update canvas size
        this.width = canvas.width = 640;
        this.height = canvas.height = 480;
        this.ctx = canvas.getContext('2d')


        canvas.style.height = Math.floor(canvas.height * scale) + "px";
        canvas.style.width = Math.floor(canvas.width * scale) + "px";

        this.ctx.clearRect(0, 0, canvas.width, canvas.height);


        let s = this.shrinkSize / 100.0;


        let tx = this.width / 2 - img.width / 2 * s;
        let ty = this.height / 2 - img.height / 2 * s;

        // this.ctx.fillStyle = "#FF0000";
        // this.ctx.fillRect(0, 0, this.width, this.height);

        this.ctx.drawImage(img, 0, 0, img.width, img.height, tx, ty, img.width * s, img.height * s);

        // // Add fog effect to the image
        // this.ctx.globalAlpha = 0.5;
        // this.ctx.fillStyle = "rgba(255, 255, 255, 0.5)";
        // this.ctx.fillRect(0, 0, this.width, this.height);
        // this.ctx.globalAlpha = 1.0;

        // // add a shattered effect to the image
        // this.ctx.globalCompositeOperation = "destination-out";
        // this.ctx.lineWidth = 10;
        // this.ctx.beginPath();
        // this.ctx.moveTo(0, 0);
        // this.ctx.lineTo(this.width, this.height);
        // this.ctx.stroke();
        // this.ctx.closePath();
        // this.ctx.globalCompositeOperation = "source-over";



    }


    onEda(gsr, acc) {
        if (this.state.ended) {
            return;
        }

        if(this.state.imagesConfirmed == false) {
            return;
        }

        console.log("onEda", gsr, acc);

        let ts = Date.now();

        if (!this.lastts) {
            this.lastts = ts;
            return;
        }

        if (!this.lastSubl) this.lastSubl = ts;

        if (this.decr_ended_ts && ts - this.lastSubl > 100) {
            //if(ts - this.lastSubl > 10000) {

            // console.log("====================== DRAW IMAGE");
            // window.requestAnimationFrame(this.drawSubl.bind(this));
            this.drawSubl();

            if(this.events.length == 0) {
                let now = Date.now();
                this.events.push([now, ""+(this.events.length+1)]);
            }

            this.lastSubl = ts;
        }

        this.eda.push([ts, gsr])

        let [sl, sr] = this.calc_score.calc_one(ts, gsr);

        if (this.test) {
            sl = this.simscore;
            sr = sl;

            console.log("SIMSCORE", this.simscore);
        }

        this.calc_stats.calc_one(ts, sl, sr);
        this.scores.push([ts, sl, sr]);

        this.setState({ sclScore: sl, scrScore: sr });


        let score = Math.max(sl, sr);

        // when we passed the decr state ====================
        if (this.calc_stats.decr) {
            if (!this.decr_ended_ts) this.decr_ended_ts = ts;

            let a = this.props.params.shrink_speed;

            if (score < 50) {
                this.shrinkSize -= a;
            }

            if (this.shrinkSize > 100) this.shrinkSize = 100;
            if (this.shrinkSize < 30) {
                this.shrinkSize = 100;

                this.image_id += 1;
                if(this.image_id >= this.decoded_images.length) {

                    this.ended();
                    return;
                }

                let now = Date.now();
                this.events.push([now, ""+(this.events.length+1)]);
    
            }


        }

        this.lastts = ts;

    }

    async confirmClicked() {
        this.setState({ imagesConfirmed: true });

        for (let i = 0; i < this.state.images.length; i++) {
            let img = new Image();
            img.src = this.state.images[i];
            await img.decode();

            this.decoded_images.push(img);
        }
    }

    rgba(c) {
        return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
    }

    siminc() {
        this.simscore += 5;
    }

    simdec() {
        this.simscore -= 5;
        if (this.simscore < 0) this.simscore = 0;
    }

    handleTextChange(event) {
        this.setState({ textAreaValue: event.target.value });
    }


    async feedback() {
        await this.save();
        this.setState({ askFeedback: false });
    }

    render() {


        if (this.state.saving) {
            return (
                <div>
                    <p>Saving...</p>
                    <CircularProgress />
                </div>
            );
        }


        if (!this.state.imagesConfirmed) {

            let disabled = false;
            if (this.state.images.length < 5) {
                disabled = true;
            }


            return (
                <div>
                    <Typography variant="h5">
                        Your images
                    </Typography>
                    <GetImages storage_key={this.storage_key} onImagesSet={(images) => this.setState({ images: images })}></GetImages>
                    <br></br>
                    <Button disabled={disabled} variant='contained' color='primary' onClick={() => this.confirmClicked()}>Confirm Images</Button>
                    <span> </span>
                    <Button variant='contained' color='primary' onClick={() => this.context.cancel()}>Cancel</Button>
                    {this.state.images.length < 5 && <p>Add at least 5 images ({this.state.images.length})</p>}

                </div>
            )
        }

        if (this.state.askFeedback) {
            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <br></br>

                    <Typography>
                        If you wish, you can enter your thoughts here:
                    </Typography>

                    <br></br>

                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={5}

                        //placeholder="If you wish, you can enter your thoughts here..."
                        value={this.state.textAreaValue}
                        onChange={this.handleTextChange.bind(this)}
                        style={{ width: '90%' }}
                    />

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

                </Box>
            );
        }

        if (this.state.showResult) {

            //let final_score = this.calc_score.calc_final_score(this.scores);


            //let total = Math.floor(this.state.final_score.total);

            //let rank = this.calcRank(this.state.hist, total);

            //console.log("Show RESULT", this.state.sessionData);

            let ses = {
                exercise: this.props.exercise,
                campaign: this.props.campaign,
                data: this.state.sessionData,
                ts: Date.now(),
                device: this.context.deviceName,
                user: this.context.user
            }


            return (
                <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

                    <WaveResultCard session={ses}></WaveResultCard>

                    <br></br>

                    <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.finished.bind(this)}>SAVE SESSION</Button>

                </Box>
            );
        }


        let a1 = this.state.sclScore;
        let a2 = this.state.scrScore;

        if (a1 > 100) a1 = 100;
        if (a2 > 100) a2 = 100;

        const style = {
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
        };

        let h = "100%";
        h = "0";

        return (


            <div>

                {this.state.bImg &&
                    <div style={{ position: 'absolute', left: '0px', top: 0, zIndex: '-10', height: "100%", width: "100%" }}>
                        <img
                            src={this.state.bImg}
                            style={{ height: "100%", width: "100%", objectFit: "cover" }}
                        />
                    </div>

                }

                {/* <div>
                    <div style={{
                        position: "fixed",
                        zIndex: -5, width: "100%", top: 0, left: 0
                    }}>
                        <div style={{
                            marginLeft: "auto", marginRight: "auto"
                        }}>
                            <canvas ref={this.canvas}>
                                <p>Add suitable fallback here.</p>
                            </canvas>
                        </div>
                    </div>
                </div> */}

                <div>
                    <div style={{
                        position: 'absolute', left: '0px', top: 0, zIndex: '-2', height: "100%", width: "100%"
                    }}>
                        <div style={{
                            height: "100%", width: "100%", objectFit: "cover"
                        }}>
                            <canvas ref={this.canvas}>
                                <p>Add suitable fallback here.</p>
                            </canvas>
                        </div>
                    </div>
                </div>


                {this.state.backdropLabel &&
                    <Backdrop
                        sx={{
                            p: 1, color: 'black', zIndex: 0, background: '#fff0',
                            textShadow: 'white 0.1em 0.1em 0.2em, white -0.1em -0.1em 0.2em'
                        }}
                        // open={this.state.backdropLabel}
                        open={true}
                        transitionDuration="5000"
                    >
                        {this.state.backdropLabel}
                    </Backdrop>}

                <Wave fill={this.color1}
                    paused={false}
                    options={{
                        height: 50,
                        amplitude: a1,
                        speed: 0.15,
                        points: 2
                    }}
                    style={{ position: 'absolute', left: '0px', top: '70%', zIndex: '1', height: "30%" }}
                ></Wave>

                <Wave fill={this.color2}
                    paused={false}
                    options={{
                        height: 50,
                        amplitude: a2,
                        speed: 0.15,
                        points: 10
                    }}
                    style={{ position: 'absolute', left: '0px', top: '70%', zIndex: '2', height: "30%" }}
                ></Wave>

                <Fab variant="contained" color="primary" >
                    <CheckCircleIcon onClick={this.ended.bind(this)} />
                </Fab>

                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.siminc.bind(this)} />
                    </Fab>}
                {this.test &&
                    <Fab variant="contained" color="primary" >
                        <NavigationIcon onClick={this.simdec.bind(this)} />
                    </Fab>}


            </div>


        );

    };
}

export default ShrinkImage;