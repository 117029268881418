import './App.css';

import React, { Component } from 'react';

import 'chartjs-plugin-annotation';

// import exercise_data from './scripts/exercise_data.js';
import campaign_data from './scripts/campaign_data.js';

import { MyContext } from './MyContext.js';

import { Routes, Route, Navigate } from "react-router-dom";

import Login from './Login.jsx';
import ResultSelector from './ResultSelector.js';
import PupilSelector from './PupilSelector.js';
import NavigateBar from './NavigateBar.jsx';
import Register from './Register.jsx';
import ManageUser from './pages/ManageUser.jsx';
// import ManageCourses from './pages/ManageCourses.jsx';
import { Prompt } from "react-router-dom";

import MySessions from './pages/MySessions.jsx';
import Exercise from './pages/Exercise';

import Dashboard from './pages/Dashboard.jsx';
import Campaigns from './pages/Campaigns.jsx';
import HomePage from './pages/HomePage';
import GDPR from './GDPR';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { calcWeeklyCTW } from './scripts/calc_weekly_ctw';
import { CalcScore } from './scripts/calc_score';
import Flower from './pages/Flower';
import Scenery from './pages/Scenery';
import Backdrop from '@mui/material/Backdrop';
import Ota from './pages/Ota';

// import { withSnackbar } from 'notistack';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

import { findCampHack } from './scripts/campaign_data';

import { calcDifficultyFromParams } from './scripts/calc_difficulty';
import { calcParamsFromDifficulty } from './scripts/calc_difficulty';
import GroupSessions from './pages/GroupSessions.jsx';
import ForceReload from './ForceReload.jsx';
import GroupExerciseControl from './pages/GroupExerciseControl.jsx';
import GroupExerciseClient from './pages/GroupExerciseClient.jsx';
import CreateRegistrationCode from './CreateRegistrationCode.jsx';
import VerifyEmail from './VerifyEmail.jsx';
import ForgotPassword from './ForgotPassword.jsx';
import ResetPassword from './ResetPassword.jsx';
import CreateMentee from './CreateMentee.jsx';
import MentorDashboard from './MentorDashboard.jsx';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    type: 'light',
    primary: {
      main: '#6831da',
      darker: '#6831da',
    },
    secondary: {
      main: '#f50057',
    },
  },
});


class App extends Component {
  constructor(props) {
    super(props)

    console.log("++++++++++++++++++ APP Constructor", process.env.NODE_ENV);

    this.tabId = Math.random().toString(36).substr(2, 9);
    console.log("TABID", this.tabId);

    // console.log(navigator);
    // console.log("OS", navigator.userAgentData.platform);

    // this.exercise_data = exercise_data();
    this.campaign_data = campaign_data();


    this.state = {
      loading: true,
      authenticated: false,
      user: "",
      isRoot: false,
      lang: "en",
      connected: false,
      exerciseSelected: false,
      campaignSelected: false,
      deviceName: false,
      bat: false,
      firmware: false,
      sessions: false,
      showExerciseSelector: false,
      mentorView: false,
      filterDate: null,
      upgradeDialogOpen: false,
      latestFirmware: '',
    }

    this.autoReconnect = false;

    this.netStatus = true;

    this.edaRef = React.createRef();

    this.handleLogin = this.handleLogin.bind(this);
    this.checkToken = this.checkToken.bind(this);
    this.enableBle = this.enableBle.bind(this);
    this.onDisconnected = this.onDisconnected.bind(this);
    this.handleNotifications = this.handleNotifications.bind(this);
    this.handleAccNotifications = this.handleAccNotifications.bind(this);
    this.handleMemNotifications = this.handleMemNotifications.bind(this);

    this.openDeviceSelectorClicked = this.openDeviceSelectorClicked.bind(this);
    this.networkConnected = this.networkConnected.bind(this);
    this.networkDisconnected = this.networkDisconnected.bind(this);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);

    // this.setDeviceData = this.setDeviceData.bind(this);


    this.ConnectComponent = this.ConnectComponent.bind(this);

    window.addEventListener("online", this.networkConnected);

    window.addEventListener("offline", this.networkDisconnected);

    window.addEventListener('beforeunload', this.handleBeforeUnload.bind(this));
    window.addEventListener('popstate', this.handlePopState.bind(this));

    this.allowNavigation = true;

  }

  handleBeforeUnload = (event) => {
    console.log("handleBeforeUnload", event);
    // Block navigation
    if (this.allowNavigation == false) {
      event.preventDefault();
      event.returnValue = ''; // Required for Chrome compatibility
    }
  };

  handlePopState = (event) => {
    console.log("handlePopState");
    // Prevent navigation via back button
    // navigate(window.location.pathname); // Redirect to the current path

    const { location, history } = this.props;
    history.push(location.pathname); // Redirect back to the current path
  };


  networkConnected() {
    if (this.netStatus == false) {
      console.log("I am connected to the internet");
      enqueueSnackbar('You are connected to the Internet.', { variant: 'success' });
    }

    this.netStatus = true;
  }

  networkDisconnected() {
    enqueueSnackbar('You are disconnected from the Internet.', { variant: 'error' });
    this.netStatus = false;
  }

  purgeOldDailyFirstLevelRecords() {
    const today = new Date().toISOString().slice(0, 10);
    // Loop through all keys in localStorage
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      // Only purge keys that start with the designated prefix
      if (key && key.startsWith("dailyFirstLevel_")) {
        let storedData = localStorage.getItem(key);
        if (storedData) {
          storedData = JSON.parse(storedData);
          if (storedData.date !== today) {
            localStorage.removeItem(key);
            console.log(`Purged old record: ${key}`);
          } else {
            console.log(`Kept record: ${key}`);
          }
        }
      }
    }
  }

  async componentDidMount() {
    this.checkToken();

    // Set up BroadcastChannel to listen for auth events
    const authChannel = new BroadcastChannel('auth');
    authChannel.onmessage = (event) => {
      console.log('Auth event received:', this.state, event.data, this.state.user, this.tabId);

      // Ignore messages sent by this tab
      if (event.data.sender === this.tabId) {
        console.log('Auth event: Ignoring event from self');
        return;
      }

      if (event.data.user === false || event.data.user !== this.state.user) {
        console.log('Auth event received:', event.data);
        // Force a reload to update the session state across tabs

        this.setState({ authenticated: false, isRoot: false });
        this.userRecord = null;

        // window.location.reload();
        // this.checkToken();
      }
    };

    this.purgeOldDailyFirstLevelRecords();
  }

  async checkToken() {

    //console.log("CHECK TOKEN================");

    let resp = await fetch('/checkToken', { method: 'POST' })

    console.log("CheckToken resp", resp);

    if (resp.status === 200) {
      let u = await resp.json();
      this.handleLogin(u);

      return;
    }

    this.setState({ authenticated: false, loading: false });
  }



  async handleLogin(u) {
    console.log("handleLogin: Successful authenticated: " + JSON.stringify(u));

    this.allowNavigation = false;

    //let su = ["root", "a@com", "anna", "krisz", "sandor", "luca"]
    if (u.isRoot === true) {
      this.setState({ isRoot: true });

      console.log("********************************* I am root");

    } else {
      this.setState({ isRoot: false });
      //console.log("********************************* I am NOT root:", u.user);
    }


    this.userRecord = u;

    this.setState({
      authenticated: true,
      user: u.user,
      page: 'home',
      mentorView: false
    });

    // Broadcast a login event so other tabs know the user changed
    const authChannel = new BroadcastChannel('auth');
    authChannel.postMessage({ type: 'login', user: u.user, sender: this.tabId });

    //await this.saveGDPR();
    this.setState({ loading: true });
    await this.loadGDPR();
    await this.loadData();

  }


  async storeSession(sessionRecord) {

    console.log("APP storeSession: ", sessionRecord);

    const resp = await fetch('/api/store', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sessionRecord)
    })

    let d = await resp.json();

    console.log("APP storeSession -------------------------------------- storResult: ", d);

    let sessionId = d.id;

    // console.log("GGGG", this.context.sessions);
    // reload!

    this.addSession(sessionRecord);

    this.reloadNeeded(false);

    return sessionId;

  }

  calcDifficulty(sessions) {
    console.log("CALC DIFFICULTY");

    let diffs = [];

    console.log("=== CALC DIFFICULTY number of sessions", sessions.length);

    // check if sessions are in right order:
    if(sessions.length > 2 && sessions[0].ts > sessions[1].ts) console.error("== DIFFICULTY sessions are not in order");

    // oldest records first, so we have to go in reverse!

    // for (let s of sessions) {
    for (let i = sessions.length - 1; i >= 0; i--) {
      let s = sessions[i];

      if (s.type === "WaveUpdown") {
        console.log("=== DIFF WAVEUPDOWN is not used to adjust difficulty, skipping...", s);
        continue;
      }

      if (!s.data.scores) {
        console.log("=== DIFF NO SCORES", s);
        continue;
      }
      let dur = s.data.scores.length * 4;
      if (dur < 20) {
        console.log("=== DIFF SHORT", s);
        continue;
      }

      console.log("=== DIFF S", new Date(s.ts).toLocaleDateString(), s);

      if (!s.data.difficulty_params) continue;

      console.log("=== DIFF PARAMS", s.data.difficulty_params);

      let [nWave, diff] = calcDifficultyFromParams(s.data.difficulty_params);
      console.log("DIFF nwave", nWave, "difficulty calculated from params", diff);

      let success = false;
      let ratio = 0;
      let ts_dur = 0;

      for (let [ts, s1, s2] of s.data.scores) {
        let min = 99999;

        if (nWave == 1) {
          min = Math.min(s1, s2);
        } else {
          min = Math.max(s1, s2);
        }

        if (min <= 0) {
          success = true;

          ts_dur = ts - s.data.scores[0][0];
          let param_dur = s.params.duration;

          ratio = ts_dur / param_dur;

          console.log("DIFF ts_dur", ts_dur, "param_dur", param_dur, "ratio", ratio);
          break;
        };

        // console.log("D", d);
      }

      let diff_ajd = 0;
      if (success) {
        console.log("DIFF SUCCESS ratio", ratio);

        if (ts_dur < 60e3) {
          diff_ajd = 5;
        } else if (ts_dur < 90e3) {
          diff_ajd = 2;
        } else if (ts_dur < 120e3) {
          diff_ajd = 1;
        }

        console.log("DIFF ADJ", diff_ajd);
      } else {
        console.log("DIFF FAIL", nWave, diff);

        diff_ajd = -1;
      }

      let compensated = Math.max(1, diff + diff_ajd);
      console.log("DIFF COMPENSATED", compensated);
      diffs.push(compensated);

      if (diffs.length >= 3) break;
    }

    console.log("DIFFS", diffs);

    // calculate average
    let sum = 0;
    let avgdiff = 1;
    for (let d of diffs) {
      sum += d;
    }

    if (diffs.length > 0) {
      avgdiff = sum / diffs.length;
    }

    console.log("DIFF AVG DIFF", avgdiff);

    return avgdiff;
  }

  checkUsageLimit(sessions, level) {
    console.log("USAGE checkUsageLimit");

    let usageToday = 0;
    for (let s of sessions) {
      // check if the session is from today 
      if (this.isToday(s.ts)) {

        // console.log("USAGE ==== SESSION TODAY", s);

        if (!s.data.eda) continue;
        if (s.data.eda.length < 10) continue;

        let dur = s.data.eda[s.data.eda.length - 1][0] - s.data.eda[0][0];
        usageToday += dur;
      }
    }

    console.log("USAGE", "this.userRecord.limitDailyTime", this.userRecord);

    this.usageLimited = false;
    if (this.userRecord.limitDailyTime && this.userRecord.limitDailyTime > 0) {
      if (usageToday >= this.userRecord.limitDailyTime * 60 * 1000) {
        this.usageLimited = true;

        console.log("USAGE LIMITED USAGE");
      }
    }

    // Next, check the level-up limit for each campaign.
    // Assuming this.state.level is already set as a JSON string of the computed levels:
    let levelLimited = false;
    console.log("USAGE", "user", this.userRecord.user);
    if (level) {
      for (let campaignName in level) {
        if(!this.campaign_data[campaignName].levelUpLimit) continue;

        const currentLevel = level[campaignName].level;
        const key = `dailyFirstLevel_${this.userRecord.user}_${campaignName}`;
        console.log("USAGE", "key", key);
        const today = new Date().toISOString().slice(0, 10);
        let storedData = localStorage.getItem(key);

        if (storedData) {
          storedData = JSON.parse(storedData);
          if (storedData.date !== today) {
            // Reset for new day
            storedData = { date: today, level: currentLevel };
            localStorage.setItem(key, JSON.stringify(storedData));
          } else {
            // Check if the level increased by more than 5 from the starting level today
            if (currentLevel - storedData.level > 5) {
              levelLimited = true;
              console.log(`Level limit reached for ${campaignName}.`);
            }
          }
        } else {
          // No starting record exists yet, so store the current level as the starting point.
          storedData = { date: today, level: currentLevel };
          localStorage.setItem(key, JSON.stringify(storedData));
        }
      }
    }

    if(levelLimited) {
      this.usageLimited = true;
    }

    this.setState({ usageToday: usageToday });
    console.log("USAGE", "session from today", usageToday);

  }

  async loadData(refetch = true) {

    console.log("loadRecords", refetch);
    await this.loadRecords(refetch);

    console.log("loadCampaigns");
    let campaigns = await this.loadCampaigns(this.state.user);
    this.setState({
      campaigns: campaigns,
    });

    let weeklyStats = this.calcStats(this.state.sessions);
    this.setState({ weeklyStats: weeklyStats });

    let difficulty = this.calcDifficulty(this.state.sessions)
    this.setState({ difficulty: difficulty });

    const [level, earnedFeatures] = this.calcLevels(this.state.sessions, difficulty);
    this.earnedFeatures = earnedFeatures;
    this.setState({ "level": JSON.stringify(level) }); // can only pass simple objects not dicts

    this.checkUsageLimit(this.state.sessions, level)

    if (refetch) {
      console.log("loadSceneryImage");
      await this.loadSceneryImage();

      console.log("loadScenery");
      await this.loadScenery();
    }


    console.log("processScenery");
    await this.processScenery();

    this.setState({ loading: false });

    console.log("loadData OK");

  }

  async processScenery() {
    let newfeatures = [];

    // console.log("processScenery features: ", this.features);

    let updatedb = false;

    // check features that correspond to our level and has not beed save into db
    if (this.earnedFeatures) {
      for (let f of this.earnedFeatures) {

        let found = false;
        for (let i in this.features) {
          if (i === f) {
            found = true;
            break;
          }
        }

        if (!found) {
          this.features[f] = { type: 'asset' };
          console.log("Found earned feature", f);
          updatedb = true;
        }

      }
    }

    if (updatedb) await this.saveScenery(this.features);

    // console.log(this.features);

    if (Object.keys(this.features).length > 0) {
      for (let s in this.features) {
        //console.log("processScenery:", s);
        if (!this.features[s].pos) newfeatures.push(s);
      }
    }

    //console.log("new features:", newfeatures);

    this.setState({ newfeatures: newfeatures.length });
  }

  async loadScenery() {
    console.log("BACKEND GET getScenery");

    let resp = await fetch('/api/getmyscenery', {
      method: 'POST',
      // body: JSON.stringify(),
      // headers: {
      //   'Content-Type': 'application/json'
      // }
    })

    let d = await resp.json();

    //console.log("loadScenery", d);

    if (d && d.features) {
      //console.log("MyScenery features", d.features);
      this.features = d.features;
    } else {
      this.features = {};
    }
  }

  async saveScenery(features) {
    if (features.length === 0) return;

    console.log("BACKEND saveScenery", features);

    await fetch('/api/savemyscenery', {
      method: 'POST',
      body: JSON.stringify({
        features: features
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    //let d = await resp();

    //console.log("SAVE MyScenery:", resp);
  }

  async loadSceneryImage() {
    console.log("BACKEND GET loadSceneryImage");

    let resp = await fetch('/api/getmysceneryimage', {
      method: 'POST',
      // body: JSON.stringify(),
      // headers: {
      //   'Content-Type': 'application/json'
      // }
    })

    let d = await resp.json();

    if (d) {
      // console.log("MyScenery image length", d.img.length);

      this.setState({ mySceneryImage: d.img });
    } else {
      this.setState({ mySceneryImage: false });
    }
  }

  async loadGDPR() {
    console.log("BACKEND loadGDPR");

    let resp = await fetch('/api/getgdpr', {
      method: 'POST',
    })

    let d = await resp.json();

    console.log("loadGDPR resp", resp);
    console.log("loadGDPR data", d);

    if (d) {
      this.setState({ gdpr: d.gdpr })

    } else this.setState({ gdpr: false })


  }

  async saveGDPR() {
    console.log("BACKEND saveGDPR");

    await fetch('/api/savegdpr', {
      method: 'POST',
    })

    this.setState({ gdpr: true })


    //let d = await resp.json();    
    //console.log("savegdpr resp", resp);
    //console.log("savegdpr data", d);
  }


  async loadCampaigns(user) {
    console.log("BACKEND loadCampaigns user: " + JSON.stringify(user));

    try {
      const resp = await fetch('/api/getcampaigns', {
        method: 'POST',
        body: JSON.stringify({ user }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      // Check if the response was successful
      if (!resp.ok) {
        throw new Error(`Network response was not ok (status: ${resp.status})`);
      }

      const c = await resp.json();
      return c.campaigns;

    } catch (error) {
      console.error("Error in loadCampaigns:", error);
      // Handle the error appropriately. You might return a fallback value or rethrow the error.
      // For example, returning an empty array:
      return [];
    }
  }

  async fetchRecords(user) {
    console.log("APP fetchRecords for user:", user);

    let resp = await fetch('/api/find', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: user,
      })
    });

    console.log("APP fetchRecords resp", resp);

    let sessions = await resp.json();

    // sort by timestamp
    sessions = sessions.sort((a, b) => a.ts - b.ts);

    // check order
    if(sessions.length > 2 && sessions[0].ts > sessions[1].ts) console.error("== fetchRecords sessions are not in order");


    console.log("APP loadRecords num:", sessions.length, JSON.stringify(sessions).length);

    return sessions;
  }

  async loadRecords(refetch = true) {

    console.log("APP loadRecords for user:", this.state.user, " refetch:", refetch);

    let sessions;

    if (refetch) {
      console.log("loadRecords refetch >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");

      sessions = await this.fetchRecords(this.state.user);

      if(sessions.length > 2 && sessions[0].ts > sessions[1].ts) console.error("== loadRecords sessions are not in order");

      this.setState({ sessions: sessions });

      // localStorage.sessions = sessions;

    } else {
      console.log("loadRecords refetch not done");
      sessions = this.state.sessions;
    }

    // HACK set campaign field for old records
    console.log("Campaign HACK");
    for (let s of sessions) {
      if (s.campaign) continue;

      let campname = findCampHack(s.exercise);
      if (campname == false) continue;

      s.campaign = campname;
    }


    // // TEST RECALULATE SCORES
    // let last_params = false;
    // for (let s of sessions) {
    //   // if (s.data.scores) continue; // ANDRAS

    //   if (!s.data.eda) continue;
    //   if (s.data.eda.length < 10) continue;

    //   let params = null;
    //   if (s.params.l_incr) params = s.params;
    //   if (s.data.difficulty_params) params = s.data.difficulty_params;
    //   else if (last_params) params = last_params;
    //   else {
    //     params = {
    //       l_incr: 1,
    //       l_decr: 1,
    //       r_incr: 1,
    //       r_decr: 1
    //     }
    //   }

    //   last_params = params;

    //   console.log("TEST RECALULATE SCORES params", params, s);

    //   let calc_score = new CalcScore(params);
    //   let scores = calc_score.calc_all_db(s.data.eda);

    //   s.data.scores = scores; // REPLACE!
    // }
  }

  isToday(ts) {
    const date = new Date(ts);
    const today = new Date();

    return (
      date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()
    );
  }

  calcStats(sessions) {

    // let sessions = this.state.sessions;
    let weeklyStats = {}

    // console.log("================= Calc stats", sessions);

    for (let [campname, camp] of Object.entries(this.campaign_data)) {

      if ('levelType' in camp) {
        let weekly = calcWeeklyCTW(sessions, camp.levelType);
        weeklyStats[campname] = weekly;
      }
    }

    return weeklyStats;

  }

  calcLevels(sessions, difficulty) {

    // console.log("================= Calc Levels", sessions);

    let level = {};
    let earnedFeatures = [];

    for (let [campname, camp] of Object.entries(this.campaign_data)) {

      if (camp.levelFn) {

        let [lev, levInfo, extraMsg, earned, next_exercise] = camp.levelFn(sessions, campname, difficulty);

        // console.log("EARNED", campname, earned);

        level[campname] = {
          level: lev,
          levelInfo: levInfo,
          extraMsg: extraMsg,
          next_exercise: next_exercise
        }

        if (earned) {
          if (earnedFeatures.length === 0) earnedFeatures = earned;
          else earnedFeatures.push(...earned);
        }

      } else {
        console.log("No levelFn in campaign", campname);
        level[campname] = {
          level: 100,
          levelInfo: "",
          extraMsg: "",
          next_exercise: false
        }
      }

    }

    console.log("WWW LEVELS", level);

    return [level, earnedFeatures]
  }

  reloadNeeded(refetch = true) {
    console.log("+++++++++++++++++++++++++++++ RELOAD NEEDED");
    this.loadData(refetch);
  }

  disconnectDevice() {
    console.log("++++++++++++++++++++++++++++++++ DISCONNECT");
    if (this.bleDevice) this.bleDevice.gatt.disconnect();
  }

  logout() {
    console.log("Auth event send LOGOUT");
    const authChannel = new BroadcastChannel('auth');
    authChannel.postMessage({ type: 'login', sender: this.tabId, user: false });

    fetch('/logout', { method: 'POST' });
    this.disconnectDevice();
    this.setState({ authenticated: false, isRoot: false });
    this.userRecord = null;

    this.allowNavigation = true;
  }

  login() {
    console.log("Auth event send LOGIn");

    this.setState({ authenticated: false, loginClicked: true });
  }

  enableBle() {
    console.log("App: enableBle ")
    this.setState({ showDeviceSelector: true })

  }

  deviceSelectorCanceled() {
    console.log("deviceSelectorCanceled");
    this.setState({ showDeviceSelector: false, exerciseSelected: false });
  }

  cancel() {
    console.log("cancel");
    this.setState({ exerciseSelected: false });
  }

  async openDeviceSelectorClicked() {
    console.log("enableBle NEW");

    this.autoReconnect = false;

    this.setState({ connected: false, connecting: true });

    this.edaAppService = '43974957-3487-9347-5977-654321987654';
    this.edaOtaService = '8a97f7c0-8506-11e3-baa7-0800200c9a66';
    
    this.gsr_char_uuid = '43789734-9798-3479-8347-983479887878';
    this.keepalive_char_uuid = '43789734-9798-3479-8347-98347988787c';
    this.stat_char_uuid = '43789734-9798-3479-8347-98347988787e';
    this.version_char_uuid = '43789734-9798-3479-8347-98347988787f';
    this.acc_char_uuid = '43789734-9798-3479-8347-983479887879';
    this.addr_char_uuid = '43789734-9798-3479-8347-983479887880';
    this.mem_char_uuid = '43789734-9798-3479-8347-983479887881';
    this.tsync_char_uuid = '43789734-9798-3479-8347-983479887882';
    this.name_char_uuid = '43789734-9798-3479-8347-983479887890';

    let options = {
      filters: [

        { services: [this.edaAppService] },
        { services: [this.edaOtaService] },
        { services: ['669a0c20-0008-a7ba-e311-0685c0f7978a'] }, // this is in reverse order due to bug in OTA firmware

        // xxxxxxxxx This is the format of a 16bit uuid. The actual 16 bits are "7654"
        { services: ['00007654-0000-1000-8000-00805f9b34fb'] }, 

        { namePrefix: '_' },
        { name: '_edaota' },
        { name: 'OTAServiceMgr' },

      ],
      // multiple: true
    }

    //console.log(navigator);
    // if (navigator.userAgentData)
    //   if (navigator.userAgentData.platform === 'macOS') {
    //     options.filters = [
    //       { services: [this.serviceUuid] },
    //       //{name: '5f'}
    //       { namePrefix: '5f' }
    //     ];
    //   }

    //console.log("options:", navigator.userAgentData.platform, options)

    try {
      console.log("Request device...");
      console.log("bt ", await navigator.bluetooth.getAvailability());

      let device = await navigator.bluetooth.requestDevice(options);

      console.log("DEVICE", device);

      this.bleDevice = device;
      this.bleDevice.addEventListener('gattserverdisconnected', (event) => this.onDisconnected(event));
      await this.connect();

    }
    catch (error) {
      console.error("Failed to connect", error);

      enqueueSnackbar('Obi connection error!', { variant: 'error' });

      // CCCC
      this.setState({ exerciseSelected: false, connected: false, connecting: false, showDeviceSelector: false });
      // this.setState({ connected: false, connecting: false, showDeviceSelector: false });

      this.disconnectDevice();
    }
  }

  async connect() {
    console.log("connect")

    if (this.reconnecting) {
      console.log("already connecting, ignoring", this.reconnecting);
      return;
    }
    // this.setState({reconnecting: true});

    console.log('Connect Name: ' + this.bleDevice.name);

    let devname = this.bleDevice.name;

    // Do something with the device.
    let server = await this.bleDevice.gatt.connect();

    // NEW: Check if device is in OTA mode by trying to get the OTA service.
    try {
      const otaService = await server.getPrimaryService(this.edaOtaService);
      console.warn("Device detected in OTA mode. Redirecting to OTA page.");
      this.setState({ page: 'ota', connecting: false });
      return; // Skip normal connection flow.
    } catch (err) {
      // OTA service not available: continue with normal connection.
    }

    console.log('Getting Service...');
    let service = await server.getPrimaryService(this.edaAppService);

    let gsr_char = await service.getCharacteristic(this.gsr_char_uuid);
    console.log('gsr_char UUID:  ' + gsr_char.uuid);

    // console.log('acc_char UUID:  ' + acc_char.uuid);
    let chars = await service.getCharacteristics();
    // console.log("CHARS:", chars);
    for (let c of chars) {
      // console.log("CHECK", c, this, this.acc_char_uuid);
      if (c.uuid == this.acc_char_uuid) {
        console.log("Found ACC char!");
        let acc_char = await service.getCharacteristic(this.acc_char_uuid);

        await acc_char.startNotifications();
        acc_char.addEventListener('characteristicvaluechanged', this.handleAccNotifications);

      }

      if (c.uuid == this.name_char_uuid) {
        console.log("BLE Device supports name");

        this.name_char = await service.getCharacteristic(this.name_char_uuid);
        console.log("BLE name char:", this.name_char.uuid);

        // Check if name characteristic is readable before reading its value
        if (this.name_char.properties && this.name_char.properties.read) {
          let v = await this.name_char.readValue();
          console.log("BLE NAME V=", v);
          devname = new TextDecoder().decode(v);
          console.log("BLE NAME", devname);
        } else {
          console.log("Name characteristic is not readable");
        }
      }

      if (c.uuid == this.keepalive_char_uuid) {
        console.log("Device supports keepalive");

        this.keepalive_char = await service.getCharacteristic(this.keepalive_char_uuid);
        console.log("keepalive char:", this.keepalive_char.uuid);
      }

      if (c.uuid == this.mem_char_uuid) {
        console.log("Device supports mem");

        this.mem_char = await service.getCharacteristic(this.mem_char_uuid);
        console.log("mem char:", this.mem_char.uuid);

        await this.mem_char.startNotifications();
        this.mem_char.addEventListener('characteristicvaluechanged', this.handleMemNotifications);


      }

      if (c.uuid == this.addr_char_uuid) {
        console.log("Device supports addr");

        this.addr_char = await service.getCharacteristic(this.addr_char_uuid);

      }

      if (c.uuid == this.tsync_char_uuid) {
        console.log("Device supports tsync");

        this.tsync_char = await service.getCharacteristic(this.tsync_char_uuid);

        let num = Date.now();

        const arr = new Uint8Array(8);
        for (let i = 0; i < 8; i++)
          arr.set([num / 0x100 ** i], i)

        let v = await this.tsync_char.writeValue(arr);

        
      }


    }

    console.log("BLE final Device name:", devname);
    this.setState({ deviceName: devname });

    // // MEM READ TEST
    // console.log("MMM ====");
    // const aaa = new Uint8Array([1]);
    // let v = await this.addr_char.writeValue(aaa);
    // console.log("MMM addr:",v);




    await gsr_char.startNotifications();

    console.log("X");
    gsr_char.addEventListener('characteristicvaluechanged', this.handleNotifications);

    // try {
    this.stat_char = await service.getCharacteristic(this.stat_char_uuid);
    console.log("stat_char:", this.stat_char.uuid);


    await this.readStatusChar();


    this.version_char = await service.getCharacteristic(this.version_char_uuid);
    console.log("version_char:", this.version_char.uuid);

    let vv = await this.version_char.readValue();

    console.log("VV", vv);

    if (vv.byteLength < 1) {
      console.log("Wrong version resp from Obi");
      enqueueSnackbar('Obi error!', { variant: 'error' });
      this.autoReconnect = false;
      await this.bleDevice.gatt.disconnect();
      //this.bleDevice.disconnect();
      //throw new Error("BAT OR USB CONNECTED");
      return;
    }

    let firmware = "";
    let old_fw = false;

    if (vv.getUint8(0) == 70) {
      // new device
      console.log("NEW DEVICE");

      this.btver = 0;

      for (let i = 0; i < vv.byteLength; i++) {
        let cc = vv.getUint8(i);
        if (cc == 0) break;
        firmware += String.fromCharCode(cc);
      }

      old_fw = false;

    } else {
      this.btver = vv.getUint8(0);
      console.log("XXXXXXXXXXX", vv, "l=", vv.byteLength);
      for (let i = 1; i < vv.byteLength; i++) {
        let cc = vv.getUint8(i);
        console.log("C:", cc);
        firmware += String.fromCharCode(cc);
      }
      console.log("BTVERSION ", this.btver);
      console.log("FIRMWARE ", this.firmware);

      let msec_expected = Date.parse("Sep 20 2023");
      let msec = Date.parse(firmware);
      console.log("msec", msec);
      if (msec < msec_expected) old_fw = true;

    }
    this.firmware = firmware; // Save firmware version for later use

    console.log("FIRMWARE", firmware);

    // NEW: Check firmware upgrade on normal connection
    this.checkFirmwareUpgrade();

    this.reconnecting = false;


    // if (this.bat < 3.5) {
    //   console.log("BAT TOO LOW");
    //   enqueueSnackbar('Obi battery too low.', { variant: 'error' });
    //   // this.setState({ warning: true, warningText: "Battery too low!" });
    //   this.autoReconnect = false;
    //   await this.bleDevice.gatt.disconnect();
    //   //this.bleDevice.disconnect();
    //   return;
    // }

    // if (this.usb === 1) {
    //   console.log("USB Connected");
    //   // this.setState({ warning: true, warningText: "Please disconnect USB cable!" });
    //   enqueueSnackbar('Please disconnect USB cable first!', { variant: 'error' });
    //   this.autoReconnect = false;
    //   await this.bleDevice.gatt.disconnect();
    //   //this.bleDevice.disconnect();
    //   //throw new Error("BAT OR USB CONNECTED");
    //   return;
    // }

    if (old_fw) {
      console.log("Old firmware");
      enqueueSnackbar('Please upgrade Obi!', { variant: 'error' });
      // this.setState({ warning: true, warningText: "Firmware too old! Note: do NOT use Windows or Android apps to upgrade!" });
      this.autoReconnect = false;
      await this.bleDevice.gatt.disconnect();
      //this.bleDevice.disconnect();
      //throw new Error("BAT OR USB CONNECTED");
      return;
    }

    this.setState({ firmware: firmware });

    // New: Update device info on server via the Devices API
    fetch('/api/update-device', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        deviceAddress: this.bleDevice.id,
        deviceName: devname,
        firmware: this.firmware
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log("Device info updated on server:", data);
      })
      .catch(error => {
        console.error("Error updating device info:", error);
      });

    this.setState({ connected: true, connecting: false, showDeviceSelector: false });


    this.autoReconnect = false;

    if (this.keepalive_char) this.keepalliveId = setInterval(this.keepalivefn.bind(this), 3000);


  };

  // NEW: Check firmware upgrade using same API as Ota
  checkFirmwareUpgrade = async () => {
    try {
      const res = await fetch('/api/firmware-list', { method: 'POST' });
      const firmwareList = await res.json(); // assume an array like ["F003.bin", "F005.bin", "F102.bin"]

      console.log("Firmware list from server:", firmwareList);

      if (!Array.isArray(firmwareList) || firmwareList.length === 0) return;
      firmwareList.sort((a, b) => parseInt(a.replace(/[^\d]/g, ''), 10) - parseInt(b.replace(/[^\d]/g, ''), 10));
      const latest = firmwareList[firmwareList.length - 1];
      let deviceVer = parseInt(this.firmware.replace(/[^\d]/g, ''), 10);
      let latestVer = parseInt(latest.replace(/[^\d]/g, ''), 10);
      if (latestVer > deviceVer) {
        this.setState({ upgradeDialogOpen: true, latestFirmware: latest });
      }
    } catch (err) {
      console.error("Firmware check error:", err);
    }
  }

  handleMemNotifications(event) {
    let value = event.target.value;
    console.log("MMM REC", Date.now(), value.getUint8(0));

    // let acc = []

    // for(let i=0; i<16; i++) {
    //   let g = value.getUint8(i)
    //   // console.log(i,g);
    //   acc.push(g);
    // }

    // if (this.edaRef.current) this.edaRef.current.onAcc(acc);

  }

  async readStatusChar() {
    console.log("readStatusChar");

    let v = await this.stat_char.readValue();

    this.bat = (v.getUint8(0) * 256 + v.getUint8(1)) / 100.0;
    this.usb = v.getUint8(2);

    
    console.log("BAT=", this.bat, "USB=", this.usb);

    this.setState({ bat: this.bat });
  }

  async keepalivefn() {

    console.log("KEEPALIVEFN");

    if (!this.keepalive_char) console.log("ERROR device does not have keepalive char");

    const b = new Uint8Array([1]);
    await this.keepalive_char.writeValue(b);

    console.log("KEEPLIVE");

    await this.readStatusChar();

  }

  onDisconnected(event) {

    clearInterval(this.keepalliveId);

    console.log('> Bluetooth Device disconnected NEW ', event);

    enqueueSnackbar('Obi disconnected.', { variant: 'error' });


    if (this.autoReconnect) {
      console.log("Autoreconnect set, connecting again...")
      this.connect();
    } else {
      console.log("Autoreconnect NOT set")
      // this.context.setDeviceName(false, false, false);
      // this.setDeviceData(false, false, false);
      // this.setState({ exerciseSelected: false, deviceName: false, connected: false, connecting: false, showDeviceSelector: false })
      this.setState({ deviceName: false, connected: false, connecting: false, showDeviceSelector: false })

    }
  }

  handleAccNotifications(event) {
    let value = event.target.value;
    // console.log("Acc", event, value);

    let acc = []

    // console.log("value length", value.byteLength);

    for (let i = 0; i < value.byteLength; i++) {
      let g = value.getUint8(i)
      // console.log(i,g);
      acc.push(g);
    }

    if (this.edaRef.current) this.edaRef.current.onAcc(acc);

  }

  handleNotifications(event) {
    // console.log("handleNotifications ")

    let value = event.target.value;

    let g = value.getUint32(0)

    // console.log("Not raw ", g, g.toString(16));

    let acc = (g >> 24) & 0xff;
    g &= 0xffffff;

    // console.log("Notification XXX ", g, acc);

    if (!this.state.eda) this.setState({ eda: true });

    if (this.edaRef.current) this.edaRef.current.onEda(g, acc);

  }

  // Add new onConnectClicked handler
  onConnectClicked() {
    console.log("onConnectClicked triggered");
    this.openDeviceSelectorClicked();
  }

  ConnectComponent() {
    return (
      <div>
        <p>
          Please attach the electrodes and place device on your fingers.
        </p>
        <p>
          Then press connect below, select your device and click 'Pair'
        </p>
        <Button sx={{ m: 2 }} variant='outlined' color='primary' onClick={this.deviceSelectorCanceled.bind(this)}>CANCEL</Button>
        {/* Updated to use new onConnectClicked handler */}
        <Button sx={{ m: 2 }} variant='contained' color='primary' onClick={this.onConnectClicked.bind(this)}>CONNECT OBI</Button>
      </div>
    )
  }

  exerciseSelectedCb(campaign, exercise, intructorView = false) {

    console.log("APP: exerciseSelectedCb ____________________________", campaign, exercise);

    if (exercise === null) {
      console.error("exerciseSelectedCb: exercise is null");
    }

    if (intructorView) {
      console.log("Intructor view");

      // set poll timer
      if (this.pollTimer) {
        clearInterval(this.pollTimer);
      }

      this.pollTimer = setInterval(this.pollExercise.bind(this), 1000);


    }

    this.setState({ intructorView: intructorView, campaignSelected: campaign, exerciseSelected: exercise, exerciseKey: Date.now() });


  }

  exerciseFinishedCb() {
    console.log("exerciseFinishedCb");
    this.setState({ campaignSelected: false, exerciseSelected: false })
  }

  addSession(s) {
    console.log("GGGG Add session", s);

    // this.state.sessions.unshift(s);

    // add to the end
    this.state.sessions.push(s);


    // localStorage.sessions = this.state.sessions;

    this.setState({ sessions: this.state.sessions });


  }

  showExerciseSelector() {
    console.log("APP showExerciseSelector");
    this.setState({ showExerciseSelector: true });
  }

  setPage(page, pageParam=false) {
    console.log("APP setPage", page);
    this.setState({ page: page, pageParam: pageParam });
  }

  setMentorView(mentor) {
    console.log("APP setMentorView", mentor);
    this.setState({ mentorView: mentor });
  }

  checkReloadKey() {
    if (this.lastReloadCkeck === undefined) {
      this.lastReloadCkeck = Date.now();
    }

    if (Date.now() - this.lastReloadCkeck < 5000) {
      return;
    }
    this.lastReloadCkeck = Date.now();

    fetch('/api/get_reload_key', { method: 'POST' })
      .then(response => response.json())
      .then(data => {
        console.log("RELOAD KEY", data.key, "STATEKEY", this.key);

        if (this.key === undefined) {
          this.key = data.key;
        } else if (data.key !== this.key) {

          console.log("RELOAD NEEDED!! !");
          window.removeEventListener('beforeunload', this.handleBeforeUnload);

          window.location.reload();




        }
      });

  }

  // Add new method to update campaignSelected
  setCampaign(camp) {
    this.setState({ campaignSelected: camp });
  }

  // New: setFilterDate method for updating context
  setFilterDate(filterDate) {
    console.log("APP setFilterDate", filterDate);
    this.setState({ filterDate });
  }

  render() {

    console.log("==============================================+++++++++++++++++++++++++++++++=====================");
    this.checkReloadKey();

    // console.log("Render: exerciseSelected", this.state.exerciseSelected, this.state.connected, this.state.showDeviceSelector);

    // console.log("XXX", this.userRecord);
    let hasPupils = false;
    if (this.userRecord)
      if (this.userRecord.pupils)
        if (this.userRecord.pupils.length > 0) hasPupils = true;

    // console.log("HASPUPILS", hasPupils, this.userRecord);

    let navComp = <NavigateBar
      className="navigate"
      user={this.state.user}
      isRoot={this.state.isRoot}
      manageCourses={this.state.manageCourses}
      manageGroupExercise={this.state.manageGroupExercise}
      hasPupils={hasPupils}
      isLoggedIn={this.state.authenticated}
      onLogoutClicked={this.logout.bind(this)}
      onLoginClicked={this.login.bind(this)}
      onDisconnectClicked={this.disconnectDevice.bind(this)}
      onConnectClicked={this.onConnectClicked.bind(this)} // added callback
    />

    if (this.state.loading || this.state.connecting) {

      console.log("STATE waiting...", this.state);

      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      );
    }


    // LOGIN
    // if (this.state.authenticated === false && this.state.loginClicked === true) {
    // if (this.state.authenticated === false) {

    //   return (
    //     <ThemeProvider theme={theme}>
    //       {/* {navComp} */}
    //       <Login onLogin={this.handleLogin}></Login>
    //     </ThemeProvider>
    //   );

    // }

    if (this.state.authenticated === false) {

      console.log("FFFF APP LOGIN");

      return (
        <ThemeProvider theme={theme}>
          {navComp}
          {/* <HomePage /> */}

          <Routes>
            <Route path="/verify-email" element={
              <VerifyEmail />
            } />

            <Route path="/" element={
              <Login onLogin={this.handleLogin}></Login>
            } />

            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </ThemeProvider>
      );

    }

    // if (this.state.authenticated === false) {
    //   return (
    //     <ThemeProvider theme={theme}>
    //       {navComp}
    //       {/* <HomePage /> */}

    //       <Routes>
    //         <Route path="/" element={
    //           <HomePage />
    //         } />
    //         <Route path="/privacy" element={
    //           <GDPR />
    //         } />
    //         <Route path="/login" element={
    //           <Login onLogin={this.handleLogin}></Login>
    //         } />

    //         <Route path="/verify-email" element={
    //               <VerifyEmail />
    //         } />

    //       </Routes>
    //     </ThemeProvider>
    //   );

    // }


    if (!this.state.gdpr) {

      let agreeButtonText = {
        hu: "Elfogadom",
        en: "Accept"
      };

      let declineButtonText = {
        hu: "Nem fogadom el",
        en: "Decline"
      };

      let agreeText = {
        hu: (<div>
          <Typography variant='h6'>
            Felhasználási feltételek
          </Typography>

          <Typography variant="body2" align='left' component='div'>
            Az Obimon EDA eszköz működése során egy alacsony, 0.5V-os feszültséget (ez egy AAA ceruzaelem egyharmada) tesz az ujjakra, ezáltal
            méri meg a bőr vezetőképességét. Az eszköz tervezése során figyelembe vettük az EU releváns szabályozásaiban meghatározott határértékeket.
            <br></br>
            <br></br>
            Az Obimon EDA eszköz nem egy tanúsított orvosi eszköz es nem szabad orvosi eszközként használni! Ha Önnek egészségügyi problémái vannak, vagy
            aggályai vannak az eszközünkkel vagy szolgáltatásunkkal kapcsolatban, kérjük ne vegye azokat igénybe.
            <br></br>
            <br></br>
            Az Obimon EDA eszközt nem szabad töltés közben vagy az USB-re csatlakoztatva használni.
            <br></br>
            <br></br>
            Az Obimon EDA eszköz egy kísérleti eszköz, amit kifejezetten kutatási célra fejlesztettünk ki. Ön teljes felelősséggel tartozik az eszköz
            és szolgáltatás használatából származó károkért. Az Obimon Kft. nem vállal felelősséget bármilyen jellegű kárral kapcsolatban ami az eszköz vagy a
            szolgáltatás használatából származik.
            <br></br>
            <br></br>
            Az "elfogadom" gomb megnyomásával Ön beleegyezik a felhasználási feltételeinkbe.
          </Typography>

          <br></br>

          <Typography variant='h6'>
            Adatkezelési tájékoztató
          </Typography>

          <Typography variant="body2" align='left' component='div'>
            Adatkezelési tájékoztatónkat megtekintheti itt, illetve a weboldalon megtalálhatja a menü alatt
          </Typography>
        </div>
        ),
        en: (<div>
          <Typography variant='h6'>
            Terms and conditions
          </Typography>

          <Typography variant="body2" align='left' component='div'>
            The Obimon EDA device operates by applying a small voltage of less than 0.5V on your fingers (one third of an AAA battery),
            and measures the electrical resistance of your skin. The device was carefully designed to follow the related EU regulatory limits regarding skin
            conductance measurements.
            <br></br>
            <br></br>
            Obimon EDA is not a certified medical device and cannot be used as a medical device. If you have problems with your health or concerns
            about using our devices and services, please do not use them.
            <br></br>
            <br></br>
            The device must not be charged / connected via USB and attached to a person at the same time.
            <br></br>
            <br></br>
            Obimon EDA is an experimental device specifically designed for research use. You take full responsibility for using our devices and services.
            Obimon Ltd. is not responsible for any damages arising from the use of our devices and services.
            <br></br>
            <br></br>
            By accepting you agree to our terms and conditions.
          </Typography>

          <br></br>

          <Typography variant='h6'>
            Privacy policy
          </Typography>

          <Typography variant="body2" align='left' component='div'>
            Your can read our privacy policy here, or you can find it on our webpage under the menu
          </Typography>

        </div>
        )
      };

      let lang = this.state.lang;

      return (
        <div>

          <Box sx={{ m: 5, p: 2 }}>

            <Button variant='text' color='primary' onClick={
              () => {
                this.setState({ lang: "en" });
                document.cookie = "lang=en";
              }
            }>en</Button>

            <Button variant='text' color='primary' onClick={
              () => {
                this.setState({ lang: "hu" });
                document.cookie = "lang=hu";
              }
            }>hu</Button>

            <br></br>
            {agreeText[lang]}

            <br></br>

            <Button size="small" variant="outlined" onClick={() => { this.setState({ backdropGDPR: true }); }} >open</Button>

            <Backdrop
              sx={{
                m: 3, color: 'black', zIndex: 1, background: '#eee', overflowY: "scroll",
              }}
              open={this.state.backdropGDPR}
              // open={true}
              onClick={() => { this.setState({ backdropGDPR: false }); }}
            >
              <GDPR lang={this.state.lang}></GDPR>
            </Backdrop>

            {/* <Paper elevation="4" style={{ height: "300px", overflowY: "scroll" }}>
              <GDPR></GDPR>
            </Paper> */}


            <br></br>

            <Button sx={{ m: 2, mt: 5 }} size="medium" variant="contained" onClick={this.logout.bind(this)} >{declineButtonText[lang]}</Button>

            <Button sx={{ m: 2, mt: 5 }} size="medium" variant="contained" onClick={this.saveGDPR.bind(this)} >{agreeButtonText[lang]}</Button>
          </Box>
        </div>
      );

    }

    // WE ARE AT THE MAIN SCREEN

    //console.log("App: stat", this.state.stats);

    let stat_thisweek = {};
    let stat_all = {};

    if (this.state.weeklyStats) {
      if ('ctw_stat' in this.state.weeklyStats) {
        if (this.state.weeklyStats['ctw_stat'].wstat) {
          stat_thisweek = this.state.weeklyStats['ctw_stat'].wstat;
        }

        if (this.state.weeklyStats['ctw_stat'].allstat) {
          stat_all = this.state.weeklyStats['ctw_stat'].allstat;
        }
      }
    }


    let execinfo = "";
    //console.log("App stat_thisweek", stat_thisweek, "stat_lastweek", stat_lastweek);
    //console.log(this.state.stats);

    //console.log("this user", this.state.user);

    console.log("user record", this.userRecord);


    let context = {
      userRecord: this.userRecord,
      user: this.state.user,
      isRoot: this.state.isRoot,
      reload: this.reloadNeeded.bind(this),
      label: this.state.label,
      deviceName: this.state.deviceName,
      bat: this.state.bat,
      firmware: this.state.firmware,
      difficulty: this.state.difficulty,
      sessions: this.state.sessions,
      // addSession: this.addSession.bind(this),
      storeSession: this.storeSession.bind(this),
      cancel: this.cancel.bind(this),
      setExercise: this.exerciseSelectedCb.bind(this),
      showExerciseSelector: this.showExerciseSelector.bind(this),
      setPage: this.setPage.bind(this),
      setMentorView: this.setMentorView.bind(this),
      mentorView: this.state.mentorView,
      usageLimited: this.usageLimited,
      lang: this.state.lang,

      fetchRecords: this.fetchRecords.bind(this),
      calcDifficulty: this.calcDifficulty.bind(this),
      calcStats: this.calcStats.bind(this),
      loadCampaigns: this.loadCampaigns.bind(this),
      calcLevels: this.calcLevels.bind(this),

      // setDeviceData: this.setDeviceData.bind(this),
      // NEW: Add setCampaign to context
      setCampaign: this.setCampaign.bind(this),
      // New: add setFilterDate and filterDate to the context.
      setFilterDate: this.setFilterDate.bind(this),
      filterDate: this.state.filterDate,
      // Added firmware upgrade info for Dashboard to show upgrade card
      firmwareUpgrade: this.state.upgradeDialogOpen,
      latestFirmware: this.state.latestFirmware,
    }

    if (navigator.platform === 'iPhone' || navigator.platform === 'iPad') {
      enqueueSnackbar('iPhone and iPad not supported. Please use MacBook, Windows or Android devices.', {
        variant: 'error', preventDuplicate: true, autoHideDuration: 30000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      });
    } else if (!navigator.bluetooth) {
      enqueueSnackbar('Your browser does not support Bluetooth Low Energy. Recommended browser is Chrome.', {
        variant: 'error', preventDuplicate: true, autoHideDuration: 30000,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        }
      });
    }

    let showGroupControl = false;
    if (this.state.exerciseSelected && this.state.mentorView) {
      showGroupControl = true;
    }

    let showNavBar = true;
    if (this.state.exerciseSelected && (!this.state.mentorView)) {
      showNavBar = false;
    }

    let s = "";

    if (this.state.page === "privacy") {
      s = <GDPR />

    } else if (this.state.page === "register") {
      s = <Register />

    } else if (this.state.page === "manage") {
      s = <ManageUser />

    } else if (this.state.page === "sessions") {
      s = <MySessions user={this.state.user} sessions={this.state.sessions} filterDate={this.state.pageParam.filterDate}/>

    } else if (this.state.page === "inspectgroupsessions") {
      s = <GroupSessions />

    } else if (this.state.page === "inspectuser") {
      s = <ResultSelector />

    } else if (this.state.page === "inspectpupil") {
      s = <PupilSelector pupils={this.userRecord.pupils} />

    } else if (this.state.page === "ota") {
      s = <Ota />
    } else if (this.state.page === "create-registration-code") {
      s = <CreateRegistrationCode />
    } else if (this.state.page === "create-mentee") {
      s = <CreateMentee />

    } else if (this.state.page === "force_reload") {
      s = <ForceReload />

    } else if (this.state.page === "mentor-dashboard") {
      s = <MentorDashboard />

    }else if (this.state.page === "groupexercise") {
      s = <GroupExerciseClient />

    } else if (this.state.showDeviceSelector) {
      s = <this.ConnectComponent></this.ConnectComponent>

    } else if (this.state.exerciseSelected) {

      if (this.state.mentorView) {
        s = <GroupExerciseControl exercise={this.state.exerciseSelected} campaign={this.state.campaignSelected} />

      } else {
        s = <Exercise key={this.state.exerciseKey} sessions={this.state.sessions} user={this.state.user} exercise={this.state.exerciseSelected} campaign={this.state.campaignSelected} finished_cb={this.exerciseFinishedCb.bind(this)} enableBle={this.enableBle} ref={this.edaRef} connected={this.state.connected} />

      }
    } else {
      if (this.state.page === "campaigns") {
        s = <Campaigns 
              user={this.state.user} 
              level={this.state.level} 
              campaigns={this.state.campaigns} 
              sessions={this.state.sessions} 
              exerciseSelectedCb={this.exerciseSelectedCb.bind(this)}
              campaign={this.state.campaignSelected} 
            />
      } else {
        s = <Dashboard newfeatures={this.state.newfeatures} mySceneryImage={this.state.mySceneryImage} campaigns={this.state.campaigns} exerices={execinfo} level={this.state.level} stats={this.state.stats} weeklyStats={this.state.weeklyStats}></Dashboard>
      }
    }



    return (

      <div>

        {/* <MyContext.Provider value={this.reloadNeeded.bind(this)}> */}
        <MyContext.Provider value={context}>
          <ThemeProvider theme={theme}>

            {
              (showNavBar) && navComp
            }
            <Box sx={{ m: 0 }}>

              {s}

              <Routes>

                <Route path="/" element={
                  <div> </div>
                } />

                (manageCourses && <Route path="/inspectgroupsessions" element={
                  <GroupSessions />
                } />)

                {/* (isRoot && <Route path="/fractal" element={
                  <Flower size="100" />
                } />) */}

                (isRoot && <Route path="/ota" element={
                  <Ota />
                } />)

                (isRoot && <Route path="/force_reload" element={
                  <ForceReload />
                } />)

                {/* (isRoot && <Route path="/scenery" element={
                  <Scenery />
                } />) */}

                <Route path="*" element={<Navigate to="/" replace />} />

              </Routes>

            </Box>

          </ThemeProvider>

        </MyContext.Provider>
      </div >
    );

  }
}

// export default withSnackbar(App);
export default App;