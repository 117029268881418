
import { calcLevelCTW } from './calc_level_ctw';
import Typography from '@mui/material/Typography';
import { calcLevelGeneric } from './calc_level_generic';


// in JS objects insertion order is preserved! so dicts act like lists!!!!

export const raweda_data = {
    raweda: {        
        label: "Raw EDA",
        levelType: "CTW",
        // levelFn: calcLevelGeneric,

        exercises: {
            raweda: {
                label: "Raw EDA",
                level: 1,
                eda: true,
        
                type: "RawEda",
                params: {
                    showWaves: false, 
                    point_mult: 1,
                    l_incr: 1,
                    l_decr: 1,
                    r_incr: 1,
                    r_decr: 1,
        
                },
        
            },        

            
        
            
        }
    }
}
