import React from 'react';
// import ReactHowler from 'react-howler'
import { Howl, Howler } from 'howler';
import { CalcScore } from '../scripts/calc_score';
import { CalcSessionStats } from '../scripts/calc_sessionstats';
import Wave from 'react-wavify'
import Fab from '@mui/material/Fab';

import Button from '@mui/material/Button';
// import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
// import SkipNextIcon from '@mui/icons-material/SkipNext';
// import { ConfirmationNumberOutlined, PlaylistAddCheckCircleSharp } from '@mui/icons-material';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
// import DoneIcon from '@mui/icons-material/Done';
import VolumeUp from '@mui/icons-material/VolumeUp';
import VolumeDown from '@mui/icons-material/VolumeDown';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Hls from 'hls.js';

import ReactPlayer from 'react-player'

// import { text_audiomodule_data } from './text_audiomodule_data.js';


// files from https://www.zapsplat.com/ or https://freesound.org/

class VideoEda extends React.Component {
  constructor(props) {
    super(props);
    this.playerRef = React.createRef();

    let params = props.params;

    console.log("Slider constructor ");

    this.eda = [];
    this.acc = [];
    this.scores = [];


    this.col1 = [178, 137, 239, 0.7];
    this.col2 = [150, 97, 255, 0.5];

    this.color1 = this.rgba(this.col1);
    this.color2 = this.rgba(this.col2);

    /////////////////////////////////////////// TODO: VIDEO EDA DOES NOT USE DIFFICULTY PARAMS!!, NEED TO FIX

    this.calc_score = new CalcScore(params);
    this.calc_stats = new CalcSessionStats();

    this.loaded = false;
    this.success = false;
    this.start = Date.now();

    this.state = {
      ended: false,
      sclScore: 100,
      scrScore: 100,

    }
  }

  // cancel() {
  //   console.log("Cancel called");
  //   // on finish, we do not send data, because we already called store_cb in "ended"
  //   this.props.finished_cb({}, false);
  // }

  rgba(c) {
    return `rgba(${c[0]}, ${c[1]}, ${c[2]}, ${c[3]})`
  }


  handleTextChange(event) {
    this.setState({ textAreaValue: event.target.value });
  }


  async ended() {
    if (this.endedCalled) return;
    this.endedCalled = true;

    console.log("Ended");

    this.setState({ ended: true });
    console.log("Ended");

    let sessionData = {
      ts: this.start,
      eda: this.eda,
      scores: this.scores,
      acc: this.acc,
      success: true
    };

    await this.props.finished_cb(sessionData);

  }


  onEda(gsr, acc) {
    // console.log(".")

    if (this.state.ended) return;

    let ts = Date.now();

    this.eda.push([ts, gsr])
    this.acc.push([ts, acc])

    let [sl, sr] = this.calc_score.calc_one(ts, gsr);

    this.calc_stats.calc_one(ts, sl, sr);
    this.scores.push([ts, sl, sr]);

    this.setState({ sclScore: sl, scrScore: sr });


  }


  render() {

    if (this.state.askFeedback) {
      return (
        <Box maxWidth='90%' margin={3} sx={{ flexDirection: 'column' }}>

          <br></br>

          <Typography>
            If you wish, you can enter your thoughts here:--
          </Typography>

          <br></br>

          <TextareaAutosize
            aria-label="minimum height"
            minRows={5}

            //placeholder="If you wish, you can enter your thoughts here..."
            value={this.state.textAreaValue}
            onChange={this.handleTextChange.bind(this)}
            style={{ width: '90%' }}
          />

          <br></br>

          <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.feedback.bind(this)}>Continue</Button>

        </Box>
      );
    }


    let url = this.props.params.url;
    // console.log("URL", url);

    let showWaves = true;
    if (this.props.params.showWaves === false) {
      showWaves = false
    }

    let a1 = this.state.sclScore;
    let a2 = this.state.scrScore;

    if (a1 > 100) a1 = 100;
    if (a2 > 100) a2 = 100;


    return (
      <div>

        <div style={{ position: 'absolute', left: '0px', top: 40, zIndex: '-10', width: "100%" }}>

          {/* <Box sx={{ m: 'auto', p: 1, maxWidth: "800px" }}> */}

          <ReactPlayer
            // url="/media/gerisgame.m4v"
            // url="/media/rezilio/cp_instruction.mp4"
            url={url}
            width="640"
            height="360"
            volume="1"
            playing={true}
            // controls
            onEnded={this.ended.bind(this)}
            // onStarted={this.started.bind(this)}
            onReady={() => {
              const hlsInstance = this.playerRef.current.getInternalPlayer('hls');
              if (hlsInstance) {
                hlsInstance.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
                  console.log('HLS Level switched', event, data);
                });
              }
            }}
          />


          {/* <Button sx={{ m: 5 }} variant='contained' color='primary' onClick={this.ended.bind(this)}>Finish</Button> */}

          {/* </Box> */}
        </div>


        {showWaves &&
          <Wave fill={this.color1}
            paused={false}
            options={{
              height: 50,
              amplitude: a1,
              speed: 0.15,
              points: 2
            }}
            style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-4', height: "50%" }}
          ></Wave>
        }
        {showWaves &&
          <Wave fill={this.color2}
            paused={false}
            options={{
              height: 50,
              amplitude: a2,
              speed: 0.15,
              points: 10
            }}
            style={{ position: 'absolute', left: '0px', top: '50%', zIndex: '-3', height: "50%" }}
          ></Wave>

        }

        <Fab variant="contained" color="primary" >
          <CheckCircleIcon onClick={this.ended.bind(this)} />
        </Fab>


      </div>
    );

  };
}

export default VideoEda;