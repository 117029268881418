import React, { useState, useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import { Box, ButtonGroup, Button, Typography } from '@mui/material';

// Register Chart.js components and the zoom plugin.
Chart.register(...registerables, zoomPlugin);

const SessionsChart = ({ sessions }) => {
  const [view, setView] = useState('daily'); // "daily" or "weekly"
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  // Helper: Convert a timestamp to a Date.
  // Assumes ts is in seconds if it has 10 digits; otherwise in milliseconds.
  const convertTimestamp = (ts) => {
    if (!ts) return null;
    const tsStr = ts.toString();
    const timestamp = tsStr.length === 10 ? ts * 1000 : ts;
    return new Date(timestamp);
  };

  // Group sessions by day. Returns an object mapping "YYYY-MM-DD" => count.
  const groupSessionsByDay = (sessionsArray) => {
    const groups = {};
    sessionsArray.forEach(session => {
      if (!session.ts) return;
      const dateObj = convertTimestamp(session.ts);
      if (!dateObj || isNaN(dateObj.getTime())) return;
      const day = dateObj.toISOString().split('T')[0];
      groups[day] = (groups[day] || 0) + 1;
    });
    return groups;
  };

  // Group sessions by week (using Monday as the week’s start).
  // Returns an object mapping "YYYY-MM-DD" (Monday) => count.
  const groupSessionsByWeek = (sessionsArray) => {
    const groups = {};
    sessionsArray.forEach(session => {
      if (!session.ts) return;
      const dateObj = convertTimestamp(session.ts);
      if (!dateObj || isNaN(dateObj.getTime())) return;
      const day = dateObj.getDay();
      // Calculate Monday (if Sunday, subtract 6 days).
      const diff = dateObj.getDate() - day + (day === 0 ? -6 : 1);
      const monday = new Date(dateObj);
      monday.setDate(diff);
      const mondayStr = monday.toISOString().split('T')[0];
      groups[mondayStr] = (groups[mondayStr] || 0) + 1;
    });
    return groups;
  };

  // Given two groups (video and other) whose keys are date strings,
  // fill in missing time data over the overall range.
  // incrementDays should be 1 for daily, 7 for weekly.
  const fillMissingTimeData = (videoGroups, otherGroups, incrementDays) => {
    // Get the union of keys from both groups.
    const keysSet = new Set([...Object.keys(videoGroups), ...Object.keys(otherGroups)]);
    if (keysSet.size === 0) {
      return { videoData: [], otherData: [], xMin: undefined, xMax: undefined };
    }
    const keys = Array.from(keysSet).sort();
    const startDate = new Date(keys[0]);
    const endDate = new Date(keys[keys.length - 1]);

    const videoData = [];
    const otherData = [];
    const current = new Date(startDate);
    while (current <= endDate) {
      const isoDate = current.toISOString().split('T')[0];
      videoData.push({ x: new Date(isoDate), y: videoGroups[isoDate] !== undefined ? videoGroups[isoDate] : null });
      otherData.push({ x: new Date(isoDate), y: otherGroups[isoDate] !== undefined ? otherGroups[isoDate] : null });
      current.setDate(current.getDate() + incrementDays);
    }
    return { videoData, otherData, xMin: startDate, xMax: endDate };
  };

  // Prepare the dataset based on the selected view.
  // Separates sessions into VideoTutorial vs. Other.
  const getChartData = () => {
    // Ensure sessions is defined and is an array.
    const allSessions = Array.isArray(sessions) ? sessions : [];
    const videoSessions = allSessions.filter(s => s.type === "VideoTutorial");
    const otherSessions = allSessions.filter(s => s.type !== "VideoTutorial");

    let result;
    if (view === 'daily') {
      const videoGroups = groupSessionsByDay(videoSessions);
      const otherGroups = groupSessionsByDay(otherSessions);
      result = fillMissingTimeData(videoGroups, otherGroups, 1);
    } else {
      const videoGroups = groupSessionsByWeek(videoSessions);
      const otherGroups = groupSessionsByWeek(otherSessions);
      result = fillMissingTimeData(videoGroups, otherGroups, 7);
    }
    return result;
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const { videoData, otherData, xMin, xMax } = getChartData();
    const ctx = canvasRef.current.getContext('2d');
    chartRef.current = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [
          {
            label: 'VideoTutorial Sessions',
            data: videoData,
            borderColor: '#8884d8',
            backgroundColor: 'rgba(136, 132, 216, 0.2)',
            spanGaps: false,
            tension: 0.1,
            pointRadius: 3,
          },
          {
            label: 'Other Sessions',
            data: otherData,
            borderColor: '#24fff4',
            backgroundColor: 'rgba(36, 255, 244, 0.2)',
            spanGaps: false,
            tension: 0.1,
            pointRadius: 3,
          }
        ]
      },
      options: {
        parsing: false, // Data is already in { x, y } format.
        scales: {
          x: {
            type: 'time',
            min: xMin,
            max: xMax,
            time: {
              unit: view === 'daily' ? 'day' : 'week',
              tooltipFormat: 'yyyy-MM-dd',
            },
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Sessions Count',
            },
            beginAtZero: true,
            ticks: {
              precision: 0,
            },
          }
        },
        plugins: {
          zoom: {
            zoom: {
              wheel: { enabled: true },
              pinch: { enabled: true },
              mode: 'x', // Zoom only on the x-axis.
            },
            pan: {
              enabled: true,
              mode: 'x',
            }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [sessions, view]);

  // Reset zoom to autoscale the chart.
  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Sessions Over Time
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mb: 2,
        }}
      >
        <Button
          variant={view === 'daily' ? 'contained' : 'outlined'}
          onClick={() => setView('daily')}
        >
          Daily
        </Button>
        <Button
          variant={view === 'weekly' ? 'contained' : 'outlined'}
          onClick={() => setView('weekly')}
        >
          Weekly
        </Button>
        <Button variant="outlined" onClick={resetZoom}>
          Reset Zoom
        </Button>
      </Box>      <div style={{ position: 'relative', height: '300px' }}>
        <canvas ref={canvasRef}></canvas>
      </div>
    </div>
  );
};

export default SessionsChart;
